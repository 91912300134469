import type { ThemeOptions } from '@mui/material/styles';

const MuiBackdrop: NonNullable<ThemeOptions['components']>['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      cursor: 'pointer',
    },
  },
};

export default MuiBackdrop;
