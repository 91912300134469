import type { ThemeOptions } from '@mui/material/styles';

const MuiCircularProgress: NonNullable<ThemeOptions['components']>['MuiCircularProgress'] = {
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      color: theme.palette['color/text/default'],
    }),
    colorSecondary: ({ theme }) => ({
      color: theme.palette['color/text/subtle'],
    }),
  },
};

export default MuiCircularProgress;
