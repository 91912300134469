import type { ThemeOptions } from '@mui/material/styles';
import * as heading from '../elements/headings';
import * as mqs from '~/styles/media-queries';

export const MuiCard: NonNullable<ThemeOptions['components']>['MuiCard'] = {
  styleOverrides: {
    root: {
      padding: 16,
      [mqs.xl]: {
        padding: 40,
      },
      display: 'grid',
    },
  },
  defaultProps: {
    elevation: 25,
  },
};

export const MuiCardHeader: NonNullable<ThemeOptions['components']>['MuiCardHeader'] = {
  styleOverrides: {
    title: {
      ...heading?.h1,
      fontWeight: 'unset',
      textAlign: 'center',
    },
    root: {
      padding: 'unset',
    },
  },
};

export const MuiCardContent: NonNullable<ThemeOptions['components']>['MuiCardContent'] = {
  styleOverrides: {
    root: {
      padding: 'unset',
      '&:last-child': {
        paddingBottom: 'unset',
      },
    },
  },
};

export const MuiCardMedia: NonNullable<ThemeOptions['components']>['MuiCardMedia'] = {
  styleOverrides: {
    img: {
      objectFit: 'contain',
    },
  },
};
