export const values = {
  xs: 0,
  sm: 600,
  smMid: 512, // TODO: @Freddie vilka värden ska det stå här egentligen?
  xsMid: 768, // TODO: @Mattias eller så vet du kanske?
  md: 1024,
  lg: 1200,
  xl: 1536,
  xxl: 1680,
};
