/**
 * This is the client-side entrypoint for the tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as the type-safe React Query hooks.
 */
import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import type { AppRouter } from '~/server/api/root';
import { isServer } from './is-server';

function getBaseUrl() {
  if (isServer()) {
    if (process.env.NEXT_PUBLIC_BASE_URL_WEB) {
      return process.env.NEXT_PUBLIC_BASE_URL_WEB;
    }

    // assume localhost
    return `http://localhost:${process.env.PORT ?? 5443}`;
  }

  // browsers should use relative path
  return '';
}

export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/spa/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
          },
        },
      },
    };
  },

  ssr: false,
});
