export const colorTokens = {
  "color/background/default": "#F6F6F6",
  "color/background/elevated": "#FFFFFF",
  "color/background/action": "#00873B",
  "color/background/action-dark": "#21713B",
  "color/background/action-darkest": "#175D30",
  "color/background/action-subtle": "#F6F6F6",
  "color/background/action-inverse": "#FFFFFF",
  "color/background/visual": "#D7EAD5",
  "color/background/visual-dark": "#ABD4AA",
  "color/background/visual-second": "#FBD6CB",
  "color/background/notice": "#FBD6CB",
  "color/background/inverse": "#175D30",
  "color/background/success": "#D7EAD5",
  "color/background/information": "#D6E1F2",
  "color/background/warning": "#FFECD4",
  "color/background/error": "#FFDCDA",
  "color/border/default": "#D9D9D9",
  "color/border/subtle": "#F6F6F6",
  "color/border/action": "#00873B",
  "color/border/action-dark": "#21713B",
  "color/border/action-darkest": "#175D30",
  "color/border/action-subtle": "#595959",
  "color/border/visual": "#D7EAD5",
  "color/border/inverse": "#FFFFFF",
  "color/border/focus": "#262626",
  "color/border/success": "#175D30",
  "color/border/information": "#284267",
  "color/border/warning": "#A15A19",
  "color/border/error": "#B91509",
  "color/icon/default": "#262626",
  "color/icon/subtle": "#595959",
  "color/icon/subtlest": "#D9D9D9",
  "color/icon/action": "#00873B",
  "color/icon/action-dark": "#21713B",
  "color/icon/action-darkest": "#175D30",
  "color/icon/action-subtle": "#595959",
  "color/icon/brand": "#175D30",
  "color/icon/inverse": "#FFFFFF",
  "color/icon/inverse-dark": "#D7EAD5",
  "color/icon/inverse-darkest": "#ABD4AA",
  "color/icon/success": "#175D30",
  "color/icon/information": "#284267",
  "color/icon/warning": "#A15A19",
  "color/icon/error": "#B91509",
  "color/text/default": "#262626",
  "color/text/subtle": "#595959",
  "color/text/action": "#00873B",
  "color/text/action-dark": "#21713B",
  "color/text/action-darkest": "#175D30",
  "color/text/action-subtle": "#595959",
  "color/text/brand": "#175D30",
  "color/text/notice": "#AD3436",
  "color/text/inverse": "#FFFFFF",
  "color/text/inverse-dark": "#D7EAD5",
  "color/text/inverse-darkest": "#ABD4AA",
  "color/text/success": "#175D30",
  "color/text/information": "#284267",
  "color/text/warning": "#A15A19",
  "color/text/error": "#B91509"
};
