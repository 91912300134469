import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';

export type ConsentInfoLinkProps = {
  onOpenHandler: (consentType: string) => void;
  consentType: string;
  text: string;
};

export const ConsentInfoLink: React.FC<ConsentInfoLinkProps> = ({
  onOpenHandler,
  text,
  consentType,
}) => {
  const intl = useIntl();

  return (
    <Button
      className="h-3 justify-start p-0"
      data-testid="CONSENT.INFO.LINK"
      variant="tertiary"
      onClick={(e) => {
        e.preventDefault();
        onOpenHandler(consentType);
      }}
    >
      <Typography
        className="[&_b]:text-brand [&_b]:underline"
        dangerouslySetInnerHTML={{ __html: intl.messages[text].toString() }}
      />
    </Button>
  );
};
