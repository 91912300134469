import theme from '~/styles/theme';

const alertMappings = new Map();

const successMap = new Map();
successMap.set('filled', {
  borderColor: theme.palette['color/background/success'],
  color: theme.palette['color/text/success'],
  background: theme.palette['color/background/success'],
});
successMap.set('outlined', {
  borderColor: theme.palette['color/border/success'],
  color: theme.palette['color/text/success'],
  background: 'transparent',
});
successMap.set('standard', {
  borderColor: theme.palette['color/background/success'],
  color: theme.palette['color/text/success'],
  background: theme.palette['color/background/success'],
});

const infoMap = new Map();
infoMap.set('filled', {
  borderColor: theme.palette['color/background/information'],
  color: theme.palette['color/text/information'],
  background: theme.palette['color/background/information'],
});
infoMap.set('outlined', {
  borderColor: theme.palette['color/border/information'],
  color: theme.palette['color/text/information'],
  background: 'transparent',
});
infoMap.set('standard', {
  borderColor: theme.palette['color/background/information'],
  color: theme.palette['color/text/information'],
  background: theme.palette['color/background/information'],
});

const warningMap = new Map();
warningMap.set('filled', {
  borderColor: theme.palette['color/background/warning'],
  color: theme.palette['color/text/warning'],
  background: theme.palette['color/background/warning'],
});
warningMap.set('outlined', {
  borderColor: theme.palette['color/border/warning'],
  color: theme.palette['color/text/warning'],
  background: 'transparent',
});
warningMap.set('standard', {
  borderColor: theme.palette['color/background/warning'],
  color: theme.palette['color/text/warning'],
  background: theme.palette['color/background/warning'],
});

const errorMap = new Map();
errorMap.set('filled', {
  borderColor: theme.palette['color/background/error'],
  color: theme.palette['color/text/error'],
  background: theme.palette['color/background/error'],
});
errorMap.set('outlined', {
  borderColor: theme.palette['color/border/error'],
  color: theme.palette['color/text/error'],
  background: 'transparent',
});
errorMap.set('standard', {
  borderColor: theme.palette['color/background/error'],
  color: theme.palette['color/text/error'],
  background: theme.palette['color/background/error'],
});

alertMappings.set('success', successMap);
alertMappings.set('info', infoMap);
alertMappings.set('warning', warningMap);
alertMappings.set('error', errorMap);

export { alertMappings };
