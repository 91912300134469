import type { ThemeOptions } from '@mui/material/styles';
import * as headings from '../elements/headings';
import * as text from '../elements/text';
import palette from '../theme/palette';

export const MuiTypography: NonNullable<ThemeOptions['components']>['MuiTypography'] = {
  styleOverrides: {
    h1: {
      ...headings.h1,
    },
    h2: {
      ...headings.h2,
    },
    h3: {
      ...headings.h3,
    },

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore This prop doesn't exist on MuiTypography
    normal: {
      ...text.normal,
    },
    normalBold: {
      ...text.normalBold,
    },

    large: {
      ...text.large,
    },
    largeBold: {
      ...text.largeBold,
    },

    headingBold: {
      ...text.headingBold,
    },

    medium: {
      ...text.medium,
    },
    mediumBold: {
      ...text.mediumBold,
    },

    small: {
      ...text.small,
    },
    smallBold: {
      ...text.smallBold,
    },

    xs: {
      ...text.xs,
    },
    xsBold: {
      ...text.xsBold,
    },

    normalBonus: {
      ...text.normalBonus,
    },

    largeBonus: {
      ...text.largeBonus,
    },

    // remove these

    price: {
      color: palette.price.primary,
    },

    body1: undefined,
    inherit: undefined,
    button: undefined,
    h5: undefined,
    h6: undefined,
    caption: undefined, 
    overline: undefined, // removes a variant from the theme
  },
};
