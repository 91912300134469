import type { IFeatureToggle } from './IFeatureToggle';

export type FeatureToggleState = {
  featureToggles: IFeatureToggle[];
  error: boolean;
  errorMessage: string;
  loading: boolean;
  updated: number;
};

export const initialState = {
  featureToggles: [],
  error: false,
  errorMessage: '',
  loading: false,
  updated: 0,
} as FeatureToggleState;
