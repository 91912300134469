import { createContext, useContext, useMemo } from 'react';
import produce from 'immer';
import findLast from 'lodash/findLast';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { logger } from '~/logging';
import type { FeatureToggleState } from './FeatureToggleState';
import type { IFeatureToggle } from './IFeatureToggle';
import { getCookie as getFeatureToggleFromCookie } from './feature-toggle.api';
import { initializeFeatureToggleStoreMiddleware } from './initializeFeatureToggleStoreMiddleware';

export const CookieFeatureTogglesContext = createContext<Array<IFeatureToggle>>([]);

function isFeatureEnabled(
  feature: IFeatureToggle | undefined,
  featureTogglesFromCookies: Array<IFeatureToggle>,
) {
  if (!feature) return false;

  if (feature?.isActive) return true;

  try {
    const cookieToogle = getFeatureToggleFromCookie(feature, featureTogglesFromCookies);

    if (!cookieToogle) return false;

    if (cookieToogle.cookieName === feature.cookieName) {
      return (
        cookieToogle.isActive ??
        (cookieToogle.value === feature.onValue && feature.onValue !== undefined)
      );
    }
  } catch {
    /* empty */
  }

  return false;
}

// the store is created at initialize app. Not 100% this is a good idea. This just becomes some kind of "global".
// Perhaps this is where we should have used context? - fredrik
const store = createStore<FeatureToggleState>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initializeFeatureToggleStoreMiddleware((set, get, _api) => ({
    ...get(),
    mutate: (mutateState: FeatureToggleState) =>
      set(
        produce((state) => ({
          ...state,
          ...mutateState,
        })),
      ),
  })),
);

// I'm just showing the entire spread of the store
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { getState, setState, subscribe } = store;

const useFeatureToggle = (featureToggleName: string | undefined) => {
  const features = useStore(store, ({ featureToggles }) => featureToggles);
  const cookieFeatureToggles = useContext(CookieFeatureTogglesContext);

  const feature = useMemo(
    () => findLast(features, ({ name }) => name === featureToggleName),
    [features, featureToggleName],
  );

  if (feature === undefined && (featureToggleName?.length ?? 0) > 0) {
    logger.warn(
      `useFeatureToggle.ts: [${featureToggleName}] is not present in feature toggle state`,
    );
  }

  const isEnabled = isFeatureEnabled(feature, cookieFeatureToggles ?? []);

  return { isEnabled, feature };
};

export { store as featureToggleStore, useFeatureToggle };
