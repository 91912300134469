import type { ThemeOptions } from '@mui/material';
import * as mqs from '../media-queries';

const MuiPaper: NonNullable<ThemeOptions['components']>['MuiPaper'] = {
  styleOverrides: {
    root: {
      rowGap: 8,
      [mqs.xl]: {
        rowGap: 16,
      },
    },
    elevation0: {
      [mqs.lg]: {
        padding: 40,
      },
    },
    elevation1: ({ theme }) => ({
      boxShadow: theme.shadows[25],
    }),
    elevation2: {
      boxShadow: 'none',
      [mqs.lg]: {
        padding: 0,
      },
      [mqs.lgDown]: {
        marginTop: 16,
      },
    },
  },
};
export default MuiPaper;
