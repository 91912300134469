/**
 * This file contains colors that are required from external companies, and are not part of the design tokens.
 */

export const EXTERNAL_COMPANY_COLORS = {
  MIN_DOKTOR: '#173846',
  ICA: {
    RED: '#e3000b',
    PINK: '#f8c9df',
    PINK_LIGHT: '#ffe7f2',
    ORANGE: '#e17366',
    ORANGE_LIGHT: '#fcece7',
    GREEN: '#00cd4f', // This color represent Apotek Hjärtat at ICA, but might differ from the colors we use
  },
  ICA_BANK: {
    RED: '#9b0600',
    VIOLET: '#bb9ec7',
  },
  ICA_INSURANCE: {
    BLUE_LIGHT: '#c8e5f7',
    BLUE: '#6fb4e0',
  },
};
