export const spacingTokens = {
  "spacing/0.25": 2,
  "spacing/0.5": 4,
  "spacing/1": 8,
  "spacing/1.5": 12,
  "spacing/2": 16,
  "spacing/2.5": 20,
  "spacing/3": 24,
  "spacing/4": 32,
  "spacing/5": 40,
  "spacing/6": 48,
  "spacing/7": 56,
  "spacing/8": 64,
  "spacing/9": 72,
  "spacing/10": 80,
  "spacing/11": 88,
  "spacing/12": 96,
  "spacing/13": 104,
  "spacing/14": 112,
  "spacing/15": 120
};
