import React, { useEffect, useRef, useState } from 'react';
import { cva } from 'class-variance-authority';
import { Transition } from 'react-transition-group';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import type { IconName } from '../icon/icon';
import { Icon } from '../icon/icon';
import { Link } from '../link/link';
import { Typography } from '../typography/typography';

const variations = cva(
  'text-default lg:max-xxl:w-auto flex w-full max-w-[642px] justify-between gap-1 rounded-lg p-1.5',
  {
    variants: {
      variant: {
        error: 'bg-error',
        warning: 'bg-warning',
        information: 'bg-information',
        success: 'bg-success',
      },
    },
    defaultVariants: {
      variant: 'information',
    },
  },
);

interface AlertProps
  extends React.HTMLAttributes<HTMLElement>,
    CustomVariantProps<typeof variations> {
  title?: string;
  description?: string;
  link?: {
    url?: string;
    text?: string;
  };
  isDismissible?: boolean;
  isSelfclosing?: boolean;
}

/**
 *
 * @example
 * ```tsx
 * <Alert variant="variant-property"
 *  title="Title"
 *  description="Some description"
 *  isDismissible=true/false
 *  isSelfclosing=true/false
 *  link={url: "some-url", text="some text"}
 * />
 * ```
 *
 */
export const Alert: React.FC<AlertProps> = ({
  variant = 'information',
  description,
  title,
  link,
  isDismissible = true,
  isSelfclosing = false,
  className,
  ...rest
}) => {
  const iconMap: Record<string, IconName> = {
    error: 'AlertError',
    warning: 'AlertWarning',
    information: 'AlertInfo',
    success: 'AlertSuccess',
  };
  const icon = iconMap[variant];

  const [open, setOpen] = useState(true);
  const nodeRef = useRef(null);
  const duration = 600;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 1,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isDismissible && isSelfclosing) {
      const timer = setTimeout(() => {
        close();
      }, 5000);
      return () => clearTimeout(timer);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Transition nodeRef={nodeRef} in={open} timeout={duration} exit unmountOnExit>
      {(state) => (
        <div
          ref={nodeRef}
          className={cn(variations({ variant }), className)}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          {...rest}
        >
          <div className="flex flex-none">
            <Icon name={icon} size="large" />
          </div>
          <div className="flex flex-1 flex-col">
            {title && (
              <Typography typography="body" className="font-bold">
                {title}
              </Typography>
            )}
            {description && <Typography typography="body">{description}</Typography>}
            {link && <Link href={link.url}>{link.text}</Link>}
          </div>
          {isDismissible && (
            <button className="flex" type="button" aria-label="Close alert" onClick={() => close()}>
              <Icon name="Close" size="medium" />
            </button>
          )}
        </div>
      )}
    </Transition>
  );
};
