import { Cookies } from 'react-cookie';
import { CookieFeatureToggle } from './CookieFeatureToggle';
import type { IFeatureToggle } from './IFeatureToggle';

export const getFeatureTogglesFromCookies = (cookies?: Cookies): IFeatureToggle[] => {
  const allCookies = (cookies ?? new Cookies()).getAll();

  const namePrefix = `${process.env.NEXT_PUBLIC_FEATURE_COOKIE_PREFIX}`;
  return Object.keys(allCookies)
    .filter((cookieName) => cookieName.startsWith(namePrefix))
    .map((cookieName) => new CookieFeatureToggle(cookieName, allCookies[cookieName]));
};
