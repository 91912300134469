// This component is meant for formatting price and label for lineitems and articles.
// TODO: Should be adjusted or removed when design system for typography is set.
import React from 'react';
import { cva } from 'class-variance-authority';
import type { TypographyProps } from '@aph/ui/components/typography/typography';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { CustomVariantProps } from '@aph/ui/tailwind/variant-props';
import { formatPrice } from '@aph/utilities/format-price/format-price';

const variants = cva('font-ica-heading font-bold', {
  variants: {
    size: {
      xsmall: ['text-sm', 'font-ica-text', 'font-regular'],
      small: ['text-base', 'font-ica-text', 'font-regular'],
      medium: ['text-2xl'],
      large: ['text-3xl'],
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export type PriceFormatterProps = TypographyProps & {
  price: number;
  strikeThrough?: boolean;
  inline?: boolean;
} & CustomVariantProps<typeof variants>;

export const PriceFormatter = ({
  price,
  strikeThrough,
  inline = false,
  size = 'medium',
  color,
  className,
  ...props
}: PriceFormatterProps) => (
  <Typography
    color={color}
    className={cn(
      variants({ size }),
      strikeThrough && 'line-through',
      inline && 'md:min-w-13',
      className,
    )}
    {...props}
  >
    {formatPrice(price)}
  </Typography>
);
