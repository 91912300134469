import StatusCode from 'status-code-enum';

class HttpStatusCodes {
  static Success(statusCode: StatusCode): boolean {
    return (
      statusCode === StatusCode.SuccessAccepted ||
      statusCode === StatusCode.SuccessAlreadyReported ||
      statusCode === StatusCode.SuccessCreated ||
      statusCode === StatusCode.SuccessIMUsed ||
      statusCode === StatusCode.SuccessMultiStatus ||
      statusCode === StatusCode.SuccessNoContent ||
      statusCode === StatusCode.SuccessNonAuthoritativeInfo ||
      statusCode === StatusCode.SuccessOK ||
      statusCode === StatusCode.SuccessPartialContent ||
      statusCode === StatusCode.SuccessResetContent
    );
  }
}

export { HttpStatusCodes };
