import * as yup from 'yup';
import type { FeatureToggleKey } from './EnvFeatureToggle';
import { EnvFeatureToggle, featureToggleSchema } from './EnvFeatureToggle';
import type { FeatureToggleStates } from './FeatureToggleStates';
import type { IFeatureToggle } from './IFeatureToggle';

// we're only using this schema to validate that we have the feature toggles we need in the environment files
export const envFeatureTogglesSchema = yup.object().shape({
  NEXT_PUBLIC_APH_FEATURES_NEW_LAYOUT_PDP: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_FINDPHARMACY: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_MANUAL_DYNATRACE_INJECTION: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_CUSTOMERSERVICE: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_COOKIE_INFO: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_COMPETITION_CONDITION: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_RETAIL_MEDIA_CONSENT: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_BONUS_BAR: featureToggleSchema,
  NEXT_PUBLIC_APH_FEATURES_ROSA_BANDED: featureToggleSchema,
});

type EnvFeatureTogglesSchemaType = yup.InferType<typeof envFeatureTogglesSchema>;

// this is needed to tell typescript that some keys in process.env are of a specific type
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ProcessEnv extends EnvFeatureTogglesSchemaType {}
  }
}

const FEATURE_TOGGLES_FROM_ENV: Record<FeatureToggleKey, FeatureToggleStates> = {
  aph_features_new_layout_pdp: process.env.NEXT_PUBLIC_APH_FEATURES_NEW_LAYOUT_PDP,
  aph_features_findpharmacy: process.env.NEXT_PUBLIC_APH_FEATURES_FINDPHARMACY,
  aph_features_manual_dynatrace: process.env.NEXT_PUBLIC_APH_FEATURES_MANUAL_DYNATRACE_INJECTION,
  aph_features_customerservice: process.env.NEXT_PUBLIC_APH_FEATURES_CUSTOMERSERVICE,
  aph_features_cookieinfo: process.env.NEXT_PUBLIC_APH_FEATURES_COOKIE_INFO,
  aph_features_competitioncondition: process.env.NEXT_PUBLIC_APH_FEATURES_COMPETITION_CONDITION,
  aph_features_retail_media_consent: process.env.NEXT_PUBLIC_APH_FEATURES_RETAIL_MEDIA_CONSENT,
  aph_features_bonus_bar: process.env.NEXT_PUBLIC_APH_FEATURES_BONUS_BAR,
  aph_features_rosa_banded: process.env.NEXT_PUBLIC_APH_FEATURES_ROSA_BANDED,
} as const;

// guide typescript to know that our keys are of a specific type
const keysOf = <TObject extends object>(obj: TObject): (keyof TObject)[] => {
  return Object.keys(obj) as (keyof TObject)[];
};

const FEATURE_TOGGLES = keysOf(FEATURE_TOGGLES_FROM_ENV).map((name) =>
  EnvFeatureToggle.parse(name, FEATURE_TOGGLES_FROM_ENV[name]),
);

export const getFeatureTogglesFromEnv = (): IFeatureToggle[] => {
  const featureToggles = JSON.parse(process.env.NEXT_PUBLIC_FEATURE_TOGGLES || '{}');

  return [
    ...Object.keys(featureToggles).map((name) => new EnvFeatureToggle(name, featureToggles[name])),
    ...FEATURE_TOGGLES,
  ];
};
