// TODO: Missing test

/* eslint-disable react/no-danger */
import React from 'react';
import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import merge from 'lodash/merge';
import { classes as sxclasses } from './content-through-html.sxstyles';

type ContentThroughHtmlProps = {
  html: string | null;
  sx?: SxProps;
};

export const ContentThroughHtml: React.FC<ContentThroughHtmlProps> = React.memo(
  ({ html, sx = null }) => {
    if (!html) {
      return null;
    }
    return <Box sx={merge({}, sxclasses.content, sx)} dangerouslySetInnerHTML={{ __html: html }} />;
  },
  () => true,
); // static component, will not be affected by props change.
