/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import type { ConsentOptionV3 } from '~/services/generated/CustomerClient';
import { ConsentInfoLink } from '../consent-info-link/consent-info-link.component';
import { classes } from './gdpr-consent.styles';

type GdprConsentProps = {
  consentType: string;
  options: ConsentOptionV3[] | undefined;
  isChecked?: string;
  onOpenMoreInfo: (consentType: string) => void;
};

export const GdprConsent: React.FC<GdprConsentProps> = React.memo(
  ({ consentType, options, isChecked, onOpenMoreInfo }) => {
    const intl = useIntl();
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const { ref: consentOptionRef, ...consentOptionProps } = register(consentType, {
      required: true,
    });

    const errorStyles = errors[consentType!]
      ? 'border-error border-[1px] border-solid mb-1 md:mb-2'
      : 'mb-1 md:mb-2';

    return (
      <div
        className={`bg-elevated my-2 flex flex-col gap-1 rounded-md p-[20px] ${errorStyles}`}
        data-testid={`GDPR.CONSENT.${consentType?.toUpperCase()}`}
      >
        <Typography typography="headingMedium" asChild>
          <h2>
            <FormattedMessage id={`CONSENT.HEADING.${consentType}`} />
          </h2>
        </Typography>
        <ConsentInfoLink
          onOpenHandler={onOpenMoreInfo}
          consentType={consentType}
          text="CONSENT.LINK.TEXT.SELECT"
        />
        <Typography
          className="mb-1"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: `CONSENT.TEXT.${consentType}` }),
          }}
        />

        <FormControl component="fieldset">
          <RadioGroup defaultValue={isChecked} aria-label={consentType} {...consentOptionProps}>
            {/* Display the diffrent options */}
            {options &&
              options.map((option) => (
                <FormControlLabel
                  key={option.code}
                  control={<Radio inputRef={consentOptionRef} sx={classes.radioButton} />}
                  value={option.code}
                  label={
                    <>
                      <b>
                        <FormattedMessage id={`CONSENT.OPTION.${option.code}`} />
                      </b>
                      <span>
                        <FormattedMessage id={`CONSENT.OPTION.TEXT.${option.code}`} />
                      </span>
                    </>
                  }
                  sx={{ ...classes.rootLabel, ...classes.label }}
                  data-testid={option.code}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  },
);
