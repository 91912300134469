import { colorTokens } from '@aph/ui/design-tokens/color-tokens';

const MuiPrivateSwitchBase = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: colorTokens['color/background/elevated'],
      },
    },
  },
};

export default MuiPrivateSwitchBase;
