import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { svSE } from '@mui/x-date-pickers/locales';
import { sv } from 'date-fns/locale';

export type InputDateProps = {
  inputId: string;
  inputValue: Date | unknown;
  inputOnChange: (date: Date | unknown) => void;
  inputDisablePast?: boolean;
  inputDisableFuture?: boolean;
  inputMinDate?: Date;
  inputMaxDate?: Date;
  error?: { message: string };
  inputRef?: React.Ref<HTMLInputElement>;
};

export const InputDate: React.FC<InputDateProps> = ({
  error,
  inputId,
  inputValue,
  inputOnChange,
  inputDisablePast,
  inputDisableFuture,
  inputMinDate,
  inputMaxDate,
  inputRef,
}) => {
  return (
    <Box>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={sv}
        localeText={svSE.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          format="yyyy-MM-dd"
          inputRef={inputRef}
          defaultValue={inputValue}
          disablePast={inputDisablePast || false}
          disableFuture={inputDisableFuture || false}
          minDate={inputMinDate || null}
          maxDate={inputMaxDate || null}
          data-testid="FORM_ELEMENTS.INPUT_DATE.INPUT"
          value={inputValue}
          onChange={inputOnChange}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'medium',
            },
          }}
        />
      </LocalizationProvider>
      {error && error.message && (
        <Box marginTop={0.5}>
          <FormHelperText
            id={`${inputId}.VALIDATION_ERROR_TEXT`}
            data-testid="FORM_ELEMENTS.INPUT_DATE.VALIDATION"
          >
            {error.message}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
};
