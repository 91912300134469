import 'reflect-metadata';
import { useEffect } from 'react';
import type { AppContext, AppProps as NextAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { userAgentFromString } from 'next/server';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import clsx from 'clsx';
import { DefaultSeo } from 'next-seo';
import { Cookies, CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { HasNoSessionRedirect } from '@aph/components/common/auth-session/auth.has-no-session-redirect.component';
import { AuthSessionTimerAuthenticationChecked } from '@aph/components/common/auth-session/auth.session-timer.component';
import { ChatWrapper } from '@aph/components/common/chat/chat-wrapper.component';
import { IsBotContext } from '@aph/hooks/use-is-bot';
import { ICAHeading, ICATextNy } from '@aph/ui/fonts/fonts';
import '@aph/ui/tailwind/tailwind.css';
import { createEmotionCache } from '@aph/utilities/createEmotionCache';
import { getLogOutRedirectUrl } from '@aph/utilities/log-out/log-out.utils';
import { registerFetchOptions } from '@aph/utilities/register-fetch-options';
import { api } from '@aph/utilities/trpc-api';
import { GtmRouteChange } from '~/components/gtm/gtm-route-change';
import { AuthProvider } from '~/contexts/auth/auth.provider';
import { messages } from '~/i18n/messages';
import { logger } from '~/logging';
import type { IFeatureToggle } from '~/model/feature-toggle/IFeatureToggle';
import { getFeatureTogglesFromCookies } from '~/model/feature-toggle/getFeatureTogglesFromCookies';
import { CookieFeatureTogglesContext } from '~/model/feature-toggle/useFeatureToggle';
import { statusEventDispatcherInstance } from '~/services/register/container';
import GlobalStyles from '~/styles/global-styles.component';
import theme from '~/styles/theme';

const isReactQueryDevtoolsEnabled =
  String(process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS_DISABLED) !== 'true' &&
  process.env.NODE_ENV === 'development';

const isTailwindDevtoolsEnabled =
  String(process.env.NEXT_PUBLIC_TAILWIND_DEVTOOLS_DISABLED) !== 'true' &&
  process.env.NODE_ENV === 'development';

const ReactQueryDevtools = dynamic(
  // eslint-disable-next-line import/no-extraneous-dependencies
  () => import('@tanstack/react-query-devtools').then((mod) => mod.ReactQueryDevtools),
  {
    ssr: false,
  },
);

const TailwindDevTool = dynamic(
  // eslint-disable-next-line import/no-extraneous-dependencies
  () => import('@aph/ui/tailwind/tailwind-devtool').then((mod) => mod.TailwindDevTool),
  {
    ssr: false,
  },
);

// Client-side cache, shared for the whole session of the user in the browser.

export interface AppProps<P = unknown> extends NextAppProps<P> {
  emotionCache?: EmotionCache;
}
const clientSideEmotionCache: EmotionCache = createEmotionCache();

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppProps<{
  hasNoSessionReturnPath?: string;
  disableGlobalHasNoSessionRedirect?: boolean;
  isBot?: boolean;
  featureToggles?: Array<IFeatureToggle>;
}>) => {
  const {
    isBot: isBotFromServer = false,
    hasNoSessionReturnPath,
    disableGlobalHasNoSessionRedirect,
    featureToggles,
    ...props
  } = pageProps;

  const router = useRouter();
  // disable all types of prefetching (as a temporary measure)
  // ref: https://github.com/vercel/next.js/discussions/24437#discussioncomment-3679709
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.prefetch = async () => {};
  }, [router]);

  useEffect(() => {
    try {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentElement) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    } catch (e) {
      logger.error(`App.componentDidMount:error ${JSON.stringify(e, null, 2)}`);
    }
  }, []);

  return (
    <>
      <CacheProvider value={emotionCache}>
        <CookiesProvider>
          <AuthProvider authInstance={statusEventDispatcherInstance}>
            <GtmRouteChange />
            <DefaultSeo
              defaultTitle="Apotek Hjärtat"
              description="Köp snabbt & enkelt online - Apotek Hjärtat"
            />
            {/** No useStyles should before theme provider component */}
            <ThemeProvider theme={theme}>
              <IntlProvider defaultLocale="sv-SE" locale="sv-SE" key="sv" messages={messages.sv}>
                <AuthSessionTimerAuthenticationChecked
                  authInstance={statusEventDispatcherInstance}
                />
                {/** Log out user when has no session */}
                {disableGlobalHasNoSessionRedirect === true ? null : (
                  <HasNoSessionRedirect
                    redirectToExternal={getLogOutRedirectUrl(
                      hasNoSessionReturnPath ?? router.asPath,
                    )}
                  />
                )}
                {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
                <CookieFeatureTogglesContext.Provider value={featureToggles ?? []}>
                  <GlobalStyles />
                  <CssBaseline />
                  <Head>
                    <meta charSet="utf-8" />

                    <meta
                      name="viewport"
                      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                    />
                  </Head>
                  <div className="sr-only has-[:focus]:not-sr-only">
                    <a href="#main-content">Skip to main content</a>
                  </div>
                  <ChatWrapper />
                  <div
                    style={{ display: 'none' }}
                    className={clsx(ICATextNy.variable, ICAHeading.variable)}
                  />
                  <IsBotContext.Provider value={isBotFromServer}>
                    <Component {...props} />
                  </IsBotContext.Provider>
                </CookieFeatureTogglesContext.Provider>
              </IntlProvider>
            </ThemeProvider>
          </AuthProvider>
        </CookiesProvider>
      </CacheProvider>
      {isReactQueryDevtoolsEnabled ? <ReactQueryDevtools buttonPosition="bottom-left" /> : null}
      {isTailwindDevtoolsEnabled ? <TailwindDevTool /> : null}
    </>
  );
};

App.getInitialProps = async ({ ctx: { req } }: AppContext) => {
  registerFetchOptions(req?.headers);
  const cookies = new Cookies(req?.headers.cookie);
  const featureToggles = getFeatureTogglesFromCookies(cookies);

  const ua = userAgentFromString(req?.headers['user-agent']);

  // ref: https://github.com/trpc/trpc/discussions/1710#discussioncomment-4158134
  return { pageProps: { featureToggles, isBot: ua.isBot } };
};

export default api.withTRPC(App);
