import { waitForElement } from '@aph/utilities/wait-for-element/wait-for-element';

export const reloadOTBanner = async () => {
  const otConsentSdk = await waitForElement('#onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (typeof window === 'undefined' || window.OneTrust == null) {
    return;
  }

  window.OneTrust.Init();
  setTimeout(() => {
    window.OneTrust.LoadBanner();
  }, 1000);
};
