import type { IncomingHttpHeaders } from 'http';
import pickBy from 'lodash/pickBy';
import { Cookies } from 'react-cookie';
import { container } from 'tsyringe';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '~/logging';

export function mapCookiesToHeaders(cookies: Cookies) {
  const xAnonymousId = cookies.get<string>('aph_anonymous_id') ?? uuidv4();

  const eSalesHeaders = pickBy({
    'x-recommendations-session-id': cookies.get<string>('ApptusSessionId'),
    'x-recommendations-customer-id': cookies.get<string>('ApptusCustomerId'),
  });

  const headers = {
    Accept: 'text/plain',
    'X-requested-with': 'Fetch',
    'Content-Type': 'application/json',
    'X-Aph-Api-Key': 'df6e4856-c145-43cf-8be4-2d0a1f0dbabb',
    'X-Anonymous-Id': xAnonymousId,
    ...eSalesHeaders,
  };

  return headers;
}

export function registerFetchOptions(
  incomingHeaders: IncomingHttpHeaders | HeadersInit | undefined,
) {
  if (incomingHeaders) {
    let cookiesString = '';

    if (incomingHeaders instanceof Headers) {
      cookiesString = incomingHeaders.get('cookie') || '';
    } else if (Array.isArray(incomingHeaders)) {
      const cookieHeader = incomingHeaders.find((header) => header[0].toLowerCase() === 'cookie');
      cookiesString = cookieHeader ? cookieHeader[1] : '';
    } else if (typeof incomingHeaders === 'object') {
      cookiesString = incomingHeaders.cookie || '';
    }

    container.register('FetchOptions', {
      useValue: {
        headers: mapCookiesToHeaders(new Cookies(cookiesString)),
      },
    });
  } else {
    logger.warn('register-fetch-options.ts [incomingHeaders] does not exist');
  }
}
