import type { ThemeOptions } from '@mui/material/styles';
import * as font from '../fonts';

export const MuiButton: NonNullable<ThemeOptions['components']>['MuiButton'] = {
  /* Styles applied to the root element. */
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 48,
      padding: '6px 20px',
      textTransform: 'none',
      fontSize: 16,
      fontFamily: font.ICAText,
      fontWeight: 700,
      height: 48,
      boxShadow: 'none',
      lineHeight: 'normal',
      '&:hover': {
        boxShadow: 'none',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
      '&$disabled': {
        color: theme.palette['color/text/inverse-dark'] || 'inherit',
      },
    }),
    /* Styles applied to the span element that wraps the children. */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore This prop doesn't exist
    label: {},
    /* Styles applied to the root element if `variant="text"`. */
    text: ({ theme }) => ({
      padding: '8px 48px',
      boxShadow: 'none',
      color: theme.palette['color/text/brand'] || 'inherit',
      backgroundColor: 'transparent',
      '&$focusVisible': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/visual-dark'],
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/visual-dark'],
      },
      '&$disabled': {
        color: theme.palette['color/text/brand'] || 'inherit',
        backgroundColor: theme.palette['color/background/visual-dark'],
        opacity: 0.2,
      },
      '&:hover': {
        backgroundColor: theme.palette['color/background/visual-dark'],
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    }),
    /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
    textPrimary: {},
    /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
    textSecondary: {},
    /* Styles applied to the root element if `variant="outlined"`. */
    outlined: ({ theme }) => ({
      padding: '8px 48px',
      boxShadow: 'none',
      color: theme.palette['color/text/brand'] || 'inherit',
      border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette['color/text/inverse-dark'] || 'inherit',
        border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
        backgroundColor: theme.palette['color/background/action-dark'] || 'inherit',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '& svg': {
          '& path': {
            fill: theme.palette['color/icon/inverse-dark'] || 'inherit',
            color: theme.palette['color/icon/inverse-dark'] || 'inherit',
          },
        },
      },
      '&$disabled': {
        color: theme.palette['color/text/brand'] || 'inherit',
        border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
        backgroundColor: 'transparent',
        opacity: 0.2,
      },
    }),
    /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
    outlinedPrimary: ({ theme }) => ({
      color: theme.palette['color/text/action'],
      border: `1px solid ${theme.palette['color/border/action']}`,
      backgroundColor: theme.palette['color/background/elevated'],
      '&:hover': {
        color: theme.palette['color/text/action-dark'],
        border: `1px solid ${theme.palette['color/border/action-dark']}`,
        backgroundColor: theme.palette['color/background/elevated'],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette['color/background/elevated'],
        },
        '& svg': {
          '& path': {
            fill: theme.palette['color/icon/brand'],
            color: theme.palette['color/icon/brand'],
          },
        },
      },
      '&$disabled': {
        color: theme.palette['color/text/action-subtle'],
        border: `1px solid ${theme.palette['color/border/default']}`,
        backgroundColor: theme.palette['color/background/elevated'],
      },
    }),
    /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
    outlinedSecondary: ({ theme }) => ({
      color: theme.palette['color/text/brand'] || 'inherit',
      border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette['color/text/inverse-dark'] || 'inherit',
        border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
        backgroundColor: theme.palette['color/background/action-dark'] || 'inherit',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&$disabled': {
        color: theme.palette['color/text/brand'] || 'inherit',
        border: `2px solid ${theme.palette['color/border/action-dark'] || 'inherit'}`,
        backgroundColor: 'transparent',
        opacity: 0.2,
      },
    }),
    /* Styles applied to the root element if `variant="contained"`. */
    contained: ({ theme }) => ({
      padding: '8px 48px',
      boxShadow: 'none',
      color: theme.palette['color/text/inverse'] || 'inherit',
      backgroundColor: theme.palette['color/background/action-dark'] || 'inherit',
      '&$focusVisible': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/action'],
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/action'],
      },
      '&$disabled': {
        color: theme.palette['color/text/inverse-dark'] || 'inherit',
        backgroundColor: theme.palette['color/background/action-dark'] || 'inherit',
        opacity: 0.2,
      },
      '&:hover': {
        backgroundColor: theme.palette['color/background/action'],
        '@media (hover: none)': {
          backgroundColor: theme.palette['color/background/action'],
        },
      },
    }),
    /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
    containedPrimary: ({ theme }) => ({
      boxShadow: 'none',
      color: theme.palette['color/text/inverse'],
      backgroundColor: theme.palette['color/background/action'],
      '&$focusVisible': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/action'],
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette['color/background/action'],
      },
      '&$disabled': {
        color: theme.palette['color/text/subtle'],
        backgroundColor: theme.palette['color/background/default'],
      },
      '&:hover': {
        backgroundColor: theme.palette['color/background/action-dark'],
        '@media (hover: none)': {
          backgroundColor: theme.palette['color/background/action'],
        },
      },
    }),
    /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
    containedSecondary: {},
    /* Pseudo-class applied to the ButtonBase root element if the button is keyboard focused. */
    focusVisible: {},
    /* Pseudo-class applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the root element if `size="small"`. */
    sizeSmall: {
      height: 32,
      width: 'auto',
      minWidth: 44,
      padding: '8px 16px',
      fontSize: 14,
      borderRadius: 24,
      whiteSpace: 'nowrap',
    },
    sizeLarge: {
      fontSize: 18,
      height: 48,
      padding: '8px 20px',
    },
    /* Styles applied to the root element if `fullWidth={true}`. */
    fullWidth: {
      width: '100%',
    },
  },
};

export const ButtonBase = {
  // ripple: {},
  // buttonRipple: {},
  // touchRippleColor: 'inherit',
};
