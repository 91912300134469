import type { ThemeOptions } from '@mui/material/styles';
import { ICAText } from '../fonts';

export const MuiInputBase: NonNullable<ThemeOptions['components']>['MuiInputBase'] = {
  styleOverrides: {
    formControl: {
      // height: 40,
    },
    input: ({ theme }) => ({
      padding: 0,
      appearance: 'none',
      color: theme.palette['color/text/default'],
      margin: 0,
      height: 'inherit',
      fontSize: 16,
      fontFamily: ICAText,
      fontWeight: 400,
      '&::-ms-clear': {
        display: 'none',
      },
      '&.Mui-disabled': {
        color: theme.palette['color/text/action-subtle'],
      },
    }),
  },
};

export const MuiInput: NonNullable<ThemeOptions['components']>['MuiInput'] = {
  styleOverrides: {
    underline: ({ theme }) => ({
      '&:before': {
        borderBottom: `1px solid ${theme.palette['color/border/action-dark']}`,
      },
    }),
    // Make sure autofill doesn't cover label
    // formControl: {
    //   height: 32,
    //   'label + &': {
    //     marginTop: '24px',
    //   },
    // },
  },
};

export const MuiInputLabel: NonNullable<ThemeOptions['components']>['MuiInputLabel'] = {
  styleOverrides: {
    formControl: ({ theme }) => ({
      color: theme.palette['color/text/subtle'],
    }),
    shrink: ({ theme }) => ({
      color: theme.palette['color/text/brand'],
    }),
  },
};

export const MuiFilledInput: NonNullable<ThemeOptions['components']>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 'auto',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      backgroundColor: theme.palette['color/background/default'],
    }),
    underline: ({ theme }) => ({
      '&:before': {
        borderBottom: `1px solid ${theme.palette['color/border/action-dark']}`,
      },
      '&.Mui-disabled': {
        backgroundColor: '#cacaca',
        '&:before': {
          borderBottom: `1px solid ${theme.palette['color/border/action-subtle']}`,
        },
      },
    }),
  },
};

export const MuiOutlinedInput: NonNullable<ThemeOptions['components']>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 'auto',
      borderRadius: 12,
      '&.Mui-disabled': {
        backgroundColor: theme.palette['color/background/default'],
      },
      input: {
        '&.Mui-disabled': {
          textFillColor: theme.palette['color/text/subtle'],
        },
      },
    }),
  },
};
