import * as headings from '~/styles/elements/headings';
import * as text from '~/styles/elements/text';
import theme from '~/styles/theme';

export const classes = {
  content: {
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '& + &': {
      marginTop: theme.spacing(1),
      [theme.breakpoints.mqs.sm]: {
        marginTop: theme.spacing(2),
      },
    },

    // headings
    '& h1': {
      ...headings.h1,
      '& + *': { marginTop: theme.spacing(1) },
    },
    '& h2': {
      ...headings.h2,
      '& + h2, & + h3, & + h4, & + h5': { marginTop: theme.spacing(2) },
      '& + *': { marginTop: theme.spacing(0.5) },
    },
    '& h3': {
      ...headings.h3,
      '& + h2, & + h3, & + h4, & + h5': { marginTop: theme.spacing(2) },
      '& + *': { marginTop: theme.spacing(0.5) },
    },
    '& h4, & h5': {
      ...headings.h4,
      '& + h2, & + h3, & + h4, & + h5': { marginTop: theme.spacing(2) },
      '& + *': { marginTop: theme.spacing(0.5) },
    },

    // centered content
    '&.centered': {
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      textAlign: 'center',
    },

    // styling for first and last element
    '& > *:first-of-type': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },

    // p and span
    '& p, & span': {
      ...text.normal,
      margin: '0 0 0 0',
      display: 'block',
      width: '100%',
      maxWidth: 700,
      '& + *': { marginTop: theme.spacing(2) },
      '& + p': { marginTop: theme.spacing(1) },
    },
    '&.small-text p, &.small-text span, & p.small, & span.small': {
      ...text.small,
      maxWidth: 610,
    },

    // strong and bold
    '& strong, & b': {
      fontFamily: theme?.typography?.fontFamily,
      fontWeight: theme?.typography?.fontWeightBold,
    },

    // links
    '& a': {
      textDecoration: 'underline',
    },
    '& a[rel*="external"]': {},

    // hr
    '& hr': {
      border: 'none',
      borderTop: `1px solid ${theme.palette['color/border/action-dark']}`,
      margin: theme.spacing(2, 0, 0, 0),
      width: '100%',
    },

    // quotes
    '& blockquote': {},

    // images
    '& img, & picture': {
      width: 'auto',
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
    },
    '& * + img, & * + picture': {
      marginTop: theme.spacing(2),
    },

    // lists
    '& ul, & ol': {},
    '& ul': {
      ...text.normal,
      padding: theme.spacing(2, 0, 0, 3),
      margin: '0 0 0 0',
      display: 'block',
      width: '100%',
      listStyleType: 'none',
      '& + *': { marginTop: theme.spacing(2) },
      '& li': {
        position: 'relative',
        display: 'block',
        maxWidth: 700,
      },
      '& li:before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: theme.palette['color/background/visual-dark'],
        left: -24,
        top: 8,
        borderRadius: '100%',
      },
      '& li + li': {
        marginTop: 3,
      },
    },
    '& ol': {},

    // iframe
    '& iframe': {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      border: 'none',
      overflow: 'hidden',
    },
  },
};
