export const sendLoginEvent = (loginOrigin: string) => {
  const parsedOrigin = loginOrigin.replace('_', ' ');

  const event = {
    event: 'login',
    event_data: {
      login_type: parsedOrigin,
    },
    user_properties: {
      used_id: undefined,
      club_member: undefined,
    },
  };

  if (window && window.dataLayer) {
    window.dataLayer.push(event);
  }
};
