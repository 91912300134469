import type { ThemeOptions } from '@mui/material/styles';
import * as headings from '../elements/headings';
import { small } from '../elements/text';

export const MuiDialogTitle: NonNullable<ThemeOptions['components']>['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      ...headings.h2,
    },
  },
};
export const MuiDialogContentText: NonNullable<ThemeOptions['components']>['MuiDialogContentText'] =
  {
    styleOverrides: {
      root: ({ theme }) => ({
        ...small,
        color: theme.palette['color/text/default'],
      }),
    },
  };
