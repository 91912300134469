/* eslint-disable max-classes-per-file */
import React, { useContext } from 'react';
import { logger } from '~/logging';
import { FeatureToggleContext } from '../../../model/feature-toggle/FeatureToggleContext';
import { InvalidFeatureNameArgumentError } from '../../../model/feature-toggle/InvalidFeatureNameArgumentError';
import { useFeatureToggle } from '../../../model/feature-toggle/useFeatureToggle';

/**
 *
 * @summary The new or ON-track feature goes into this component.
 */
export const On: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isEnabled: isFeatureEnabled } = useFeatureToggle(
    useContext(FeatureToggleContext)?.featureName,
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isFeatureEnabled === true && children}</>;
};

/**
 *
 * @summary The default or OFF-track feature goes into this component. Will be used as default if there is an InvalidFeatureNameArgumentError
 */
export const Off: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isEnabled: isFeatureEnabled } = useFeatureToggle(
    useContext(FeatureToggleContext)?.featureName,
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isFeatureEnabled !== true && children}</>;
};

/**
 * @summary FeatureToggle component shows On (and all other children) if feature is enabled, but not Off-component. If feature is disabled (or not found) then Off is shown
 * @param featureName a string of the feature name that has to match for the feature the be enabled/active/On
 * @throws All errors except InvalidFeatureNameArgument which we swallow and kill the feature by not displaying the On-component
 */
export const FeatureToggleComponent: React.FC<{
  featureName: string;
  children?: React.ReactNode;
}> = ({ children, featureName }) => {
  try {
    return (
      <FeatureToggleContext.Provider value={{ featureName }}>
        {children}
      </FeatureToggleContext.Provider>
    );
  } catch (exception) {
    if (exception instanceof InvalidFeatureNameArgumentError) {
      logger.error('Invalid feature name argument error');
      return (
        <>
          {React.Children.toArray(children).filter((child) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (React.isValidElement(child) && (child as React.ReactElement<any>).type === On) {
              logger.debug(`Not showing feature: ${featureName} On-state`);
              return false;
            }
            return true;
          })}
        </>
      );
    }
    // Rethrow
    throw exception;
  }
};
