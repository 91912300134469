import { type Shadows, createTheme } from '@mui/material/styles';
import { roundedTokens } from '@aph/ui/design-tokens/rounded-tokens';
import { spacingTokens } from '@aph/ui/design-tokens/spacing-tokens';
import muiElements from '../mui-elements';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  breakpoints,
  typography,
  shape: {
    borderRadius: roundedTokens['rounded/base'],
  },
  spacing: spacingTokens['spacing/1'],
  components: { ...muiElements },
});

declare module '@mui/material/styles' {
  interface Theme {
    shadows: Shadows & { 25: string };
  }
}

// Adds a new boxShadow to the theme which can be used for all components that uses the elevation or boxShadow property with elevation={25} or boxShadow={25}.
// For more information about boxShadow, check https://mui.com/system/shadows/
theme.shadows.push('0 1px 2px 0 #eaeaea');

export default theme;
