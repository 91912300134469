import type { FC } from 'react';
import React from 'react';
import type { AlertProps } from '@mui/material';
import { Alert, AlertTitle, Box, IconButton, Stack, SvgIcon } from '@mui/material';
import ErrorIconFilled from '@aph/icons/alert_error_filled.svg';
import ErrorIconOutlined from '@aph/icons/alert_error_outlined.svg';
import InfoIconFilled from '@aph/icons/alert_info_filled.svg';
import InfoIconOutlined from '@aph/icons/alert_info_outlined.svg';
import SuccessIconFilled from '@aph/icons/alert_success_filled.svg';
import SuccessIconOutlined from '@aph/icons/alert_success_outlined.svg';
import WarningIconFilled from '@aph/icons/alert_warning_filled.svg';
import WarningIconOutlined from '@aph/icons/alert_warning_outlined.svg';
import CloseIcon from '@aph/icons/close.svg';
import theme from '~/styles/theme';
import { alertMappings } from './aph-alert-severity-variant-map';

type AphAlertProps = Pick<AlertProps, 'severity' | 'variant' | 'sx'> & {
  title?: React.ReactNode;
  children?: React.ReactNode;
  'data-testid'?: string;
  handleClose?: () => void;
};

export const AphAlert: FC<AphAlertProps> = ({
  children,
  severity,
  title,
  variant,
  'data-testid': dataTestId,
  handleClose,
}) => {
  const alertSettings = alertMappings.get(severity).get(variant || 'standard');

  const alertSX = {
    '&.MuiAlert-root': {
      paddingX: 1.5,
      paddingY: 0.5,

      borderColor: alertSettings.borderColor,
      borderWidth: '2px',
      borderStyle: 'solid',
      background: alertSettings.background,
      a: {
        color: theme.palette['color/text/default'],
        textDecorationColor: theme.palette['color/text/default'],
      },
    },
  };

  return (
    <Alert
      iconMapping={{
        warning:
          variant === 'standard' ? (
            <WarningIconFilled fontSize="inherit" aria-label='varnings ikon' />
          ) : (
            <WarningIconOutlined fontSize="inherit" aria-label='varnings ikon'/>
          ),
        error:
          variant === 'standard' ? (
            <ErrorIconFilled fontSize="inherit" aria-label='fel ikon'/>
          ) : (
            <ErrorIconOutlined fontSize="inherit" aria-label='fel ikon' />
          ),
        info:
          variant === 'standard' ? (
            <InfoIconFilled fontSize="inherit" aria-label='informations ikon'/>
          ) : (
            <InfoIconOutlined fontSize="inherit" aria-label='informations ikon'/>
          ),
        success:
          variant === 'standard' ? (
            <SuccessIconFilled fontSize="inherit" aria-label='framgångs ikon'/>
          ) : (
            <SuccessIconOutlined fontSize="inherit" aria-label='framgångs ikon'/>
          ),
      }}
      severity={severity}
      variant={variant || 'standard'}
      sx={alertSX}
      data-testid={dataTestId}
      action={
        handleClose ? (
          <Box
            component={IconButton}
            size="small"
            marginRight={1}
            color={alertSettings.color}
            aria-label="stäng"
            onClick={handleClose}
            tabIndex={0}
          >
            <SvgIcon fontSize="small">
              <CloseIcon />
            </SvgIcon>
          </Box>
        ) : null
      }
    >
      {title && (
        <AlertTitle
          data-pw="aph-alert-title"
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            color: alertSettings.color,
          }}
          // @ts-expect-error Props of the Typography component are available, see: https://mui.com/material-ui/api/alert-title/#props
          gutterBottom={false}
        >
          {title}
        </AlertTitle>
      )}
      <Stack color={alertSettings.color}>{children}</Stack>
    </Alert>
  );
};
