/**
 * @summary An error used in the feature toggle component only. To indicate that the featureName argument is missing
 */
export class InvalidFeatureNameArgumentError extends Error {
  constructor(message?: string) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidFeatureNameArgumentError.name; // stack traces display correctly now
  }
}
