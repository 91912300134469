import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import StatusCode from 'status-code-enum';
import { container } from 'tsyringe';
import type { UserInfo } from '~/services/generated/DispatcherClient';
import { ApiException, Client as DispatcherClient } from '~/services/generated/DispatcherClient';
import { HttpStatusCodes } from '~/services/middleware/HttpStatusCodes';
import type { StatusEventDispatcher } from '~/services/middleware/StatusEventDispatcher';

type AuthStateContextType = {
  authenticated: boolean | null;
  hasSession: boolean | null;
  userInfo: UserInfo | null;
  setIsAuthenticated: () => void;
  setIsNotAuthenticated: () => void;
  setHasNotSession: () => void;
};

const initialAuthStateContext: AuthStateContextType = {
  authenticated: null,
  hasSession: null,
  userInfo: null,
  setIsAuthenticated: () => {
    throw Error('auth provider setIsAuthenticated was not assigned');
  },
  setIsNotAuthenticated: () => {
    throw Error('auth provider setIsNotAuthenticated was not assigned');
  },
  setHasNotSession: () => {
    throw Error('auth provider setHasNotSession was not assigned');
  },
};

const AuthStateContext = createContext<AuthStateContextType>(initialAuthStateContext);

// eslint-disable-next-line no-shadow
enum AuthActionTypes {
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_NOT_AUTHENTICATED = 'SET_NOT_AUTHENTICATED',
  SET_HAS_NOT_SESSION = 'SET_HAS_NOT_SESSION',
  SET_USER_INFO = 'SET_USER_INFO',
}
type SetIsAuthenticated = {
  type: AuthActionTypes.SET_IS_AUTHENTICATED;
};
type SetNotAuthenticated = {
  type: AuthActionTypes.SET_NOT_AUTHENTICATED;
};
type SetHasNotSession = {
  type: AuthActionTypes.SET_HAS_NOT_SESSION;
};
type SetUserInfo = {
  type: AuthActionTypes.SET_USER_INFO;
  payload: {
    userInfo: UserInfo | null;
  };
};

type AnyAuthAction = SetIsAuthenticated | SetNotAuthenticated | SetHasNotSession | SetUserInfo;

const authReducer = (state: AuthStateContextType, action: AnyAuthAction) => {
  switch (action.type) {
    case AuthActionTypes.SET_IS_AUTHENTICATED: {
      return { ...state, authenticated: true };
    }
    case AuthActionTypes.SET_NOT_AUTHENTICATED: {
      return { ...state, authenticated: false };
    }
    case AuthActionTypes.SET_HAS_NOT_SESSION: {
      return { ...state, hasSession: false };
    }
    case AuthActionTypes.SET_USER_INFO: {
      return { ...state, userInfo: action.payload.userInfo };
    }
    default: {
      return state;
    }
  }
};

const AuthProvider: React.FC<{
  authInstance: StatusEventDispatcher;
  children?: React.ReactNode;
}> = ({ children, authInstance }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthStateContext);

  const setIsAuthenticated = () => {
    dispatch({ type: AuthActionTypes.SET_IS_AUTHENTICATED });
  };
  const setIsNotAuthenticated = () => {
    dispatch({ type: AuthActionTypes.SET_NOT_AUTHENTICATED });
  };
  const setHasNotSession = () => {
    dispatch({ type: AuthActionTypes.SET_HAS_NOT_SESSION });
  };
  const setUserInfo = (userInfo: UserInfo) => {
    dispatch({ type: AuthActionTypes.SET_USER_INFO, payload: { userInfo } });
  };

  useEffect(() => {
    const successSubscriber = {
      status: HttpStatusCodes.Success,
      act: () => {
        setIsAuthenticated();
      },
    };
    authInstance.subscribeToStatus(successSubscriber);

    const unauthenticatedSubscriber = {
      status: StatusCode.ClientErrorUnauthorized,
      act: () => {
        dispatch({ type: AuthActionTypes.SET_NOT_AUTHENTICATED });
      },
    };
    authInstance.subscribeToStatus(unauthenticatedSubscriber);

    return () => {
      authInstance.unsubscribe(successSubscriber);
      authInstance.unsubscribe(unauthenticatedSubscriber);
    };
  }, [authInstance]);

  useEffect(() => {
    let aborted = false;

    async function asyncEffect() {
      if ((state.authenticated || state.authenticated === null) && !state.userInfo) {
        const client = container.resolve(DispatcherClient);
        let userInfo;
        try {
          userInfo = await client.getUserInfo();
        } catch (ex) {
          if (ex instanceof ApiException && ex.status === 401) {
            return;
          }

          throw ex;
        }

        if (aborted) {
          return;
        }
        setUserInfo(userInfo);
      }
    }

    asyncEffect();

    return () => {
      aborted = true;
    };
  }, [state.authenticated, state.userInfo]);

  const authStateProviderValue = useMemo(
    () => ({
      ...state,
      setIsAuthenticated,
      setIsNotAuthenticated,
      setHasNotSession,
    }),
    [state],
  );

  return (
    <AuthStateContext.Provider value={authStateProviderValue}>{children}</AuthStateContext.Provider>
  );
};

const useAuthState = (): AuthStateContextType => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuthState };
