import { ChatEventTopic } from './chat-event-topic';
import { useChatEventActions, useChatEventActionsStore } from './use-chat-event-actions-store';

type useChatMediatorReturn = {
  notify: (topic: ChatEventTopic, onCompleted?: () => void, event?: Event | undefined) => void;
  subscribe: (topic: ChatEventTopic, callback: () => void) => void;
  unsubscribe: (topic: ChatEventTopic, callback: () => void) => void;
  initializeListeners: () => void;
};

export const useChatMediator = (): useChatMediatorReturn => {
  const { add, create, remove } = useChatEventActions();
  const {
    registeredActionsState: { registeredActions },
  } = useChatEventActionsStore();

  const notify = (topic: ChatEventTopic, onCompleted?: () => void) => {
    switch (topic) {
      case ChatEventTopic.OpenGiosg:
        registeredActions.get(ChatEventTopic.OpenGiosg)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.ResetTeneo:
        registeredActions.get(ChatEventTopic.ResetTeneo)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.OpenTeneo:
        registeredActions.get(ChatEventTopic.OpenTeneo)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.OpenContactUsModal:
        registeredActions.get(ChatEventTopic.OpenContactUsModal)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.CloseContactUsModal:
        registeredActions.get(ChatEventTopic.CloseContactUsModal)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.ContinueWithLiveChat:
        registeredActions
          .get(ChatEventTopic.ContinueWithLiveChat)
          ?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.FetchTeneoChatHistory:
        registeredActions
          .get(ChatEventTopic.FetchTeneoChatHistory)
          ?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.TwcReady:
        registeredActions.get(ChatEventTopic.TwcReady)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.Play:
        registeredActions.get(ChatEventTopic.Play)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.MinimizedGiosg:
        registeredActions.get(ChatEventTopic.MinimizedGiosg)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.MaximizedGiosg:
        registeredActions.get(ChatEventTopic.MaximizedGiosg)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.LivechatEnded:
        registeredActions.get(ChatEventTopic.LivechatEnded)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.LivechatStarted:
        registeredActions.get(ChatEventTopic.LivechatStarted)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.MinimizedTeneo:
        registeredActions.get(ChatEventTopic.MinimizedTeneo)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.TeneoClosed:
        registeredActions.get(ChatEventTopic.TeneoClosed)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.GiosgReady:
        registeredActions.get(ChatEventTopic.GiosgReady)?.forEach((fn) => fn(onCompleted));
        break;
      case ChatEventTopic.ConsentChanged:
        registeredActions.get(ChatEventTopic.ConsentChanged)?.forEach((fn) => fn(onCompleted));
        break;
      default:
        break;
    }
  };

  const subscribe = (topic: ChatEventTopic, callback: () => void) => {
    if (!registeredActions.get(topic)) {
      create(topic, callback);
    } else {
      add(topic, callback);
    }
  };

  const unsubscribe = (topic: ChatEventTopic, callback: () => void) => {
    remove(topic, callback);
  };

  const initializeListeners = () => {
    window.addEventListener(ChatEventTopic.ContinueWithLiveChat, () => {
      notify(ChatEventTopic.ContinueWithLiveChat, undefined);
    });
    window.addEventListener(ChatEventTopic.FetchTeneoChatHistory, () =>
      notify(ChatEventTopic.FetchTeneoChatHistory, undefined),
    );
    window.addEventListener(ChatEventTopic.TwcInitialized, () => {
      notify(ChatEventTopic.TwcReady, undefined);
    });
    window.addEventListener(ChatEventTopic.TeneoMinimize, () => {
      notify(ChatEventTopic.MinimizedTeneo, undefined);
    });
    window.addEventListener(ChatEventTopic.TeneoClosed, () => {
      notify(ChatEventTopic.TeneoClosed, undefined);
    });
    window.addEventListener(ChatEventTopic.ConsentChanged, () => {
      notify(ChatEventTopic.ConsentChanged, undefined);
    });
  };

  return {
    subscribe,
    unsubscribe,
    notify,
    initializeListeners,
  };
};
