import React from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { Button, type ButtonProps } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { getLoginRedirectUrl } from '@aph/utilities/login/login.utils';

type BankIdLogInButtonProps = ButtonProps & {
  titleId?: string;
  redirectLoginUrl: string;
  returnToUrl?: string;
};

export const BankIdLogInButton: React.FC<BankIdLogInButtonProps> = ({
  redirectLoginUrl,
  returnToUrl,
  titleId = 'COMMON.BUTTON.BANK_ID.LOGIN.TEXT',
  variant = 'primary',
  size = 'large',
  ...props
}) => {
  const router = useRouter();

  const onClick = () => {
    router.push(getLoginRedirectUrl(redirectLoginUrl, returnToUrl));
  };

  return (
    <Button data-testid="LOGIN.BUTTON" variant={variant} size={size} onClick={onClick} {...props}>
      <Icon name="BankId" size={size} />
      <FormattedMessage id={titleId} />
    </Button>
  );
};
