import theme from '~/styles/theme';

export const classes = {
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
    justifyContent: 'space-between',
    padding: 2,
  },
};
