export enum VisibleModal {
  ContactUsModal = 'ContactUs',
  TeneoChat = 'Teneo',
  GiosgChat = 'Giosg',
}

export type ChatState = {
  currentChatModal: VisibleModal;
  isGiosgReady: boolean;
  isTeneoReady: boolean;
  isMaximized: boolean;
  isLiveChatEnded: boolean;
  isFunctionalCookiesAccepted: boolean | null;
};

export const initialState: ChatState = {
  currentChatModal: VisibleModal.ContactUsModal,
  isGiosgReady: false,
  isTeneoReady: false,
  isMaximized: false,
  isLiveChatEnded: false,
  isFunctionalCookiesAccepted: null,
};
