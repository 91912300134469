// Currently defined in epi => https://git.ica.ia-hc.net/APH_eCom/ApotekHjartat.Web/-/blob/master/src/Avensia.ApotekHjartat.Web/Business/ImageResizer/ImageProxy.cs
// more here: https://git.ica.ia-hc.net/APH_eCom/ApotekHjartat.Web/-/blob/master/src/Avensia.ApotekHjartat.Web/Business/Helpers/ImageSize/ImageSizeHelper.cs#L51
export type ImagePreset = 'large' | 'medium' | 'small';

/*   
'large', // 300
'medium', // 150
'small', // 50 
*/

export const addImagePreset = (uri: string, preset: ImagePreset) => {
  const [path, queryParams] = uri.split('?', 2);
  const searchParams = new URLSearchParams(queryParams);
  searchParams.set('preset', preset);

  return `${path}?${searchParams.toString()}`;
};
