import { useEffect } from 'react';
import NoSsr from '@mui/material/NoSsr';
import { reloadOTBanner } from '@aph/components/cookie-consent/cookie-consent.utils';
import { LiveChatPlugin } from '@aph/components/live-chat/live-chat-plugin';
import { ChatBotPlugin } from './chat-plugin';
import { useChatActions } from './chat-store/use-chat-store';
import { ChatContainer } from './chat.container';
import { getHasAcceptedOneTrustByCategoryId } from './event-handlers';
import { constants } from './utils/constants';

export const ChatWrapper: React.FC = () => {
  const { setIsFunctionalCookiesAccepted } = useChatActions();
  useEffect(() => {
    setIsFunctionalCookiesAccepted(
      getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID),
    );
    reloadOTBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <NoSsr>
      <ChatContainer />
      <LiveChatPlugin />
      <ChatBotPlugin />
    </NoSsr>
  );
};
