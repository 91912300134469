import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { type ChatState, type VisibleModal, initialState } from './chat-state';

interface ChatStore {
  chatState: ChatState;
  updateCurrentChatStateModal: (visibleModal: VisibleModal) => void;
  setIsTeneoReady: (isLoaded: boolean) => void;
  setIsGiosgReady: (isLoaded: boolean) => void;
  setIsMaximized: (isMaximized: boolean) => void;
  setIsLivechatEnded: (isEnded: boolean) => void;
  setIsFunctionalCookiesAccepted: (hasAccepted: boolean) => void;
  getIsFunctionalCookiesAccepted: () => boolean | null;
  getIsMaximized: () => boolean;
  getCurrentModal: () => VisibleModal;
  getIsGiosgAndTeneoReady: () => boolean;
  getIsGiosgReady: () => boolean;
  getIsLivechatEnded: () => boolean;
  resetState: () => void;
  resetModalState: () => void;
}

export const useChatStore = create<ChatStore>()(
  devtools(
    persist(
      (set, get) => ({
        chatState: {
          ...initialState,
          currentChatModal: get()?.chatState?.currentChatModal || initialState.currentChatModal,
          isMaximized: get()?.chatState?.isMaximized || initialState.isMaximized,
        },
        updateCurrentChatStateModal: (visibleModal: VisibleModal) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              currentChatModal: visibleModal,
              isMaximized: true,
              isLiveChatEnded:
                state.chatState.currentChatModal === visibleModal
                  ? state.chatState.isLiveChatEnded
                  : false,
            },
          }));
        },
        setIsTeneoReady: (isLoaded: boolean) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              isTeneoReady: isLoaded,
            },
          }));
        },
        setIsGiosgReady: (isLoaded: boolean) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              isGiosgReady: isLoaded,
            },
          }));
        },
        setIsMaximized: (isMaximized: boolean) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              isMaximized,
            },
          }));
        },
        setIsLivechatEnded: (isEnded: boolean) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              isLiveChatEnded: isEnded,
            },
          }));
        },
        setIsFunctionalCookiesAccepted: (hasAccepted: boolean) => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              isFunctionalCookiesAccepted: hasAccepted,
            },
          }));
        },
        getIsFunctionalCookiesAccepted: () => {
          return get().chatState.isFunctionalCookiesAccepted;
        },
        getIsMaximized: () => {
          return get().chatState.isMaximized;
        },
        getCurrentModal: () => {
          return get().chatState.currentChatModal;
        },
        getIsGiosgReady: () => {
          return get().chatState.isGiosgReady;
        },
        getIsGiosgAndTeneoReady: () => {
          return get().chatState.isGiosgReady && get().chatState.isTeneoReady;
        },
        getIsLivechatEnded: () => {
          return get().chatState.isLiveChatEnded;
        },
        resetState: () => {
          set({ chatState: initialState });
        },
        resetModalState: () => {
          set((state) => ({
            chatState: {
              ...state.chatState,
              currentChatModal: initialState.currentChatModal,
              isMaximized: false,
            },
          }));
        },
      }),
      {
        name: 'chatState',
      },
    ),
    {
      name: 'UseChat',
      anonymousActionType: 'UseChat',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useChatActions = () =>
  useChatStore((state) => {
    return {
      setIsGiosgReady: state.setIsGiosgReady,
      setIsTeneoReady: state.setIsTeneoReady,
      updateCurrentChatStateModal: state.updateCurrentChatStateModal,
      setIsMaximized: state.setIsMaximized,
      setIsLivechatEnded: state.setIsLivechatEnded,
      getIsMaximized: state.getIsMaximized,
      getCurrentModal: state.getCurrentModal,
      getIsGiosgAndTeneoReady: state.getIsGiosgAndTeneoReady,
      getIsGiosgReady: state.getIsGiosgReady,
      getIsLivechatEnded: state.getIsLivechatEnded,
      resetState: state.resetState,
      setIsFunctionalCookiesAccepted: state.setIsFunctionalCookiesAccepted,
      getIsFunctionalCookiesAccepted: state.getIsFunctionalCookiesAccepted,
      resetModalState: state.resetModalState,
    };
  });
