import React, { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { AuthSessionTimer as SessionTimer } from '~/contexts/auth/AuthSessionTimer';
import { useAuthState } from '~/contexts/auth/auth.provider';
import { HttpStatusCodes } from '~/services/middleware/HttpStatusCodes';
import type { StatusEventDispatcher } from '~/services/middleware/StatusEventDispatcher';
import { touch } from './auth-session.api';
import { IdleTimer } from './auth.idle-timer.component';

const AuthSessionTimer: React.FC<{ authInstance: StatusEventDispatcher }> = ({ authInstance }) => {
  const [startIdleTimer, setStartIdleTimer] = useState<boolean>(false);

  const sessionTimer = container.resolve(SessionTimer);
  sessionTimer.handleTimeout = () => {
    setStartIdleTimer(true);
  };

  const { setHasNotSession } = useAuthState();

  useEffect(() => {
    const subscriber = {
      status: HttpStatusCodes.Success,
      act: () => {
        setStartIdleTimer(false);
        sessionTimer.restart();
      },
    };
    authInstance.subscribeToStatus(subscriber);
    return () => {
      // clean up subscribers and stop the timer
      authInstance.unsubscribe(subscriber);
      sessionTimer.stop();
    };
  }, [sessionTimer, authInstance]);

  if (startIdleTimer) {
    return (
      <IdleTimer
        touch={() => {
          touch();
          setStartIdleTimer(false);
        }}
        setHasNotSession={setHasNotSession}
      />
    );
  }
  return null;
};

export const AuthSessionTimerAuthenticationChecked: React.FC<{
  authInstance: StatusEventDispatcher;
}> = ({ authInstance }) => {
  const { authenticated } = useAuthState();
  if (authenticated) {
    return <AuthSessionTimer authInstance={authInstance} />;
  }
  return null;
};
