import theme from './theme';

const baseStyles = {
  html: {
    height: '100vh',
    textSizeAdjust: '100%',
    MozTextSizeAdjust: '100%',
  },
  body: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 280,
    height: 'auto',
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    position: 'relative',
    fontSmoothing: 'antialiased',
    textRendering: 'optimizeLegibility',
    backgroundColor: theme.palette['color/background/default'],
  },
  '#__next': {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    width: '100%',
  },
  '@keyframes Skeleton-pulse': {
    '0%': { opacity: '1' },
    '50%': { opacity: '0.4' },
    '100%': { opacity: '1' },
  },
  a: {
    color: theme.palette['color/text/action-dark'],
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
};

export { baseStyles };
