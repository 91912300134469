import { container } from 'tsyringe';
import { logger } from '~/logging';
import type { GetTokenResponseV4 } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const getToken: () => Promise<GetTokenResponseV4> = async () => {
  const client = container.resolve(CustomerClient);
  const result = await client.getTokenV4(client.uuid, 'client-admin', undefined);
  logger.info(`getSignedDataV4() was called, Correlation ID: ${client.uuid} `);
  return result;
};
