import type { ThemeOptions } from '@mui/material/styles';
import * as mqs from '../media-queries';

const MuiContainer: NonNullable<ThemeOptions['components']>['MuiContainer'] = {
  styleOverrides: {
    root: {
      paddingLeft: 8,
      paddingRight: 8,
      padding: 8,
      [mqs?.sm]: {
        paddingLeft: 8,
        paddingRight: 8,
        padding: 8,
      },
      [mqs?.md]: {
        paddingLeft: 8,
        paddingRight: 8,
        padding: 8,
      },
      [mqs?.lg]: {
        paddingLeft: 8,
        paddingRight: 8,
        padding: 8,
      },
      [mqs?.xl]: {
        paddingLeft: 0,
        paddingRight: 0,
        padding: 16,
      },
    },
  },
};

export default MuiContainer;
