const common = {
  // header
  'COMMON.HEADER.LOGO_LINK_TITLE': 'Apotek hjärtat',
  'COMMON.HEADER.LOGO_ALT_TEXT': 'Apotek hjärtat logo',
  'COMMON.HEADER.USP.FREE_FREIGHT': 'Frakt & leverans',
  'COMMON.HEADER.USP.FAST_DELIVERY': 'Snabba leveranser',
  'COMMON.HEADER.USP.ICA_BONUS': 'Samla ICA-bonus',
  'COMMON.HEADER.PROFILE.BUTTON.TEXT': 'Mina sidor',
  'COMMON.HEADER.PROFILE.BUTTON.TEXT.LOGOUT': 'Logga ut',
  'COMMON.HEADER.PROFILE.BUTTON.TEXT.LOGIN': 'Logga in',
  'COMMON.HEADER.PROFILE.BUTTON.TITLE.OPEN': 'Mina sidor',
  'COMMON.HEADER.PROFILE.BUTTON.TITLE.CLOSE': 'Mina sidor',
  'COMMON.HEADER.SEARCH': 'Sök',
  'COMMON.HEADER.SEARCH.PLACEHOLDER.DESKTOP': 'Hitta produkt eller läkemedel',
  'COMMON.HEADER.SEARCH.PLACEHOLDER.MOBILE': 'Sök bland våra produkter',
  'COMMON.HEADER.SEARCH.HITS':
    'Visa {total, plural, =0 {alla träffar} one {# träff} other {# träffar}}',
  'COMMON.HEADER.SEARCH.PRODUCTS': 'Produkter',
  'COMMON.HEADER.SEARCH.SUGGESTIONS': 'Sökförslag',
  'COMMON.HEADER.SEARCH.CATEGORIES': 'Kategorier',
  'COMMON.HEADER.SEARCH.RESULTS': 'Sökresultat',
  'COMMON.HEADER.SEARCH.NOHITS': 'Inga träffar',
  'COMMON.HEADER.SEARCH.ERROR.TITLE': 'Något gick fel',
  'COMMON.HEADER.SEARCH.ERROR.MESSAGE':
    'Något gick fel när sökresultatet skulle laddas. Försök igen',
  'COMMON.HEADER.SEARCH.CLOSE': 'Stäng',
  'COMMON.HEADER.SEARCH.CLEAR': 'Rensa',
  'COMMON.HEADER.SEARCH.FINDAPOTEK': 'Hitta apotek',
  'COMMON.HEADER.SEARCH.APOTEK': 'Apotek',
  'COMMON.HEADER.MENU': 'Meny',
  'COMMON.HEADER.CART': 'Varukorg',
  'COMMON.HEADER.RECIPES': 'Recept',
  'COMMON.HEADER.SHOPPING': 'Fortsätt handla',

  'COMMON.HEADER.CANCEL': 'Avbryt',
  // profile menu
  'COMMON.PROFILE.MENU.LOGGED.IN.AS': 'Inloggad som:',
  'COMMON.PROFILE.MENU.MY.PAGES.TEXT': 'Mina sidor',
  'COMMON.PROFILE.MENU.MY.BONUS.TEXT': 'Bonus',
  'COMMON.PROFILE.MENU.MY.BONUS.VALUE.LABEL': 'Samlade poäng:',
  'COMMON.PROFILE.MENU.MY.BONUS.VOUCHERS.LABEL': 'Bonuscheckar:',
  'COMMON.PROFILE.MENU.RECIPES.TEXT': 'Recept',
  'COMMON.PROFILE.MENU.CLUB.OFFERS.TEXT': 'Erbjudanden',
  'COMMON.PROFILE.MENU.ORDERS.TEXT': 'Ordrar',
  'COMMON.PROFILE.MENU.BECOME.MEMBER.TEXT': 'Bli medlem',
  'COMMON.PROFILE.MENU.POINTS': 'poäng',
  'COMMON.PROFILE.MENU.LOGOUT.TEXT': 'Logga ut',
  // search
  'COMMON.HEADER.SEARCH_BAR.PLACEHOLDER_TEXT': 'Sök',
  // footer
  'COMMON.FOOTER.SOCIAL.FOLLOW': 'Följ oss!',
  'COMMON.FOOTER.NEWSLETTER.TITLE': 'Prenumerera på vårt nyhetsbrev',
  'COMMON.FOOTER.NEWSLETTER.TEXT':
    'Vill du få personliga erbjudanden och lorem ipsum et dolor direkt till din inbox?',
  'COMMON.FOOTER.NEWSLETTER.TEXTFIELD': 'Ange din e-postadress',
  'COMMON.FOOTER.NEWSLETTER.SUBMIT': 'Skicka',
  'COMMON.FOOTER.LEGAL.COPYRIGHT_TEXT': '© {currentYear} Apotek Hjärtat AB.',
  'COMMON.FOOTER.LEGAL.COOKIE_TEXT': 'Vi använder cookies. ',
  'COMMON.FOOTER.LEGAL.COOKIE_LINK.TEXT': 'Läs mer om användning av cookies.',
  'COMMON.FOOTER.LEGAL.COOKIE_LINK.HREF': '/om-oss/information-om-cookies/',
  'COMMON.FOOTER.LEGAL.EU_LINK.TITLE': 'Kontrollera om webbplatsen är laglig',
  'COMMON.FOOTER.LEGAL.EU_LINK.HUMAN.ALT':
    'Kontrollera om webbplatsen är laglig för försäljning av läkemedel',
  'COMMON.FOOTER.LEGAL.EU_LINK.ANIMAL.ALT':
    'Kontrollera om webbplatsen är laglig för försäljning av djurläkemedel',
  'COMMON.FOOTER.LEGAL.LAKEMEDELSVARKET_LINK.TITLE': 'Apotek med tillstånd av Läkemedelsverket',
  // info
  'COMMON.INFO.SPA.TITLE': 'Här händer det grejer!',
  'COMMON.INFO.SPA.TEXT':
    'Vi moderniserar vår webbplats en sida i taget och därför kan saker se lite annorlunda ut framöver. Glöm inte att <a href="/logga-ut">Logga ut</a> när du är klar. Tillbaka till <a href="/mina-recept/mina-recept">Mina recept</a>.',
  'COMMON.INFO.SPA.TEXT.CHECKOUT':
    'Vi moderniserar vår webbplats en sida i taget och därför kan saker se lite annorlunda ut framöver. Glöm inte att <a href="/logga-ut">Logga ut</a> när du är klar.',
  'COMMON.INFO.SPA.TEXT.ORDER':
    'Vi moderniserar vår webbplats en sida i taget och därför kan saker se lite annorlunda ut framöver.',
  'COMMON.INFO.SPA.TEXT.START':
    'Vi moderniserar vår webbplats en sida i taget och därför kan saker se lite annorlunda ut framöver. Tillbaka till <a href="/">Startsidan</a>.',
  // form elements
  'COMMON.FORM_ELEMENTS.REQUIRED': 'Fältet är obligatoriskt',
  'COMMON.FORM_ELEMENTS.INPUT_PERSONAL_NUMBER.LABEL': 'Personnummer - 12 siffror',
  'COMMON.FORM_ELEMENTS.INPUT_PERSONAL_NUMBER.ARIA_LABEL': 'Ange personnummer med tolv siffror',
  'COMMON.FORM_ELEMENTS.INPUT_PERSONAL_NUMBER.ERROR':
    'Säkerställ att du angivit rätt. Tänk på att det skall vara tolv siffror utan mellanslag',
  'COMMON.FORM_ELEMENTS.INPUT_DATE.TOOLBAR.TODAY': 'Gå till idag',
  'COMMON.FORM_ELEMENTS.INPUT_DATE.TOOLBAR.SELECT_YEAR': 'Välj år',
  'COMMON.FORM_ELEMENTS.INPUT_DATE.TOOLBAR.SELECT_DATE': 'Välj datum',
  'COMMON.FORM_ELEMENTS.INPUT_STREET_NAME.LABEL': 'Adress',
  'COMMON.FORM_ELEMENTS.INPUT_STREET.LENGTH':
    'Du har angett för många tecken, max 35 st. Kontakta kundkontakt om du behöver hjälp',
  'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.LABEL': 'Mobilnummer',
  'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.ERROR':
    'Numret måste vara minst sju siffror långt och får endast innehålla siffror, + och - ',
  'COMMON.FORM_ELEMENTS.INPUT_MOBILENUMBER.MUST_BE_SWEDISH':
    'Endast svenska mobilnummer är giltiga',
  'COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.LABEL': 'Postnummer',
  'COMMON.FORM_ELEMENTS.INPUT_POSTAL_CODE.ERROR': 'Postnumret måste vara fem siffror',
  'COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.LABEL': 'Förnamn',
  'COMMON.FORM_ELEMENTS.INPUT_FIRST_NAME.ERROR':
    'Ditt namn är för långt, max 15 tecken. Om du har mellannamn kan du ta bort dem. Eller kontakta Kundkontakt.',
  'COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.LABEL': 'Efternamn',
  'COMMON.FORM_ELEMENTS.INPUT_LAST_NAME.ERROR':
    'Ditt efternamn är för långt, max 27 tecken. Om du har flera efternamn kan du prova ta bort ett. Eller kontakta Kundkontakt.',
  'COMMON.FORM_ELEMENTS.INPUT_MEMBERSHIP_NUMBER.LABEL': 'Medlemsnummer',
  'COMMON.FORM_ELEMENTS.INPUT_EMAIL.LABEL': 'E-post',
  'COMMON.FORM_ELEMENTS.INPUT_EMAIL.ERROR': 'Måste vara en e-post ex. namn@hjartat.se',
  'COMMON.FORM_ELEMENTS.INPUT_EMAIL.LENGTH':
    'Du har angett för många tecken, max 80 st. Kontakta kundkontakt om du behöver hjälp',
  'COMMON.FORM_ELEMENTS.INPUT_EMAIL_CONFIRM.LABEL': 'Verifiera din e-post',
  'COMMON.FORM_ELEMENTS.INPUT_EMAIL_CONFIRM.ERROR': 'E-post matchar inte',
  'COMMON.FORM_ELEMENTS.INPUT_CITY.LABEL': 'Stad',
  'COMMON.FORM_ELEMENTS.INPUT_CITY.LENGTH':
    'Du har angett för många tecken, max 20 st. Kontakta kundkontakt om du behöver hjälp',
  'COMMON.FORM_ELEMENTS.PHARMACY.REQUIRED': 'Du har glömt att välja apotek',
  'COMMON.FORM_ELEMENTS.DATE.POA.MIN': 'Datum kan inte vara i det förflutna',
  'COMMON.FORM_ELEMENTS.DATE.POA.MAX': 'Datum kan max var 4 år framåt i tiden',

  'COMMON.BUTTON.BANK_ID.LOGIN.TEXT': 'Logga in med BankID',

  // breadcrumbs
  'COMMON.BREADCRUMBS.HOME': 'Hem',

  // product card
  'COMMON.PRODUCT_CARD.IS_WEB_SALE_ONLY': 'Säljs bara online',

  // miscellaneous
  'COMMON.DISTANCE.IN_METERS': '{distance} m',
  'COMMON.DISTANCE.IN_KILOMETERS': '{distance} km',
  COMMON_WEEKDAY_MONDAY: 'Måndag',
  COMMON_WEEKDAY_TUESDAY: 'Tisdag',
  COMMON_WEEKDAY_WEDNESDAY: 'Onsdag',
  COMMON_WEEKDAY_THURSDAY: 'Torsdag',
  COMMON_WEEKDAY_FRIDAY: 'Fredag',
  COMMON_WEEKDAY_SATURDAY: 'Lördag',
  COMMON_WEEKDAY_SUNDAY: 'Söndag',
  COMMON_OPEN_TODAY: 'Öppet idag',

  // sort
  'COMMON.SORT_ORDER.LABEL': 'Sortera',

  // logged in session
  'COMMON.LOG_IN_SESSION.MODAL.HEADER': 'Vill du fortsätta vara inloggad?',
  'COMMON.LOG_IN_SESSION.MODAL.TEXT':
    'Du kommer snart att loggas ut då din BankID-session är på väg att ta slut. Om du väljer att logga ut kommer dina eventuella recept  som du har lagt i varukorgen inte att synas.',
  'COMMON.LOG_IN_SESSION.MODAL.BUTTON.CONFIRM': 'Fortsätt vara inloggad',
  'COMMON.LOG_IN_SESSION.MODAL.BUTTON.LOG_OUT': 'Logga ut',

  // log out
  'COMMON.LOG_OUT.HEADER': 'Logga in igen',
  'COMMON.LOG_OUT.TEXT': 'Blev du utloggad av misstag? Ingen fara. Logga in igen och fortsätt.',
  'COMMON.LOG_OUT.ALERT_HEADER': 'Du är nu utloggad',
  'COMMON.LOG_OUT.ALERT_TEXT':
    'Tänk på att information kan lagras temporärt i webbläsaren. Om någon annan har tillgång till denna enhet bör du stänga ner detta webbläsarfönster.',
};

const panels = {
  'PANELS.CLOSE_BUTTON.TITLE': 'Stäng',
};

const error = {
  'ERROR.HEADING': 'Oj, här var det tomt',
  'ERROR.TEXT':
    'Länken kan ha gått sönder, sidan kan ha tagits bort eller flyttats till en ny plats. Besök gärna någon av våra andra sidor istället.',
  'ERROR.LINKS.SHOP_ONLINE': 'Handla online',
  'ERROR.LINKS.GET_PRESCRIPTION': 'Hämta dina recept',
  'ERROR.LINKS.GET_TIP': 'Tips & råd',
  'ERROR.GENERAL': 'Något gick fel när sidan skulle laddas. Försök igen',
};

const checkout = {
  'CHECKOUT.HEADING': 'Kassa',
  'CHECKOUT.LOGIN.HEADING': 'Kom ihåg att logga in',
  'CHECKOUT.LOGIN.TEXT':
    'Som medlem i Klubb Hjärtat samlar du bonus när du handlar och får ta del av unika klubberbjudanden.',
  'CHECKOUT.CONTINUE_SHOPPING': 'Fortsätt handla',
  // cart empty notice
  'CHECKOUT.CART_EMPTY_NOTICE.HEADING': 'Varukorgen är tom',
  'CHECKOUT.CART_EMPTY_NOTICE.TEXT': 'Du har visst glömt att lägga något i varukorgen',
  // cart
  'CHECKOUT.CART.REMOVE_ITEM.TITLE': 'Ta bort produkt {title}',
  'CHECKOUT.CART.IMG.ALT': 'Bild av',
  // delivery
  'CHECKOUT.DELIVERY.HEADING': 'Leverans',
  'CHECKOUT.DELIVERY.SUB_TEXT': 'Fyll i ditt postnummer så hittar vi möjliga leveranssätt för dig.',
  'CHECKOUT.DELIVERY.FORM.INPUT.LABEL': 'Postnummer',
  'CHECKOUT.DELIVERY.FORM.INPUT.ADORNMENT': 'Ändra',
  'CHECKOUT.DELIVERY.FORM.INPUT.VALIDATION_ERROR_TEXT': 'Ofullständigt postnummer',
  'CHECKOUT.DELIVERY.FORM.INPUT.VALIDATION_SUCCESS_TEXT': 'Postnummer tillagt',
  'CHECKOUT.DELIVERY.FORM.BUTTON.TEXT': 'Fortsätt',
  'CHECKOUT.DELIVERY.FORM.BUTTON.TITLE': 'Fortsätt',
  // delivery warnings
  'CHECKOUT.DELIVERY.WARNING.TITLE': 'Du har varor som kan påverka dina fraktalternativ',
  'CHECKOUT.DELIVERY.WARNING.KYLVARA': 'Kylvara',
  'CHECKOUT.DELIVERY.WARNING.IDCHECK': 'Id-kontroll krävs vid utlämning/uthämtning av paketet',
  'CHECKOUT.DELIVERY.WARNING.PICKUPONLY': 'Hämta på apotek',
  'CHECKOUT.DELIVERY.WARNING.MAILDELIVERYONLY': 'Endast brev',
  'CHECKOUT.DELIVERY.WARNING.TEMPSENSITIVE': 'Temperaturkänslig',
  // payment
  'CHECKOUT.PAYMENT.HEADING': 'Betalning',
  'CHECKOUT.PAYMENT.SUB_TEXT':
    'Glöm inte kontrollera adressuppgifterna dit beställningen ska skickas',
  'CHECKOUT.PAYMENT.INFO':
    'Vilka produkter som finns i din varukorg kan påverka vilka fraktval du kan göra. Innan vi går live till kunder kommer vi lägga till funktionalitet för att visa olika meddelanden beroende på produktmix i varukorgen',
  // prescription advice
  'CHECKOUT.PRESCRIPTION_ADVICE.HEADLINE': 'Råd om ditt läkemedel',
  'CHECKOUT.PRESCRIPTION_ADVICE.INFO':
    'Känner du till hur och när ditt läkemedel ska tas eller hur olika läkemedel fungerar ihop? Har du andra funderingar kring din behandling? Vi hjälper dig att få ut bästa möjliga effekt av dina läkemedel. Någon av våra farmaceuter ringer upp dig från 0771-405 405 till det nummer som finns inlagt i betalningssteget.',
  'CHECKOUT.PRESCRIPTION_ADVICE.ERROR':
    'Just nu fungerar inte vår tjänst, ring 0771-405 405 för att prata med en farmaceut.',
  'CHECKOUT.PRESCRIPTION_ADVICE.WANT_ADVICE': 'Ja, ring mig',
  'CHECKOUT.PRESCRIPTION_ADVICE.DO_NOT_WANT_ADVICE': 'Nej tack',
  'CHECKOUT.PRESCRIPTION_ADVICE.PHONE_NUMBER_INPUT.PLACEHOLDER': 'Telefonnummer',
  'CHECKOUT.PRESCRIPTION_ADVICE.CONTINUE_BUTTON.TITLE': 'Vidare',
  'CHECKOUT.PRESCRIPTION_ADVICE.CONTINUE_BUTTON.TEXT': 'Vidare',
  'CHECKOUT.PRESCRIPTION_ADVICE.WANT_ADVICE.TEXT':
    'En farmaceut ringer upp dig inom 48 timmar på vardagar',
  'CHECKOUT.PRESCRIPTION_ADVICE.DO_NOT_WANT_ADVICE.TEXT':
    'Om du skulle ångra dig når du oss på 0771-405 405, Vardagar 8-18:30, lördagar 9-14',
  'CHECKOUT.PRESCRIPTION_ADVICE.PHONE_NUMBER_INPUT.ERROR_TEXT': 'Felaktigt telefonnummer',
  'CHECKOUT.PRESCRIPTION': 'Recept för',
  'CHECKOUT.PRESCRIPTION.ANIMAL': 'Djurrecept',
  // klarna validation error
  'CHECKOUT.KLARNA.VALIDATION.INTERNAL':
    'Tyvärr gick något fel med köpet, var vänlig och försök igen',
  'CHECKOUT.KLARNA.VALIDATION.SHIPMENT':
    'Ditt postnummer överensstämmer inte med den ni angivit i Klarna',
  'CHECKOUT.KLARNA.VALIDATION.UNDERAGE': 'Ni måste vara över 18 år för att handla hos oss',
  'CHECKOUT.KLARNA.VALIDATION.MISMATCH':
    'Din varukorg överensstämmer inte med varukorgen i Klarna, var vänlig att ladda om kassan!',
  'CHECKOUT.KLARNA.VALIDATION.EMPTY': 'Din varukorg är tom',
  'CHECKOUT.KLARNA.VALIDATION.MODAL.TITLE': 'Hoppsan!',
  'CHECKOUT.KLARNA.VALIDATION.MODAL.REFRESH': 'Ladda om',
  'CHECKOUT.KLARNA.VALIDATION.UNDERAGE.OTC':
    'Du har nått maxantalet om köp per kund av receptfria varor för dem som är under 18 år. Branschen har kommit överens om att begränsa antal köp du kan göra för att minska riskerna med överdosering eller felanvändning.',
  'CHECKOUT.KLARNA.VALIDATION.SHIPPINGADDRESS.CITY':
    'Ortnamnet får inte innehålla fler än 30 tecken. Vänlig kontrollera och försök igen.',
  // climate info
  'CHECKOUT.DELIVERY.CLIMATEINFO.TITLE': 'Tips!',
  'CHECKOUT.DELIVERY.CLIMATEINFO.TEXT':
    'Välj leveransalternativet märkt med Klimatval, det har lägst klimatpåverkan (koldioxidutsläpp).',
  // checkout confirm / thank you
  'CHECKOUT.CONFIRM.HEADING': 'Orderbekräftelse',
  'CHECKOUT.DELIVERY.INFO.TITLE': 'Tack för din beställning!',
  'CHECKOUT.CONFIRM.INFO.TEXT':
    'En orderbekräftelse kommer skickas till din e-post. Hälsningar Apotek Hjärtat!',
  'CHECKOUT.CONFIRM.ICA.HREF':
    'https://handla.ica.se/handla/?utm_source=apotek-hjartat-tacksida&utm_medium=banner&utm_campaign=handla_online',
  'CHECKOUT.CONFIRM.ICA_ALT_TEXT': 'Handla på ICA.se',
  // checkout delighted
  'CHECKOUT.DELIGHTED.HEADLINE': 'Vad tyckte du om din köpupplevelse?',
  'CHECKOUT.DELIGHTED.INFO':
    'Vi vill alltid ge dig så bra service som möjligt, och hoppas därför att du vill svara på några snabba frågor om din köpupplevelse.',
  'CHECKOUT.DELIGHTED.HREF': 'https://delighted.com/t/KOoxroVl',
  // promotion code
  'CHECKOUT.PROMOTIONCODE.TITLE': 'Lägg till rabattkod',
  'CHECKOUT.PROMOTIONCODE.INPUT': 'Rabattkod',
  'CHECKOUT.PROMOTIONCODE.REMOVE': 'Ta bort rabattkod',
  'CHECKOUT.PROMOTIONCODE.VALIDATION_SUCCESS_TEXT': 'Rabattkod aktiverad',
  // vouchers
  'CHECKOUT.VOUCHER.TITLE': 'Klicka i den bonus du vill använda',
  'CHECKOUT.VOUCHER.VALID.TILL': 'Giltig t o m {date}',
  'CHECKOUT.VOUCHER.INFO':
    'Bonuscheckar kan bara användas när varukorgens värde är större än bonuschecken. De kan ej användas till receptvaror eller fraktkostnader. Max 9 st per köp.',
  'CHECKOUT.VOUCHER.ERROR.INFO.TITLE': 'Ojdå!',
  'CHECKOUT.VOUCHER.CHECKBOX': 'Använd bonuscheck',
};

const checkoutConfirm = {
  'CHECKOUT_CONFIRM.HEADING': 'Orderbekräftelse',
};

const cart = {
  'CART.HEADING': 'Kassa',
  'CART.ERROR_MESSAGE': 'Något gick fel, försök igen.',
  'CART.LINEITEM.HV.DISCOUNT': 'Pris innan rabatt',
  'CART.LINEITEM.RX.SUBSIDIZED': 'Pris innan förmån',
};

const summary = {
  'SUMMARY.BENEFIT': 'Förmånsrabatt',
  'SUMMARY.GROSS': 'Pris innan förmån',
  'SUMMARY.PARTSUM': 'Delsumma recept',
  'SUMMARY.SHIPPING': 'Frakt',
  'SUMMARY.SHIPPING_DEFAULT_PRICE': 'Från 0:-',
  'SUMMARY.TOTAL': 'Totalsumma',
  'SUMMARY.DISCOUNT': 'Du sparar totalt',
  'SUMMARY.VAT': 'Varav moms',
  'SUMMARY.PRODUCTTOTAL': 'Summa',
  'SUMMARY.VOUCHER': 'Bonuscheck',
  'SUMMARY.HV.PARTSUM': 'Pris innan rabatt',
  'SUMMARY.HV.TOTAL': 'Delsumma',
  'SUMMARY.CART.HV': 'Summering av varukorg',
  'SUMMARY.CART.RX': 'Summering av receptpåse',
  'SUMMARY.CART.TOTAL': 'Summering av totalen',
};

const tooltip = {
  'TOOLTIP.RX_QUANTITY_LIMIT': 'Du har redan maxantalet för detta uttag i varukorgen',
};

const prescription = {
  'MY_PRESCRIPTIONS.PAGE.PREAMBLE':
    'Här kan du se dina aktuella recept och beställa dem hem till dig eller till ditt apotek. Du kan också hämta ut recept för barn under 13 år, djur samt personer du har fullmakt för. Glöm inte att logga ut när du är klar.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.SAVED_PRESCRIPTIONS.TITLE':
    'Aktuella recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.SAVED_ANIMAL_PRESCRIPTIONS.TITLE':
    'Mina sparade djurrecept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.SAVED_ANIMAL_PRESCRIPTIONS.SUB_TEXT':
    '(Aktuella djurrecept)',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM_ALERT.SAVED_ANIMAL_PRESCRIPTIONS.TITLE':
    'Mina sparade djurrecept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.HIGH_COST_PROTECTION.TITLE':
    'Mitt högkostnadsskydd',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.HIGH_COST_PROTECTION.SUB_TEXT':
    '(Läkemedelsinköp)',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.DOCUMENT_ITEM.MEDICINES_LIST.TITLE':
    'Uthämtade läkemedel',
  'MY_PRESCRIPTIONS.PRESCRIPTION_DOCUMENTS.PANEL_LINK_ITEM.BUTTON.TEXT': 'Hämta PDF',
  // POWER_OF_ATTORNEY_GIVEN_LIST
  'MY_DESCENDANTS.POWER_OF_ATTORNEY': 'Fullmakter',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.TITLE': 'Fullmakter jag har gett',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.HAS_NO_DESCENDANTS': 'Du har inga fullmakter',
  // give poa button
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.GIVE_POA_BUTTON.TEXT': 'Ge fullmakt',
  // give poa panel
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.GIVE_POA_PANEL.INPUT_DATE.LABEL':
    'Giltig t.o.m. (max 4 år)',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.GIVE_POA_PANEL.INPUT_CONSENT.LABEL':
    'Jag har tagit del av informationen och ger härmed mitt samtycke till denna behandling',
  // remove poa button
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_GIVEN_LIST.REMOVE_POA_GIVEN.BUTTON.TITLE':
    'Ta bort given fullmakt',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_RECEIVED_LIST.REMOVE_POA.BUTTON_RECEIVED.TITLE':
    'Ta bort mottagen fullmakt',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.REMOVE_CHILD.TITLE': 'Ta bort sparat barn',
  // POWER_OF_ATTORNEY_RECEIVED_LIST
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_RECEIVED_LIST.TITLE': 'Fullmakter jag har fått',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_RECEIVED_LIST.HAS_NO_DESCENDANTS': 'Du har inga fullmakter',
  // remove poa panel
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_RECEIVED_LIST.REMOVE_POA_PANEL.TITLE': 'Avsluta fullmakt',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_RECEIVED_LIST.REMOVE_POA_PANEL.PREAMBLE':
    'Är du säker på att du vill avsluta fullmakten?',
  // POWER_OF_ATTORNEY_CHILDREN_LIST
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.TITLE': 'Mina barn',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.HAS_NO_DESCENDANTS': 'Du har inga barn tillagda',
  // add child panel
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.ADD_CHILD_PANEL.CONSENT.LABEL':
    'Ja, jag samtycker till att Apotek Hjärtat sparar uppgifterna för nästkommande besök',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.ADD_CHILD_PANEL.CONSENT.ERROR_MESSAGE':
    'Du måste samtycka för att vi ska kunna spara uppgifterna till nästa besök',
  // remove child panel
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.REMOVE_CHILD_PANEL.TITLE': 'Ta bort barn',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.REMOVE_CHILD_PANEL.PREAMBLE':
    'Är du säker på att du vill ta bort tillagt barn?',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.REMOVE_CHILD_PANEL.SUBMIT_BUTTON.DEFAULT_TEXT':
    'Ta bort',
  // add child button
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_CHILDREN_LIST.ADD_CHILD_BUTTON.TEXT': 'Lägg till barn',
  // POWER_OF_ATTORNEY_ITEM
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.EXPIRES_ON_DATE': 'Giltig t.o.m. {date}',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.CONSENT_NOT_GIVEN.RECEIVED_LINK.TEXT': 'Samtyck här',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.RENEW_POA_BUTTON': 'Förnya fullmakt',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.CONSENT_NOT_GIVEN.GIVEN.REMINDER':
    'Inväntar {name} samtycke. Samtycket måste lämnas senast {date}',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.CONSENT_NOT_GIVEN.GIVEN.REMINDER_MISSING_DATE':
    'Inväntar {name} samtycke.',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.CONSENT_NOT_GIVEN.RECEIVED.REMINDER':
    'För att fullmakten ska gälla så behöver du ta ställning till samtycket senast {date}',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.CONSENT_NOT_GIVEN.RECEIVED.REMINDER_MISSING_DATE':
    'Du behöver ta ställning till samtycket.',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.RECEIVED.EXPIRES_SOON_REMINDER':
    'Fullmakten håller på att gå ut och behöver förnyas av {name} senast {date}',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY_ITEM.GIVEN.EXPIRES_SOON_REMINDER':
    'Fullmakten håller på att gå ut och behöver förnyas senast {date}',

  // POWER_OF_ATTORNEY_INFORM_RECEIVER
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.INFORM.RECEIVER.HEADER': 'Toppen! Nästa steg...',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.INFORM.RECEIVER.TEXT':
    'Nu behöver {name} logga in på receptsidan och godkänna samtycket. Därefter är det klart. Tänk på att samtycket måste godkännas inom 7 dagar.',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.INFORM.RECEIVER.BUTTON': 'Ok',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.INFORM.RECEIVER_UNKNOWN': 'personen som fått fullmakten',

  // POWER_OF_ATTORNEY_RENEW
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.RENEW.INFO_ALERT':
    'Du har en eller flera fullmakter som behöver förnyas',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.RENEW.INFO_ALERT.CONSENT':
    'Du har en eller flera samtycken att ta ställning till',
  'MY_DESCENDANTS.POWER_OF_ATTORNEY.RENEW.INFO_ALERT.BUTTON': 'Ta mig till fullmaktssidan',

  // PRESCRIPTION_LIST_HEADER

  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.PRESCRIPTION_LIST_HEADER.CURRENT_BUTTON': 'Aktuella recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.PRESCRIPTION_LIST_HEADER.CURRENT_BUTTON.TITLE':
    'Visa aktuella recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.PRESCRIPTION_LIST_HEADER.HISTORIC_BUTTON':
    'Historiska recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.PRESCRIPTION_LIST_HEADER.HISTORIC_BUTTON.TITLE':
    'Visa historiska recept',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_LIST.PRESCRIPTION_LIST_HEADER.ANIMAL': 'Djurrecept',

  // PRESCRIPTION_INFORMATION
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.CUSTOMER_NAME_TEXT_ANIMAL':
    'Djurrecept tillhörande {name}',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.CUSTOMER_NAME_TEXT_HUMAN':
    'Recept tillhörande {name}',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.DOSAGE_TITLE': 'Dosering',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.ANIMAL_IDENTITY_TITLE': 'Djuridentitet',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.INDICATION_FOR_PRESCRIPTION_TITLE':
    'Behandlingsändamål',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.ANIMAL_QUALIFYING_TITLE': 'Karens',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.PRESCRIBER_TITLE': 'Förskrivare',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_INFORMATION.WITHDRAWAL_INTERVAL': `Expeditionsintervall: {value} {unit, select,
      Days {dygn}
      Weeks {{value, plural, one {vecka} other {veckor}}}
      Months {{value, plural, one {månad} other {månader}}}
      other {value}
    }`, // Remove after april release
  // Nytt
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.TOTAL_AMOUNT_REMAINING': 'Mängd kvar på receptet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.LATEST_WITHDRAWAL_TEXT': 'Senaste uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.FIRST_WITHDRAWAL_BEFORE_TEXT': 'Första uttag senast',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.WITHDRAWALS_TEXT': 'Antal förpackningar per uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.PACKS_PER_WITHDRAWAL':
    '{numberOfPacksPerWithdrawal} förpackningar per uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.AMOUNT_PER_WITHDRAWAL': 'Mängd per uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.WITHDRAWAL_INTERVAL': 'Expeditionsintervall',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.WITHDRAWAL_INTERVAL_DETAILS': `{value} {unit, select,
    Days {dygn}
    Weeks {{value, plural, one {vecka} other {veckor}}}
    Months {{value, plural, one {månad} other {månader}}}
    other {value}
  }`,
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.OK_TO_REPLACE': 'Får bytas ut',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.BULLET_BENEFITS_FULFILLED': 'Förmånsvillkor uppfyllda',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.BULLET_PRESCRIPTIONS_LEFT': 'Uttag kvar',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.BULLET_VALID_TO': 'Gäller till',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.PATIENT_IS_ENTITLED_TO_BENEFIT': 'Förmånsberättigad',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.MISSING_DOSAGE_DECRIPTION': 'Dosinstruktion saknas',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.WORKPLACE': 'Arbetsplats',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.ADDRESS': 'Adress',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.USAGE_DOSAGE_START_DATE': 'Första doseringsdag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.USAGE_DOSAGE_END_DATE': 'Sista doseringsdag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.STARTER_PACKAGE': 'Startförpackning kan hämtas ut',
  'MY_PRESCRIPTIONS.PRESCRIPTION_INFORMATION.SML': 'Förskriven enligt smittskyddslagen',

  // PRESCRIPTION_HEADER_CONTENT
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_HEADER.GET_PRESCRIPTIONS_FOR':
    'Välj vems recept du vill se',

  // PRESCRIPTIONS NAVIGATION MENU
  'MY_PRESCRIPTIONS.NAV.PRESCRIPTIONS': 'Hämta ut recept',
  'MY_PRESCRIPTIONS.NAV.POWER_OF_ATTORNEYS': 'Hantera fullmakter & barn',
  'MY_PRESCRIPTIONS.NAV.DOCUMENTS': 'Hämta läkemedelslistor',

  // PRESCRIPTIONS PAGE TITLES
  'MY_PRESCRIPTIONS.PAGETITLE.PRESCRIPTIONS': 'Hämta ut recept',
  'MY_PRESCRIPTIONS.PAGETITLE.POWER_OF_ATTORNEYS': 'Hantera fullmakter & barn',
  'MY_PRESCRIPTIONS.PAGETITLE.DOCUMENTS': 'Hämta läkemedelslistor',
  'MY_PRESCRIPTIONS.PAGETITLE.LOG_IN': 'Mina recept - Hämta ut recept online',
  'MY_PRESCRIPTIONS.PAGETITLE.PRESCRIPTION_LIST': 'Receptlista',

  // PRESCRIPTION H1 TITLES
  'MY_PRESCRIPTIONS.TITLE.POWER_OF_ATTORNEYS': 'Fullmakter & barn för {name}',

  // ANIMAL_PRESCRIPTION_LIST
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_LIST.EMPTY_LIST.HEADER.ACTIVE': 'Listan är tom',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_LIST.EMPTY_LIST.DESCRIPTION.ACTIVE':
    'Just nu finns inga djurrecept att hämta ut',

  // HUMAN_PRESCRIPTION_LIST
  'MY_PRESCRIPTIONS.HUMAN_PRESCRIPTION_LIST.EMPTY_LIST.HEADER.ACTIVE': 'Listan är tom',
  'MY_PRESCRIPTIONS.HUMAN_PRESCRIPTION_LIST.EMPTY_LIST.DESCRIPTION.ACTIVE':
    'Just nu finns inga recept att hämta ut',

  // PRESCRIPTION_LIST
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.EMPTY_LIST.HEADER.EXPIRED': 'Inga historiska recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.PRESCRIPTION_BUTTON.ITEM_IN_CART_TEXT': 'Ligger i varukorgen',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.TABS_OPTIONS.ARIA_LABELS':
    'Mina aktiva och historiska recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.TAB_LABEL': '{label} ({items}st)',

  // ANIMAL_PRESCRIPTION_CARD
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD.BUY_BUTTON.BUYABLE_TEXT': 'Lägg i varukorg',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD.BUY_BUTTON.NOT_BUYABLE_TEXT': 'Tillfälligt slut',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD.BUY_BUTTON.ITEM_IN_CART_TEXT': 'Ligger i varukorgen',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD.BUY_BUTTON.CONTACT_US_TEXT': 'Kontakta oss',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD_BODY.LOCAL_PHARMACY_STOCK_TEXT.NONE_SELECTED':
    'Du har inte valt apotek.',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD_BODY.LOCAL_PHARMACY_STOCK_TEXT.SELECTED':
    '{stockQuantity} i lager på ditt valda apotek:',
  'MY_PRESCRIPTIONS.ANIMAL_PRESCRIPTION_CARD_BODY.LOCAL_PHARMACY_NAME.FIND_PHARMACY':
    'Hitta närmaste apotek',

  // HIGH_COST_CONNECTION
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TITLE.HUMAN_TITLE': 'Aktuell rabattnivå för {name}',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TITLE.ANIMAL_TITLE':
    'Inget högkostnadsskydd för djur',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TITLE.CHILD_TITLE': 'Högkostnadsskydd för barn',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.PERIOD_END_DATE':
    'Högkostnadsperiod gäller t.o.m: {currentPeriodEndDate} ',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.BALANCE': 'Du har betalat: {balanceNet}',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.AMOUNT_UNTIL_FREE':
    'Kvar till frikort: {amountUntilFreeNet}',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.REACHED_DISCOUT_PERCENT':
    'Uppnådd rabattnivå: {currentDiscountPercent}%',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.ANIMAL_TEXT':
    'Djurläkemedel omfattas inte av högkostnadsskyddet.',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.CARD_TEXT.CHILD_TEXT':
    'Barn under 18 år samt asylsökande barn och barn som vistas i Sverige utan nödvändiga tillstånd erbjuds kostnadsfria läkemedel och varor som ingår i läkemedelsförmånerna.',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.BUTTON_TEXT.READ_MORE_BUTTON':
    'Jag vill veta mer om högkostnadsskyddet',
  'MY_PRESCRIPTIONS.HIGH_COST_PROTECTION.BUTTON_TEXT.SHOW_HIGH_COST_MODAL': 'Visa högkostnadsskydd',

  // PRESCRIPTION_ORDER_CARD
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.CHOOSE_PRESCRIPTION': 'Välj recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.CHOOSE_ARTICLE': 'Välj läkemedel',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_ON_PRESCRIPTION_TITLE': 'På ditt recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_RECOMMENDED_SUBSTITUTE_TITLE':
    'Rekommenderat utbyte',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_SUBSTITUTE_TITLE': 'Annat utbyte',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_EXPLAINING_TEXT': 'Vad är utbyte?',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_CHOSE_TITLE': 'Välj',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HEADER_CHOSEN_TITLE': 'Vald vara',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRICE_PER_PACKAGE_TEXT': 'Pris per förpackning',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRICE_TOTAL': 'Totalt',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_LATEST_WITHDRAWAL':
    'Senast uthämtat: {date}',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_FOOD_WITHIN_BENEFIT':
    'Livsmedel till barn under 16 år kostar max 120 kr per uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_INCLUDED_IN_HIGH_COST_PROTECTION':
    'Hela beloppet ingår i högkostnadsskyddet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_INCLUDED_IN_HIGH_COST_PROTECTION_CHILD':
    'Hela beloppet är kostnadsfritt för barn',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_PARTLY_INCLUDED_IN_HIGH_COST_PROTECTION':
    'Ingår delvis i högkostnadsskyddet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_PARTLY_INCLUDED_IN_HIGH_COST_PROTECTION_CHILD':
    'Beloppet är delvis kostnadsfritt för barn',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_NOT_INCLUDED_IN_HIGH_COST_PROTECTION':
    'Ingår inte i högkostnadsskyddet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.PRESCRIPTION_INFORMATION_NOT_INCLUDED_IN_HIGH_COST_PROTECTION_CHILD':
    'Beloppet är inte kostnadsfritt för barn',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.OUT_OF_STOCK': 'Beställningsvara',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.NOT_IN_STOCK_WITH_REST':
    'Osäker tillgång, slut hos leverantör',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.IN_STOCK': 'Finns i lager',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.ARTICLES_IN_STOCK_TEXT': '{stockQuantity} i webblager',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.WEB_STOCK_MISSING': 'Lagerstatus saknas',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.MINIMUM_WITHDRAWAL_INTERVAL': 'Minsta tid mellan uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.MINIMUM_WITHDRAWAL_INTERVAL_TEXT':
    'Observera att det här receptet är utfärdat med ett uttagsintervall.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HIGHCOST_WITHDRAWAL_TOO_EARLY':
    'För tidigt att hämta ut med högkostnadsskydd',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HIGHCOST_WITHDRAWAL_TOO_EARLY_CHILD':
    'För tidigt att hämta ut kostnadsfritt',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HIGHCOST_WITHDRAWAL_POSSIBLE':
    'Nytt uttag möjligt inom högkostnadsskyddet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.HIGHCOST_WITHDRAWAL_POSSIBLE_CHILD':
    'Nytt kostnadsfritt uttag möjligt',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.CART_ADD_ERROR':
    'Det gick inte att lägga till artikeln i varukorgen',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.BUY_BUTTON.BUYABLE_TEXT': 'Lägg i varukorgen',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.ARTICLE_STATUS_NOT_ACTIVE_BY_EHM':
    'Varan med varunummer {itemNumber} har utgått',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.ARTICLE_STATUS_LOCKED':
    'Kan inte köpas online. Kontakta oss eller besök ett lokalt Apotek Hjärtat.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.ABOUT_TO_EXPIRE': 'Receptet går ut {date}',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.MAX_QUANTITY_REASON':
    'Du har uppnått maxantal för detta uttag',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.REFRIGERATED_DELIVERY':
    'Kylvara. Kan levereras hem till dig.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD.SPECIAL_DELIVERY':
    'Vara med särskilda leveranskrav. Vid hemleverans kommer du behöva visa upp en giltig legitimation.',

  // PRESCRIPTION_ORDER_CARD_ALERT
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.IS_SML_AND_SUBSTITUTION_ALLOWED':
    'När läkemedel förskrivits kostnadsfritt enligt smittskyddslagen och det finns utbytbara alternativ inom förmånen ska utbyte ske till rekommenderad vara. Vid invändningar kontakta din förskrivare.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.NOT_ENOUGH_REMAINING_AMOUNT':
    'För liten mängd kvar på receptet. Kan inte hämtas ut online. Kontakta oss eller besök ett lokalt Apotek Hjärtat.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.WITHDRAWAL_WITHOUT_HIGHCOST_PROTECTION':
    'Detta uttag kommer att göras utan förmån. För att kostnaden ska ingå i ditt högkostnadsskydd kan du hämta ut detta recept tidigast {nextWithdrawalAt}.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.WITHDRAWAL_WITHOUT_HIGHCOST_PROTECTION_CHILD':
    'Detta uttag kommer att göras utan förmån. För att beloppet ska vara kostnadsfritt kan du hämta ut detta recept tidigast {nextWithdrawalAt}.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.ADDITIONAL_COSTS_TITLE': 'merkostnad',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.ADDITIONAL_COSTS_DESCRIPTION':
    'Ingår inte i högkostnadsskyddet',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.ADDITIONAL_COSTS_DESCRIPTION_CHILD':
    'Ingår inte i läkemedelsförmånen',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.ADDITIONAL_COSTS_TITLE_CHEAPER':
    'billigare än på ditt recept',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.IS_LICENSED':
    'Licensvaror kan inte köpas online. Kontakta oss eller besök ett lokalt Apotek Hjärtat så hjälper vi dig.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.IS_EXTEMPORE':
    'Extemporeläkemedel kan inte köpas online. Kontakta oss eller besök ett lokalt Apotek Hjärtat så hjälper vi dig.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.STARTER_PACKAGE_TITLE': 'Startförpackning',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.STARTER_PACKAGE_DESCRIPTION':
    'Recept med startförpackning ger dig möjlighet att prova på ett nytt läkemedel. Startförpackningen kan endast hämtas ut på ditt lokala Apotek Hjärtat innan du gjort ditt första ordinarie uttag.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.IS_IN_DOSE_PACKAGING':
    'Detta läkemedel dosförpackas och kan inte köpas online.',
  'MY_PRESCRIPTIONS.PRESCRIPTION_ORDER_CARD_ALERT.IS_MELATONIN_SUBSTITUTION_NOT_ALLOWED':
    'Förskriven vara ingår endast i högkostnadsskyddet för individer i åldersgruppen 6-17 år',

  // MISSING_CONSENT
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.MISSING_ANIMAL_CONSENT.FALLBACK_MESSAGE':
    'För att se dina djurrecept online behöver du först samtycka till receptregister för djur',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.MISSING_ANIMAL_CONSENT': 'Samtycke Saknas',
  'MY_PRESCRIPTIONS.CONSENT_PANEL.GIVE_CONSENT_TO_POA_FOR':
    'Samtycker du till att ta emot fullmakten?',
  'MY_PRESCRIPTIONS.PRESCRIPTION_LIST.MISSING_POWEROFATTORNEY_CONSENT.FALLBACK_MESSAGE':
    'För att kunna hämta och ladda ner aktuella recept behöver du först samtycka till fullmakten',
  'MY_PRESCRIPTIONS.POA_PRESCRIPTION_LIST.MISSING_CONSENT': 'Du har fått en fullmakt',

  // GENERAL ERROR
  'MY_PRESCRIPTIONS.GENERAL_ERROR.GENERAL_ERROR_MESSAGE': 'Ett oväntat fel inträffade.',
  'MY_PRESCRIPTIONS.GENERAL_ERROR.POWER_OF_ATTORNEYS_ERROR_MESSAGE':
    'Just nu kan du inte se eller lägga till barn och fullmakter. Försök igen senare.',
  'MY_PRESCRIPTIONS.GENERAL_ERROR.MY_PRESCRIPTIONS_ERROR_MESSAGE':
    'Just nu kan inte läkemedelslistor visas. Försök igen senare.',
  'MY_PRESCRIPTIONS.GENERAL_ERROR.PRESCRIPTION_LIST.HEADER_ERROR_MESSAGE': 'Hoppsan!',
  'MY_PRESCRIPTIONS.GENERAL_ERROR.PRESCRIPTION_LIST.CANT_FETCH_PRESCRIPTIONS_ERROR_MESSAGE':
    'Receptinformationen kunde inte hämtas.',

  // SORT PRESCRIPTIONS
  'MY_PRESCRIPTIONS.SORT.PRESCRIBED_AT_ASC': 'Förskrivningsdatum - Äldst',
  'MY_PRESCRIPTIONS.SORT.PRESCRIBED_AT_DESC': 'Förskrivningsdatum - Nyast',
  'MY_PRESCRIPTIONS.SORT.HEADER_ASC': 'Bokstavsordning - Stigande',
  'MY_PRESCRIPTIONS.SORT.HEADER_DESC': 'Bokstavsordning - Fallande',
  'MY_PRESCRIPTIONS.SORT.VALIDITY_DATE_ASC': 'Utgångsdatum - Kortast tid kvar',
  'MY_PRESCRIPTIONS.SORT.VALIDITY_DATE_DESC': 'Utgångsdatum - Längst tid kvar',

  // CONSENT MODAL
  'MY_PRESCRIPTIONS.CONSENT_MODAL.TITLE': 'Neka fullmakt',
  'MY_PRESCRIPTIONS.CONSENT_MODAL.TEXT':
    'Fullmakten från {consentName} kommer att avslutas och du kommer inte kunna se hens recept.',
  'MY_PRESCRIPTIONS.CONSENT_MODAL.QUESTION': 'Vill du ändå neka fullmakten?',
  'MY_PRESCRIPTIONS.CONSENT_MODAL.DENY_AND_FINISH': 'Neka och avsluta fullmakten',

  // COMMON FOR PRESCRIPTIONS
  'MY_PRESCRIPTIONS.GENERAL.HANDLE_CONSENT': 'Hantera samtycke',
  'MY_PRESCRIPTIONS.GENERAL.CANCEL': 'Avbryt',
  'MY_PRESCRIPTIONS.GENERAL.APPROVE': 'Jag samtycker',
  'MY_PRESCRIPTIONS.GENERAL.DO_NOT_APPROVE': 'Jag nekar',
  'MY_PRESCRIPTIONS.GENERAL.TRY_AGAIN': 'Försök igen',
  'MY_PRESCRIPTIONS.GENERAL.END': 'Avsluta',
  'MY_PRESCRIPTIONS.GENERAL.ADD': 'Lägg till',
  'MY_PRESCRIPTIONS.GENERAL.RENEW': 'Förnya fullmakt',
  'MY_PRESCRIPTIONS.GENERAL.PRESCRIPTOIN_DATE': 'Förskrivningsdatum',
  'MY_PRESCRIPTIONS.GENERAL.PHONE_NR_WORKPLACE': 'Arbetsplatsens telefonnummer',
  'MY_PRESCRIPTIONS.GENERAL.PHONE_NR_PRESCRIBER': 'Förskrivarens telefonnummer',
  'MY_PRESCRIPTIONS.GENERAL.PHONE_NR_OTHERS': 'Övriga telefonnummer',
  'MY_PRESCRIPTIONS.GENERAL.ACTIVE_SUBSTANCE': 'Verksamt ämne',
  'MY_PRESCRIPTIONS.GENERAL.VALID_TO': 'Giltigt till',
  'MY_PRESCRIPTIONS.GENERAL.LATEST_WITHDRAWAL_TEXT': 'Senaste uttag',
  'MY_PRESCRIPTIONS.GENERAL.ONE_WITHDRAW_LEFT': 'Ett uttag kvar',
  'MY_PRESCRIPTIONS.GENERAL.NEXT_WITHDRAWAL_AT': 'Nästa uttag inom förmån',
  'MY_PRESCRIPTIONS.GENERAL.YES': 'Ja',
  'MY_PRESCRIPTIONS.GENERAL.NO': 'Nej',
  'MY_PRESCRIPTIONS.GENERAL.ITEM_NO': 'Varunummer',
  'MY_PRESCRIPTIONS.GENERAL.HELLO': 'Hej {name}',
  'MY_PRESCRIPTIONS.GENERAL.PRESCRIPTION_DETAILS': 'Visa receptdetaljer',
  'MY_PRESCRIPTIONS.GENERAL.READ_BIPACKSEDEL': 'Läs bipacksedel',

  // DOCUMENTS PAGE - PANEL FOR DOWNLOADING DOCUMENTS
  'MY_PRESCRIPTIONS.PANEL.DOCUMENTS_COMMON_TITLE':
    'Observera att PDF med personlig information kan sparas på denna enhet',
  'MY_PRESCRIPTIONS.PANEL.DOCUMENTS_COMMON_TEXT':
    'Du är på väg att hämta dokument som innehåller personlig information. För att vara säker på att informationen inte sparas på denna enhet efter att du loggat ut rekommenderar vi att du aktivt rensar webbläsarens temporära filer efter utloggning. Var extra uppmärksam på detta om du använder en lånad eller offentlig dator, läsplatta eller telefon. Om du inte vill hämta denna PDF och riskera att den sparas på din enhet tryck på krysset. Om du har förstått riskerna tryck på Hämta PDF.',
};

const orderDetails = {
  'ORDER_DETAILS.TITLE': 'Orderdetaljer',
  'ORDER_DETAILS.ORDERNUMBER': 'Ordernummer:',
  'ORDER_DETAILS.CREATED': 'Skapad:',
  'ORDER_DETAILS.UPDATED': 'Senast uppdaterad:',
  'ORDER_DETAILS.HISTORY': 'Orderhändelser',
  'ORDER_DETAILS.DELIVERY': 'Leverans',
  'ORDER_DETAILS.ESTIMATED-DELIVERY': 'Beräknad leverans',
  'ORDER_DETAILS.SHIPPING': 'Fraktval:',
  'ORDER_DETAILS.ORDER': 'Din order',
  'ORDER_DETAILS.DELIVERYADDRESS': 'Leveransadress:',
  'ORDER_DETAILS.TRACKINGLINK': 'Spårningslänk:',
  'ORDER_DETAILS.TRACKINGTEXT': 'Klicka här',
  // order status - delighted
  'ORDER_DETAILS.DELIGHTED.HEADLINE': 'Vad tycker du om vår orderstatussida?',
  'ORDER_DETAILS.DELIGHTED.HREF': 'https://delighted.com/t/rwbTBXUP',
  'ORDER_DETAILS.DELIGHTED.INFO':
    'Vi vill alltid ge dig så bra service som möjligt, och hoppas därför att du vill svara på några snabba frågor.',
};

const orderHistory = {
  'ORDER_HISTORY.TABLE_CONTAINER.NO_HISTORY': 'Det finns ingen orderhistorik att visa.',
  'ORDER_HISTORY.TABLE_HEAD.CREATED': 'Skapad',
  'ORDER_HISTORY.TABLE_HEAD.ORDERNUMBER': 'Ordernummer',
  'ORDER_HISTORY.TABLE_HEAD.PRICE': 'Pris',
  'ORDER_HISTORY.TABLE_HEAD.SHIPPING_OPTION': 'Leveranssätt',
  'ORDER_HISTORY.TABLE_HEAD.STATUS': 'Orderstatus',
  'ORDER_HISTORY.CARD_LIST.SORT': 'Sortering av orderhistorik',
  'ORDER_HISTORY.CARD_SORT.CREATED_DESC': 'Skapad - Nyast',
  'ORDER_HISTORY.CARD_SORT.CREATED_ASC': 'Skapad - Äldst',
  'ORDER_HISTORY.CARD_SORT.TOTAL_DESC': 'Pris - Fallande',
  'ORDER_HISTORY.CARD_SORT.TOTAL_ASC': 'Pris - Stigande',
  'ORDER_HISTORY.GENERAL_ERROR':
    'Just nu kan du inte se dina historiska ordrar. Försök igen senare.',
};

const orderHistoryStatus = {
  'ORDER_HISTORY.STATUS_NOSTATUS': 'Ingen status',
  'ORDER_HISTORY.STATUS_CREATED': 'Order skapad',
  'ORDER_HISTORY.STATUS_INPROGRESS': 'Påbörjad',
  'ORDER_HISTORY.STATUS_PACKED': 'Skickad',
  'ORDER_HISTORY.STATUS_COMPLETED': 'Avslutad',
  'ORDER_HISTORY.STATUS_CANCELED': 'Avbruten',
  'ORDER_HISTORY.STATUS_AWAITINGDESTRUCTION': 'Hämta nu',
  'ORDER_HISTORY.STATUS_CREDITED': 'Återköp',
  'ORDER_HISTORY.STATUS_DELIVERED': 'Levererad',
  'ORDER_HISTORY.STATUS_RECEIVEDATPICKUPPOINT': 'Redo att hämtas',
  'ORDER_HISTORY.STATUS_UNDEFINED': 'Inget valt fraktsätt',
};

const orderNotes = {
  'ORDERSTATUS.CREATED': 'Din order är skapad',
  'ORDERSTATUS.PABORJAD': 'Din order plockas',
  'ORDERSTATUS.SKICKAD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.PNL-HEMP': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.PNL-BREV': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.PNL-VBREV': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.PNL-MYPACK': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.BUDBEE-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.ISB-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.BST-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.BST-HUB': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.INS-CCAHJ': 'Din order är redo att hämtas',
  'ORDERSTATUS.SKICKAD.INS-AHJ': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.AIR-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.INS-EXPR': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.ICA-PIN': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.BRN-PHD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.ERL-BRD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.ICAPAKET-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.FOODORA-STD': 'Vi inväntar transportören',
  'ORDERSTATUS.SKICKAD.GORDON-HOME': 'Vi inväntar transportören',
  'ORDERSTATUS.IN_TRANSIT': 'Din order är skickad',
  'ORDERSTATUS.DELIVERY_IN_PROGRESS': 'Din order är påväg hem till dig',
  'ORDERSTATUS.ARRIVED_AT_PICKUP_POINT': 'Din order är redo för upphämtning',
  'ORDERSTATUS.DELIVERED': 'Din order har levererats',
  'ORDERSTATUS.DELAYED': 'Din order har tyvärr försenats',
  'ORDERSTATUS.NOTIFICATION_SENT': 'Notis skickad',
  'ORDERSTATUS.ARRIVED_AT_DISTRIBUTION_TERMINAL': 'Din order har anlänt till terminal',
  'ORDERSTATUS.ON_HOLD': 'Din order har pausats',
  'ORDERSTATUS.RECEIVED_AT_PICKUP_POINT': 'Din order är redo för upphämtning',
  'ORDERSTATUS.DEPARTED_FROM_PICKUP_POINT': 'Din order har lämnat upphämtningsbox',
};
const orderStatus = {
  'ORDERSTATUS.COMPLETED': 'Avslutad',
  'ORDERSTATUS.AWAITINGEXCHANGE': 'Pågående',
};

const productVariantPrice = {
  'PRODUCT_VARIANT_PRICE.CAMPAIGN': 'Kampanjpris',
  'PRODUCT_VARIANT_PRICE.CLUB': 'Pris',
  'PRODUCT_VARIANT_PRICE.WEBPRICE': 'Webbpris',
  'PRODUCT_VARIANT_PRICE.RETAILPRICE': 'Butikspris',
};

const articleMaxPurchase = {
  'MAXPURCHASE.TEXT.PARACETAMOL':
    'Max 1 förpackning för kunder under 18 år. Max 3 förpackningar för kunder över 18 år.',
  'MAXPURCHASE.TEXT': 'Max {maxQuantity} köp per kund',
  'MAXPURCHASE.TEXT.RX':
    'Max {maxQuantity, plural, =0 {# förpackning} one {# förpackning} other {# förpackningar}} per uttag',
  'MAXPURCHASE.REASON.ManuallyLimited':
    'Denna vara kan endast köpas i begränsat antal. Det beror på att många behöver samma vara just nu, eller att du har nått e-handelsgränsen för receptfritt läkemedel avsett för tillfälligt bruk.',
  'MAXPURCHASE.REASON.Loperamid':
    'För att minska riskerna med överdosering och felanvändning har branschen kommit överens om att begränsa antal köp av varor som innehåller det verksamma ämnet loperamid till max 2 st köp per kund.',
  'MAXPURCHASE.REASON.OTCProduct':
    'Eftersom receptfria läkemedel är avsedda för tillfälligt bruk är mängden du kan köpa begränsad till max 5 st.',
  'MAXPURCHASE.REASON.ParacetamolTablet':
    'För att minska riskerna med överdosering och felanvändning begränsas antal köp av tabletter som innehåller paracetamol till max 3 st per kund.',
};

// PRODUCT DETAILS
const productLabels = {
  'PRODUCT_LABELS.NEW': 'Ny',
  'PRODUCT_LABELS.VALVALD.TEXT':
    'Detta läkemedel uppfyller apotekens krav på ansvarsfull läkemedelstillverkning.',
  'PRODUCT_LABELS.VALVALD.LINK': 'Läs mer',
};

const productVariantDetails = {
  'COMMON.INFO.SPA.TEXT.PRODUCT':
    'Vi moderniserar vår webbplats en sida i taget och därför kan saker se lite annorlunda ut framöver. Tillbaka till <a href="/">Startsidan</a>.',
  'PRODUCT_VARIANT_DETAILS.DESCRIPTION.TITLE': 'Produktfakta',
  'PRODUCT_VARIANT_DETAILS.ABOUT_PRODUCT.TITLE': 'Om produkten',
  'PRODUCT_VARIANT_DETAILS.DESCRIPTION.DESCRIPTION_HEADER': 'Snabbfakta',
  'PRODUCT_VARIANT_DETAILS.DESCRIPTION.DETAILED_PRODUCT_DESCRIPTION.HEADER':
    'Detaljerad produktbeskrivning',
  'PRODUCT_VARIANT_DETAILS.CONTENT_TABLE.TITLE': 'Ingrediens- & materialförteckning',
  'PRODUCT_VARIANT_DETAILS.INSTRUCTIONS.TITLE': 'Användarinstruktioner',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.TITLE': 'Omdömen',
  'PRODUCT_VARIANT_DETAILS.DELIVERY.TITLE': 'Leveransätt',
  'PRODUCT_VARIANT_DETAILS.ONLINE_ONLY': 'Säljs endast online',
  'PRODUCT_VARIANT_DETAILS.DELIVERY.TEXT':
    '<br><div><b>Fri frakt</b> – Apotek Hjärtat erbjuder alltid leveransalternativ med fri frakt.</div><br> <div><b>Snabbt till brevlådan</b> – om du beställer före kl. 15.00 skickar vi din order samma dag (gäller vardagar om produkterna finns på lager.).</div><br> <div><b>Hemleverans till dörren*</b> – till vissa områden erbjuder vi hemleverans till dörren redan samma dag. Du ser på kassasidan vilka leveransalternativ som finns för just ditt postnummer.</div><br> <div>*Särskilda läkemedel t.ex. narkotika, kylvaror och produkter med åldersgräns kan endast levereras för uthämtning på apotek. Du får information om detta när du gör din beställning.</div><br><br> <div>Läs mer om våra leveransalternativ: <a href="https://www.apotekhjartat.se/kundkontakt/frakt-leverans/">Frakt och leverans</a></div>',
  'PRODUCT_VARIANT_DETAILS.PACK_LEAFLET_DOCUMENT.TITLE': 'Bipacksedel',
  'PRODUCT_VARIANT_DETAILS.PACK_LEAFLET_DOCUMENT.SOURCE': 'Källa:',
  'NORDIC_ITEM_NUMBER.HEADING': 'Varunummer',
  'VARIANT_CODE.HEADING': 'Apotekets varuid',
  'GTIN.HEADING': 'EAN',
  'ACTIVE_SUBSTANCES.HEADING': 'Verksamt ämne',
  'CERTIFICATE.HEADING': 'Märkningar',
  'AGE_GROUP.HEADING': 'Målgrupp',
  'PRODUCT_SERIES.HEADING': 'Produktserie',
  'MARKETING_PROPERTIES.HEADING': 'Egenskap',
  'RESPONSIBLE_CHOICES.HEADING': 'Medvetna val',
  'COLOR.HEADING': 'Färg',
  'SKIN_TYPE.HEADING': 'Hudtyp',
  'CONSISTENCY.HEADING': 'Formula',
  'CATEGORY.HEADING': 'Kategori',
  'BRANDS.HEADING': 'Varumärken',
  'PRODUCT_VARIANT_DETAILS.PICKUPONLY': 'Den här produkten behöver hämtas på apotek.',

  'ECOCERTIFICATE.Valvald': 'Välvald',
  'ECOCERTIFICATE.Ecocert': 'Ecocert',
  'ECOCERTIFICATE.EuEcoLabel': 'EuEcoLabel',
  'ECOCERTIFICATE.KRAV': 'KRAV',
  'ECOCERTIFICATE.Svanen': 'Svanen',
  'ECOCERTIFICATE.NATRUE': 'NATRUE',

  'AGES.HEADING': 'Ålder',
  'AGES.ZeroMonths': '0 mån',
  'AGES.ThreeMonths': '3 mån',
  'AGES.SixMonths': '6 mån',
  'AGES.EightMonths': '8 mån',
  'AGES.OneYear': '1 år',
  'AGES.TwoYears': '2 år',
  'AGES.ThreeYears': '3 år',
  'AGES.FourYears': '4 år',
  'AGES.FiveYears': '5 år',
  'AGES.SixYears': '6 år',
  'AGES.SevenYears': '7 år',
  'AGES.EightYears': '8 år',
  'AGES.NineYears': '9 år',
  'AGES.TenYears': '10 år',
  'AGES.ElevenYears': '11 år',
  'AGES.TwelveYears': '12 år',

  'EFFECTDURATION.HEADING': 'Verkningstid',
  'EFFECTDURATION.LongActing': 'Långtidsverkande',
  'EFFECTDURATION.ShortTerm': 'Korttidsverkande',
  'EFFECTDURATION.TwelveHours': '12 tim',
  'EFFECTDURATION.TwentyFourHours': '24 tim',
  'EFFECTDURATION.FourtyEightHours': '48 tim',
  'EFFECTDURATION.SeventyTwoHours': '72 tim',

  'VERMINTYPE.HEADING': 'Ohyratyp',
  'VERMINTYPE.Tapeworm': 'Bandmask',
  'VERMINTYPE.Roundworm': 'Spolmask',
  'VERMINTYPE.Fleas': 'Loppor',
  'VERMINTYPE.Lice': 'Löss',
  'VERMINTYPE.Ticks': 'Fästingar',
  'VERMINTYPE.Mosquitoes': 'Myggor',
  'VERMINTYPE.Scabies': 'Rävskabb',
  'VERMINTYPE.EarMites': 'Öronskabb',
  'VERMINTYPE.WalkingDandruff': 'Mjällkvalster',

  'TARGETGROUP.HEADING': 'Målgrupp',
  'TARGETGROUP.Infant': 'Spädbarn',
  'TARGETGROUP.Diabetic': 'Diabetiker',
  'TARGETGROUP.Man': 'Man',
  'TARGETGROUP.Pregnant': 'Gravid',
  'TARGETGROUP.BreastfeedingMom': 'Ammande mamma',
  'TARGETGROUP.Youth': 'Ungdom',
  'TARGETGROUP.Adult': 'Vuxen',
  'TARGETGROUP.Senior': 'Senior',
  'TARGETGROUP.Children': 'Barn',
  'TARGETGROUP.Woman': 'Kvinna',
  'TARGETGROUP.Cat': 'Katt',
  'TARGETGROUP.Horse': 'Häst',
  'TARGETGROUP.Dog': 'Hund',

  'DOSAGE_FORM.HEADING': 'Beredningsform',
  'DOSAGE_FORM.Tablet': 'Tablett',
  'DOSAGE_FORM.Drops': 'Droppar',
  'DOSAGE_FORM.BandAid': 'Plåster',
  'DOSAGE_FORM.Capsule': 'Kapsel',
  'DOSAGE_FORM.ChewableTablet': 'Tuggtablett',
  'DOSAGE_FORM.Cream': 'Kräm',
  'DOSAGE_FORM.EffervescentTablet': 'Brustablett',
  'DOSAGE_FORM.Gel': 'Gel',
  'DOSAGE_FORM.Inhaler': 'Inhalator',
  'DOSAGE_FORM.Liquid': 'Vätska',
  'DOSAGE_FORM.Lozenge': 'Pastill',
  'DOSAGE_FORM.Suppository': 'Suppositorium',
  'DOSAGE_FORM.PortionSachet': 'Portionspåse',
  'DOSAGE_FORM.ChewingGum': 'Tuggummi',
  'DOSAGE_FORM.Oil': 'Olja',
  'DOSAGE_FORM.Powder': 'Pulver',
  'DOSAGE_FORM.Ointment': 'Salva',
  'DOSAGE_FORM.Spray': 'Spray',

  'CONSISTENCY.CompactPowder': 'Kompaktpuder',
  'CONSISTENCY.LoosePowder': 'Löstpuder',
  'CONSISTENCY.Mousse': 'Mousse',
  'CONSISTENCY.Cream': 'Kräm',
  'CONSISTENCY.Gel': 'Gel',
  'CONSISTENCY.Lotion': 'Lotion',
  'CONSISTENCY.Liquid': 'Flytande',
  'CONSISTENCY.Oil': 'Olja',

  // Warning symbols
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TITLE': 'Märkning',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.Corrosive': 'Frätande',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.Flammable': 'Brandfarligt',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.EnvironmentallyHazardous': 'Skadligt för miljön',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.HealthHazard': 'Skadligt',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.Explosive': 'Explosivt',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.Oxidising': 'Oxiderande',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.GasUnderPressure': 'Gasbehållare',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.SeriousHealthHazard': 'Hälsofarlig',
  'PRODUCT_VARIANT_DETAILS.WARNING_SYMBOLS.TEXT.AcuteToxicity': 'Giftigt',
};

const productVariantComparisonPrice = {
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.TEXT': 'Jämförpris: ',
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.UNIT.Piece': '/st',
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.UNIT.Kilogram': '/kg',
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.UNIT.Litre': '/l',
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.UNIT.Dose': '/dos',
  'PRODUCT_VARIANT_DETAILS.COMPARISON_PRICE.UNIT.Pair': '/par',
};

const productVariantRetailPrice = {
  'PRODUCT_VARIANT_DETAILS.RETAIL.PRICE': 'Butikspris:',
};

const productVariantSelector = {
  'PRODUCT_VARIANT_SELECTOR.SHOW_MORE': 'visa fler',
  'PRODUCT_VARIANT_SELECTOR.SHOW_LESS': 'visa mindre',
};

const ArticlePrice = {
  'ARTICLE.CAMPAIGN': 'Kampanjpris',
  'ARTICLE.CLUB': 'Klubbpris',
  'ARTICLE.WEB': 'Webbpris',
  'ARTICLE.RETAIL': 'Butikspris {price}',
  'ARTICLE.COMPARISON': 'Jämförpris: ',
  'ARTICLE.COMPARISON.UNIT.Piece': '/st',
  'ARTICLE.COMPARISON.UNIT.Kilogram': '/kg',
  'ARTICLE.COMPARISON.UNIT.Litre': '/l',
  'ARTICLE.COMPARISON.UNIT.Dose': '/dos',
  'ARTICLE.COMPARISON.UNIT.Pair': '/par',
  'ARTICLE.PRICE': 'Pris: ',
  'ARTICLE.SALES_PRICE_LAW_INFO':
    'Överstruket pris avser det lägsta priset produkten haft de senaste 30 dagarna.',
  'ARTICLE.LOWEST_PRICE_LAST_30_DAYS': 'Lägsta priset senaste 30 dagarna är {price}',
};

const productVariantReviews = {
  'PRODUCT_VARIANT_DETAILS.REVIEWS.TOTAL_REVIEWS': '({reviewCount} kundomdömen)',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.BASED_ON': 'Baserat på {reviewCount} betyg',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.REPORT_PLACEHOLDER':
    'Vad i recensionen är felaktigt eller stötande?',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.FEEDBACK_THANKS': 'Tack för din feedback!',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.CANCEL': 'Skicka',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.SEND': 'Avbryt',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.SHOW_MORE_REVIEWS': 'Visa fler recensioner',
  'PRODUCT_VARIANT_DETAILS.REVIEWS.DISCLAIMER':
    'Samtliga omdömen visas oavsett betyg. Endast kunder som har köpt varor via apotekhjartat.se får möjlighet att skriva omdömen om produkterna. Hanteringen av omdömena hanteras av företaget Teastfreaks.',
};

const productVariantQuantityFormatter = {
  'PRODUCT_VARIANT_DETAILS.QUANTITY_FORMATTER.IN_STOCK': 'Finns i lager',
  'PRODUCT_VARIANT_DETAILS.QUANTITY_FORMATTER.FEW_IN_STOCK': 'Fåtal i lager',
  'PRODUCT_VARIANT_DETAILS.QUANTITY_FORMATTER.SOON_IN_STOCK': 'Snart i lager',
};

const productQuantityUnit = {
  'PRODUCT.QUANTITY.UNIT': ' {quantity}st',
};

const productDetailsStoreStockStatus = {
  'PRODUCT_DETAILS.STORE.OUT_OF_STOCK': 'Slut i lager hos {storeName}',
  'PRODUCT_DETAILS.STORE.IN_STOCK_FEW': 'Fåtal i lager hos {storeName}',
  'PRODUCT_DETAILS.STORE.IN_STOCK': 'Finns i lager hos {storeName}',
  'PRODUCT_DETAILS.STORE_STOCK': 'st finns i lager hos {storeName}',
};

const productDetailsArticleType = {
  'PRODUCT_DETAILS.TYPE.DRUG': 'Läkemedel',
  'PRODUCT_DETAILS.TYPE.SUPPLEMENT': 'Kosttillskott',
};
const productDetailsError = {
  'PRODUCT_DETAILS.ERROR.TITLE': 'Något gick fel',
  'PRODUCT_DETAILS.ERROR.BACK_TO_START': 'Ta mig till startsidan',
  'PRODUCT_DETAILS.ERROR.ERROR_MESSAGE':
    'Vi kunde inte hitta den här produkten. Gå tillbaka till startsidan genom att klicka på knappen.',
};

const productVariantShowExcludedFromPromotionInformation = {
  'PRODUCT_VARIANT_DETAILS.SHOW_EXCLUDED_FROM_PROMO':
    'Denna artikel ingår ej i kampanjer eller rabatterbjudanden.',
};

const productDetailsButtons = {
  'ADD_ARTICLE_TO_CART.BUTTON.BUYABLE': 'Köp',
  'ADD_ARTICLE_TO_CART.BUTTON.NOT.BUYABLE': 'Snart tillbaka',
  'ADD_ARTICLE_TO_CART.BUTTON.BUY_VIA_PRESCRIPTION': 'Köp via recept',
  'ADD_ARTICLE_TO_CART.BUTTON.HAS_VARIANTS': 'Se varianter',
  'ADD_ARTICLE_TO_CART.DIKLOFENAK.TITLE': 'Använd med eftertanke',
  'ADD_ARTICLE_TO_CART.DIKLOFENAK.TEXT':
    'Diklofenak påverkar miljön negativt, använd med eftertanke. Efter att ha applicerat gelen, torka av överbliven gel från händerna med till exempel ett hushållspapper eller en våtservett. Kasta sedan i papperskorgen och tvätta händerna.\n\nLäkemedelsbehållaren ska sorteras som läkemedelsavfall, oavsett om behållaren är slut eller inte.',
  'ADD_ARTICLE_TO_CART.DIKLOFENAK.CONFIRM': 'Jag har förstått',
  'ADD_ARTICLE_TO_CART.DIKLOFENAK.CANCEL': 'Avbryt',
};

const findInStore = {
  'FIND_IN_STORE.HEADER_TITLE': 'Finns varan i mitt apotek',
  'FIND_IN_STORE.HEADER_SUBTITLE':
    'Sök eller aktivera platstjänster för att hitta ditt närmaste apotek',
  'FIND_IN_STORE.LIST_HEADERS.PHARMACIES_NEAR_YOU': 'Apotek nära dig',
  'FIND_IN_STORE.LIST_HEADERS.SELECTED_PHARMACY': 'Valt Apotek',
  'FIND_IN_STORE.BUTTON.CHOOSE': 'Välj',
  'FIND_IN_STORE.BUTTON.FIND_IN_STORE': 'Finns varan i mitt apotek?',
  'FIND_IN_STORE.SEARCH': 'Sök',
  'FIND_IN_STORE.USE_MY_LOCATION': 'Använd min plats',
  'FIND_IN_STORE.ERROR.TITLE': 'Något gick fel',
  'FIND_IN_STORE.ERROR.ERROR_MESSAGE':
    'Försök med en annan stavning eller besök vår sida med alla apotek.',
};

const productVariantInformationTextIcon = {
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.PACK_LEAFLET':
    'Läs alltid bipacksedeln innan användning.',
  // Product variant info short description
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.Nikotin':
    'Receptfritt läkemedel med nikotin. Från 18 år, och får inte säljas om avsikten är langning.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.Drug': 'Receptfritt läkemedel.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.PrescriptionDrug':
    'Det här är ett receptbelagt läkemedel. Kan endast köpas om du har e-recept.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.Supplement':
    'Kosttillskott. Rekommenderad dos bör inte överskridas. Bör inte användas som ett alternativ till en varierad och balanserad kost och en hälsosam livsstil. Förvaras torrt i rumstemperatur, ej i direkt solljus och utom räckhåll för små barn.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.Foodstuff':
    'Livsmedel. Mat och dryck utan medicinsk effekt. Vissa kan ge särskild näring eller vara en del av en behandling.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DrugAid':
    'Förbrukningsartikel som ingår i förmån. Denna artikel ingår ej i kampanjer eller rabatterbjudanden.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.MedicalEquipment':
    'Förbrukningsartikel som ingår i förmån. Denna artikel ingår ej i kampanjer eller rabatterbjudanden.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.HerbalMedicine': 'Växtbaserat läkemedel.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TraditionalHerbalMedicine':
    'Traditionellt växtbaserat läkemedel.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.ExternalMedicine': 'Vissa utvärtes läkemedel.',
  // Product variant info title
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.Nikotin': 'Nikotinläkemedel',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.Drug': 'Receptfritt läkemedel',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.PrescriptionDrug': 'Receptbelagt läkemedel',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.Supplement': 'Kosttillskott',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.Foodstuff': 'Information',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.DrugAid': 'Förbrukningsartiklar',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.MedicalEquipment': 'Förbrukningsartiklar',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.HerbalMedicine': 'Växtbaserat läkemedel',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.TraditionalHerbalMedicine':
    'Traditionellt Växtbaserat läkemedel',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.TITLE.ExternalMedicine':
    'Vissa utvärtes läkemedel (VUM)',

  // Product variant info long description
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.COLD_STORAGE':
    'Den här produkten är en kylvara vilket påverkar leveranssätten.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.Nikotin':
    'Nikotinläkemedel är ett receptfritt läkemedel. Får inte säljas om avsikten är langning. Åldersbegränsad till 18 år och kräver ID-kontroll vid utlämning.<p>Läs alltid bipacksedeln innan användning. Observera att läkaren kan ha ordinerat läkemedlet för annat användningsområde och/eller med annan dosering än vad som står i bipacksedeln. Följ alltid läkarens ordination.</p><p>Har du frågor eller funderingar om hur du ska ta ditt läkemedel är du alltid välkommen att kontakta Apotek Hjärtats Kundkontakt för rådgivning.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.Drug':
    'Läs alltid bipacksedeln innan användning. Observera att läkaren kan ha ordinerat läkemedlet för annat användningsområde och/eller med annan dosering än vad som står i bipacksedeln. Följ alltid läkarens ordination.<p>Har du frågor eller funderingar om hur du ska ta ditt läkemedel är du alltid välkommen att kontakta Apotek Hjärtats Kundkontakt för rådgivning.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.PrescriptionDrug':
    'Det här är ett receptbelagt läkemedel. Läs alltid bipacksedeln före användning.<p>Följ alltid den ordination du fått från förskrivaren. Kontakta din läkare/förskrivare eller vår Kundkontakt för rådgivning om du känner dig osäker.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.Supplement':
    'Rekommenderad dos bör inte överskridas. Bör inte användas som ett alternativ till en varierad och balanserad kost och en hälsosam livsstil. Förvaras torrt i rumstemperatur, ej i direkt solljus och utom räckhåll för små barn.<p>Vitaminer och mineraler i koncentrerad form kallas kosttillskott. Kosttillskott är tänkta att komplettera den vanliga kosten och säljs i form av kapslar, tabletter, pulver, ampuller med vätska, droppflaskor eller liknande. Även aminosyror, essentiella fettsyror, fibrer, olika örter och växtextrakt i koncentrerad form, och som är avsedda att tas i små mängder, kan säljas som kosttillskott.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.Foodstuff':
    'Livsmedel kan vara riktade till särskilda grupper, som livsmedel för speciella medicinska ändamål eller komplett kostersättning för viktkontroll. Vissa av dessa livsmedel kan du få på recept. En del livsmedel kan ha närings- eller hälsofördelar för friska personer. De hälsopåståenden du ser är i så fall godkända av EU-kommissionen och de följs alltid av en näringsdeklaration på förpackningen.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.DrugAid':
    'Förbrukningsartiklar är en grupp medicintekniska produkter som kan subventioneras och ingå i högkostnadsskyddet. Det finns tre grupper av förbrukningsartiklar i förmånssystemet: Produkter för att tillföra kroppen läkemedel (exempelvis kanyler), produkter för egenkontroll av medicinering (exempelvis teststickor) och produkter för stomi (exempelvis stomipåsar). En förbrukningsartikel är en produkt som förskrivs till en enskild patient och blir dennes egendom. Det är förhållandevis enkla, mindre kostsamma produkter som har en begränsad livslängd.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.MedicalEquipment':
    'Förbrukningsartiklar är en grupp medicintekniska produkter som kan subventioneras och ingå i högkostnadsskyddet. Det finns tre grupper av förbrukningsartiklar i förmånssystemet: Produkter för att tillföra kroppen läkemedel (exempelvis kanyler), produkter för egenkontroll av medicinering (exempelvis teststickor) och produkter för stomi (exempelvis stomipåsar). En förbrukningsartikel är en produkt som förskrivs till en enskild patient och blir dennes egendom. Det är förhållandevis enkla, mindre kostsamma produkter som har en begränsad livslängd.',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.HerbalMedicine':
    'Läs alltid bipacksedeln innan användning.<p>Växtbaserade läkemedel innehåller växtbaserat material eller växtbaserade beredningar som verksamma beståndsdelar. De följer samma lagstiftning som konventionella läkemedel och Läkemedelsverket bedömer kvalitet, säkerhet och effekt av läkemedlet innan produkten godkänns för försäljning.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.TraditionalHerbalMedicine':
    'Läs alltid bipacksedeln innan användning.<p>Indikationerna för ett traditionellt växtbaserat läkemedel grundar sig uteslutande på erfarenhet av långvarig användning. Traditionella växtbaserade läkemedel innehåller växtbaserat material eller växtbaserade beredningar som verksamma beståndsdelar. Läkemedelsverket bedömer kvalitet, säkerhet och traditionell medicinsk användning av läkemedlet innan produkten godkänns för försäljning.</p>',
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.DESCRIPTION.ExternalMedicine':
    'Läs alltid bipacksedeln innan användning.<p>Vissa utvärtes läkemedel (VUM) är väl beprövade produkter för utvärtes bruk som är avsedda för behandling av enklare sjukdomstillstånd hos människor eller djur.</p>',

  // Cancel button
  'PRODUCT_VARIANT_DETAILS.INFORMATION_TEXT_ICON.CANCEL_BUTTON.TEXT': 'Avbryt',
};

const consent = {
  // Information texts about consent
  'CONSENT.INGRESS':
    'Som medlem i Klubb Hjärtat får du bonus och personliga erbjudanden baserat på dina köp av produkter eller tjänster. För att få bonus och personliga erbjudande baserat på köp som är integritetskänsliga behöver du lämna samtycken.',
  'CONSENT.WITH_RETAIL_MEDIA_INGRESS':
    'Som medlem i Klubb Hjärtat får du bonus och personliga erbjudanden baserat på dina köp av produkter eller tjänster. För att få bonus och personliga erbjudande baserat på köp som är integritetskänsliga behöver du lämna samtycken. [Lägg till text för retail media]..',
  'CONSENT.ALERT.INFO':
    'OBS! Inom klubb Hjärtat hanterar vi aldrig personuppgifter om receptbelagda läkemedel',
  'CONSENT.MAIN.HEADING': 'Samtycken för bonus och personliga erbjudanden',
  'CONSENT.PARAGRAF2':
    'Att samtycka är frivilligt och du kan när som helst ändra dina val (dra tillbaka ditt samtycke) på apotekhjartat.se',
  'CONSENT.PARAGRAF3':
    'OBS! Inom Klubb Hjärtat hanterar vi aldrig personuppgifter om receptbelagda läkemedel.',
  'CONSENT.LINK.TEXT.SELECT': '<b>Läs mer här</b> innan du gör dina val.',
  'CONSENT.LINK.TEXT.CHANGE': '<b>Läs mer här</b> innan du ändrar dina val.',
  'CONSENT.APPLIES.FROM.TEXT':
    'Samtycken kommer att gälla på köp gjorda från och med <b>1 november 2021</b>.',
  // Consent option texts
  'CONSENT.HEADING.Bonus': 'Samtycke till bonus',
  'CONSENT.HEADING.PersonalOffer': 'Samtycke till personliga erbjudanden',
  'CONSENT.HEADING.RetailMedia': 'Samtycke till personligt anpassade annonser',
  'CONSENT.TEXT.Bonus':
    'Samtycker du till att vi hanterar dina personuppgifter om du köper <b>produkter eller tjänster med nära koppling till hälsa (ex hudbesvär), receptfria läkemedel (ex värktabletter) eller produkter kopplade till sex </b>i syfte att du ska få bonus på detta sortiment?',
  'CONSENT.TEXT.PersonalOffer':
    'Samtycker du till att vi hanterar dina personuppgifter om du köper <b>produkter med nära koppling till hälsa (ex hudbesvär) eller receptfria läkemedel (ex rökfritt, allergi) </b>i syfte att få personliga erbjudanden på detta sortiment?',
  'CONSENT.OPTION.BonusYes': 'Ja tack, ',
  'CONSENT.OPTION.BonusNo': 'Nej tack, ',
  'CONSENT.OPTION.PersonalOfferYes': 'Ja tack, ',
  'CONSENT.OPTION.PersonalOfferNo': 'Nej tack, ',
  'CONSENT.OPTION.TEXT.BonusYes': 'jag vill gärna ha bonus även på detta sortiment.',
  'CONSENT.OPTION.TEXT.BonusNo': 'jag vill inte ha bonus på detta sortiment.',
  'CONSENT.OPTION.TEXT.PersonalOfferYes':
    'jag vill gärna ha personliga erbjudanden även på detta sortiment.',
  'CONSENT.OPTION.TEXT.PersonalOfferNo':
    'jag vill inte ha personliga erbjudanden på detta sortiment.',
  'CONSENT.TEXT.RetailMedia':
    'Jag samtycker till att Apotek Hjärtat AB använder personuppgifter såsom min köpinformation, mina demografiska uppgifter samt besök och interaktioner i sina digitala kanaler för att utveckla, samt tillsammans med sina annonseringspartners följa upp och ge mig relevanta och personligt anpassade annonser i Apotek Hjärtats digitala kanaler',
  'CONSENT.OPTION.RetailMediaYes': 'Ja tack, ',
  'CONSENT.OPTION.RetailMediaNo': 'Nej tack, ',
  'CONSENT.OPTION.TEXT.RetailMediaYes': 'jag accepterar.',
  'CONSENT.OPTION.TEXT.RetailMediaNo': 'jag accepterar inte.',
  //
  'CONSENT.BUTTON': 'Spara',
  'CONSENT.BUTTON.SAVED': 'Sparat',
  'CONSENT.BUTTON.FORWARD': 'Gå vidare',
  'CONSENT.BUTTON.LATER': 'Gör ett val nästa gång',
  'CONSENT.BUTTON.TRY.AGAIN': 'Ladda om sidan',
  'CONSENT.ERROR.MESSAGE': 'Du har inte svarat på samtyckena ovan',
  'CONSENT.SAVE.ERROR.MESSAGE': 'Oj, något gick fel. Vi kan inte spara ditt samtycke just nu',
  'CONSENT.IMAGE': 'Mor och dotter som skrattar',
  'CONSENT.LATER.TITLE': 'Är du säker på ditt val?',
  'CONSENT.LATER.TEXT1':
    'Vi vill förbättra din upplevelse så att du får bonus och personliga erbjudanden på hela sortimentet, samt att kunna visa dig personligt anpassade annonser.',
  'CONSENT.LATER.TEXT2': 'Denna sida kommer att visas nästa gång coh tills du gör ett val.',
  'CONSENT.LATER.TEXT.REDIRECT': 'Du navigeras vidare, vänligen vänta...',
  'CONSENT.LATER.YES': 'Fortsätt utan att göra ett val',
  'CONSENT.LATER.NO': 'Avbryt',
  'NO.AVAILABLE.CONSENT.OPTIONS.HEADER': 'Du har redan fyllt i alla våra samtycken',
  'NO.AVAILABLE.CONSENT.OPTIONS.TEXT': 'Vänligen vänta, du navigeras vidare...',
};

const consentInfo = {
  'CONSENT_INFO.HEADER.BONUS': 'Information om personuppgiftsbehandling för bonus',
  'CONSENT_INFO.HEADER_BONUS': 'Särskilt om bonus',
  'CONSENT_INFO.HEADER.PERSONAL_OFFERS':
    'Information om personuppgiftsbehandling för personliga erbjudanden',
  'CONSENT_INFO.HEADER_PERSONAL_OFFERS': 'Särskilt om personliga erbjudanden',
  'CONSENT_INFO.HEADER.RETAIL_MEDIA':
    'Information om personuppgiftsbehandling för personliga erbjudanden',
  'CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF1': 'Hur används dina uppgifter?',
  'CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF2': 'Dessa personuppgifter behandlar vi',
  'CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF3': 'Personuppgiftsansvar',
  'CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF4': 'I de här digitala kanalerna visas annonser',
  'CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF5': 'Vill du veta mer?',
  'CONSENT_INFO.PARAGRAF1':
    'När vi hanterar dina personuppgifter så följer vi tillämplig lagstiftning så som dataskyddsförordningen (GDPR) och apoteksdatalagen. Eftersom ett flertal av de produkter som vi erbjuder utgör produkter med nära koppling till hälsa, receptfria läkemedel eller produkter kopplade till sex och därför kan ses som mer integritetskänsliga, behöver vi enligt dessa regelverk inhämta ett särskilt samtycke från dig. Vi har även integritetskänsliga tjänster.',
  'CONSENT_INFO.PARAGRAF2':
    'Exempel på vad vi benämner som integritetskänsliga produkter är receptfria läkemedel (så som värktabletter eller allergi), produkter med nära koppling till hälsa (så som produkter kopplade till hudbesvär) eller produkter kopplade till kategorin sex. Exempel på produkter som inte ses som integritetskänsliga är exempelvis skönhetsprodukter såsom smink eller ansiktskräm. Läs mer om hur vi hanterar information om denna typ av produkter och tjänster <a href="/om-oss/var-personuppgiftsbehandling/medlem-i-klubb-hjartat/integritetskansligt-sortiment" target="_blank">här</a>. Obs! Inom Klubb Hjärtat hanterar vi aldrig personuppgifter om receptbelagda läkemedel.​',
  'CONSENT_INFO.PARAGRAF3':
    'Som medlem i Klubb Hjärtat får du bonus baserat på dina köp av produkter eller tjänster som inte anses integritetskänsliga. Om du även önskar få bonus på resterande delar av sortimentet, innefattandes integritetskänsliga produkter eller tjänster enligt ovan, behöver du särskilt samtycka till detta. Vi ger aldrig bonus på det receptbelagda sortimentet.​',
  'CONSENT_INFO.PARAGRAF4':
    'Som medlem i Klubb Hjärtat erbjuder vi dig personliga erbjudanden baserat på dina köp av produkter som inte anses integritetskänsliga. Om du även önskar få personliga erbjudanden avseende integritetskänsliga produkter behöver du även särskilt samtycka till detta. Med personliga erbjudanden menas erbjudanden som har tagits fram utifrån din köpinformation och ditt köpbeteende. Läs mer om personliga erbjudanden <a href="/om-oss/var-personuppgiftsbehandling/medlem-i-klubb-hjartat/personliga-erbjudanden-och-profilering" target="_blank">här</a>.',
  'CONSENT_INFO.PARAGRAF5':
    'Att samtycka till vår hantering av personuppgifter för de olika ändamålen är frivilligt och du kan när som helst välja att dra tillbaka ditt samtycke genom att ändra dina val <a href="/klubb-hjartat/mina-sidor/" target="_blank">här</a>.',
  'CONSENT_INFO.PARAGRAF6':
    'Apotek Hjärtat AB, org.nr 556773-8249 är personuppgiftsansvarig för hanteringen av dina personuppgifter om inget annat anges i vår integritetspolicy. Apotek Hjärtat ansvarar i egenskap av personuppgiftsansvarig för att dina personuppgifter behandlas i enlighet med tillämplig lagstiftning och att dina rättigheter iakttas.',
  'CONSENT_INFO.PARAGRAF7':
    'Du kan läsa mer om hur vi hanterar dina personuppgifter <a href="/om-oss/var-personuppgiftsbehandling/medlem-i-klubb-hjartat/" target="_blank">här</a>.',
  'CONSENT_INFO.PARAGRAF8':
    'Vid frågor eller funderingar är du välkommen att kontakta vårt dataskyddsombud.',
  'CONSENT_INFO.BUTTON.BACK': 'Tillbaka',
  'CONSENT_INFO.CONTACT.US.EMAIL': 'E-post',
  'CONSENT_INFO.CONTACT.EMAIL.ADDRESS': 'dataskyddsombud@apotekhjartat.se',
};

const cookieSettings = {
  'COOKIE_SETTINGS.TITLE': 'Cookieinställningar',
  'COOKIE_SETTINGS.TEXT':
    'Du väljer själv om du vill acceptera cookies. Om du inte vill acceptera att din dator tar emot och lagrar cookies så kan du ändra inställningar för cookies genom att klicka på knappen nedan. Du kan också justera säkerhetsinställningarna i din webbläsare.',
  'COOKIE_SETTINGS.BACK_BUTTON.TEXT': 'Tillbaka',
  'COOKIE_SETTINGS.SETTINGS_BUTTON.TEXT': 'Ändra cookieinställningar',
};
const clubMember = {
  'CLUB_MEMBER.MY.INFO.TITLE': 'Mina uppgifter',
  'CLUB_MEMBER.CONSENT.TITLE': 'Mina samtycken',
  'CLUB_MEMBER.CONSENT.GENERAL_APPROVAL':
    'Jag intygar jag att jag har läst <a href="https://www.apotekhjartat.se/om-oss/var-personuppgiftsbehandling/medlem-i-klubb-hjartat/" target="_blank">integritetspolicyn </a>och godkänner Apotek Hjärtats <a href="https://www.apotekhjartat.se/klubb-hjartat/medlemsvillkor/" target="_blank">medlemsvillkor</a>.',
  'CLUB_MEMBER.REGISTER.CONSENT_GENERAL_REQUIRED':
    'Du måste ha läst integritetspolicyn och godkänt medlemsvillkoren för att bli medlem',
  'CLUB_MEMBER.REGISTER.TITLE': 'Registrera',
  'CLUB_MEMBER.REGISTER.CONSENT.TITLE':
    'Information om personuppgiftsbehandling för bonus och personliga erbjudanden',
  'CLUB_MEMBER.REGISTER.CONSENT.TITLE_WITH_RETAIL_MEDIA':
    'Samtycken för bonus, personliga erbjudanden och personligt anpassade annonser',
  'CLUB_MEMBER.REGISTER.CONSENT.ERROR':
    'Tyvärr kan inte samtycken väljas just nu. Ditt medlemskap har skapas och du kan fortsätta handla. Innan du har samtyckt kommer du inte få bonus eller personliga erbjudanden på vissa typer av produkter. Du kan alltid samtycka senare under - Mina uppgifter',
  'CLUB_MEMBER.REGISTER.MEMBER.SAVE': 'Vidare',
  'CLUB_MEMBER.REGISTER.STEP.MEMBER': 'Medlemsinformation',
  'CLUB_MEMBER.REGISTER.STEP.CONSENTS': 'Samtycken',
  'CLUB_MEMBER.MEMBER.SAVE': 'Spara uppgifterna',
  'CLUB_MEMBER.MEMBER.SAVED': 'Sparat',
  'CLUB_MEMBER.CONSENT.SAVE': 'Spara samtycken',
  'CLUB_MEMBER.CONSENT.SAVED': 'Sparat',
  'CLUB_MEMBER.OFFER.TEXT':
    'Jag vill gärna att Klubb Hjärtat skickar mig erbjudanden och annan marknadsföring via',
  'CLUB_MEMBER.OFFER.EMAIL': 'E-post',
  'CLUB_MEMBER.OFFER.SMS': 'SMS',
  'CLUB_MEMBER.OFFER.MAIL': 'Post',
  'CLUB_MEMBER.SELECT.PHARMACY.TITLE': 'Välj närmaste apotek:',
  'CLUB_MEMBER.SELECT.PHARMACY.CHOOSE_PHARMACY_LABEL': 'Välj apotek',
  'CLUB_MEMBER.SELECT.PHARMACY.CHOOSE_CITY_LABEL': 'Välj stad',
  'CLUB_MEMBER.SELECT.PHARMACY.FETCHERROR':
    'Tyvärr kan inte apotek visas eller ändras just nu, försök igen senare.',
  'CLUB_MEMBER.CUSTOMER_PANEL_TEXT':
    'Ja, jag vill gärna vara med i Klubb Hjärtats kundpanel. Vi behandlar dina personuppgifter (kontaktuppgifter) för att kunna skicka ut olika enkäter till dig. Du kan själv, vid varje tillfälle välja, om du vill deltaga i enkäten eller ej.',
  'CLUB_MEMBER.REGISTER.BUTTON.TEXT': 'Logga in och bli medlem',
  'CLUB_MEMBER.BECOME.MEMBER': 'Bli medlem',
  'CLUB_MEMBER.REGISTER.BUTTON.TEXT_ARIA_LABEL': 'Logga in med BankID och bli medlem',
  'CLUB_MEMBER.REGISTER.DISABLED.TITLE': 'Service pågår',
  'CLUB_MEMBER.REGISTER.DISABLED.TEXT':
    'Just nu kan vi tyvärr inte registrera dig som medlem, försök igen senare',
  'CLUB_MEMBER.BONUS.LEVELS.LEGAND.HEADING': 'Så här fungerar det',
  'CLUB_MEMBER.BONUS.LEVELS.CONVERSION': '1 krona = 1 poäng',
  'CLUB_MEMBER.BONUS.LEVELS.ICA_CARD': '20 poäng när du betalar med något av ICAs kort*',
  'CLUB_MEMBER.BONUS.LEVELS.PARTNERS':
    'Du kan också <a href="https://www.ica.se/stammis-pa-ica/bonus-och-formaner/" target="_blank">få mer bonus genom ICA Banken och våra partners</a>',
  'CLUB_MEMBER.BONUS.LEVELS.NOT.AT.ECOME': '* Gäller ej på apotekhjartat.se',
  'CLUB_MEMBER.BONUS.LEVELS.HEADING': 'Bonusnivåer',
  'CLUB_MEMBER.BONUS.LEVELS': 'Nivå',
  'CLUB_MEMBER.BONUS.LEVELS.HIGHEST.LIMIT': 'poäng och över',
  'CLUB_MEMBER.BONUS.LEVELS.BOOST.NOTICE':
    'Om du samlar mellan 1 000 - 1 999 poäng två månader i rad får du 20 kr i bonus. Du kan få 20 kr varannan månad.',
  'CLUB_MEMBER.BONUS.COLLECT.STAMMISHJULET': 'Mitt bonussparande',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.COLLECTED.POINTS': 'Samlade poäng',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.VOUCHER.VALUE': 'Din bonusnivå',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.VOUCHER.VALUE.NO.PARTNERS': 'Din bonus',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.VOUCHER.VALUE.ARRIVES': 'Kommer i {nextLoyaltyMonth}',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.VOUCHER.VALUE.TOTAL': 'Din totala bonus',
  'CLUB_MEMBER.BONUS.CURRENT.MONTH.BONUS.FROM.PARTNERS': 'Du får också bonus från',
  'CLUB_MEMBER.BONUS.REMAINING.DAYS.LAST.DAY': 'Sista dagen att samla poäng',
  'CLUB_MEMBER.BONUS.REMAINING.DAYS.ONE.DAY': 'dag kvar att samla poäng',
  'CLUB_MEMBER.BONUS.REMAINING.DAYS.MORE.DAYS': 'dagar kvar att samla poäng',
  'CLUB_MEMBER.BONUS.REMAINING.DAYS.ONE.DAY.MAX.POINTS': 'dag kvar att få ännu mer i bonus',
  'CLUB_MEMBER.BONUS.REMAINING.DAYS.MORE.DAYS.MAX.POINTS': 'dagar kvar att få ännu mer i bonus',
  'CLUB_MEMBER.BONUS.REDEEMED.VOUCHER.WHEN': 'Använd: {date}',
  'CLUB_MEMBER.BONUS.REDEEMED.VOUCHERS.TITLE': 'Nyttjad bonus',
  'CLUB_MEMBER.BONUS.REDEEMED.VOUCHERS.SHOW.MORE': 'Visa fler',
  'CLUB_MEMBER.BONUS.REDEEMED.VOUCHERS.TEXT':
    'Här ser du när och var du har använt din senaste bonus.',
  'CLUB_MEMBER.BONUS.VOUCHER.VALID.AT': 'Gäller på: {store}',
  'CLUB_MEMBER.BONUS.VOUCHER.VALID.TO': 'Giltig t.o.m {date}',
  'CLUB_MEMBER.BONUS.VOUCHER.VALID.AT.ICA.AND.APH': 'ICA butiker & Apotek Hjärtat',
  'CLUB_MEMBER.BONUS.VOUCHER.VALID.AT.ICA': 'ICA butiker',
  'CLUB_MEMBER.BONUS.VOUCHER.VALID.AT.APH': 'Apotek Hjärtat',
  'CLUB_MEMBER.BONUS.NO.VOUCHER.TO.USE': 'Du har ingen bonus att använda just nu',
  'CLUB_MEMBER.BONUS.HISTORY.TITLE': 'Tidigare bonus',
  'CLUB_MEMBER.BONUS.PRELIMINARY.BONUS.HEADER': 'Bonus på väg!',
  'CLUB_MEMBER.BONUS.PRELIMINARY.BONUS.TEXT': '{day} {month} syns din bonus från förra månaden här',
  'CLUB_MEMBER.BONUS.HISTORY.DISCOUNT.TITLE': 'Mina rabatter',
  'CLUB_MEMBER.BONUS.FAQ.LINK': 'Här kan du läsa mer om allt som rör ditt bonussparande',
  'CLUB_MEMBER.BONUS.VOUCHERS.TITLE': 'Bonus att använda',
  'CLUB_MEMBER.BONUS.STAMMIS_WHEEL.FINAL': 'Sista bonusnivån nådd',
  'CLUB_MEMBER.BONUS.STAMMIS_WHEEL.NEXT_LEVEL': 'Nästa nivå',
  'CLUB_MEMBER.BONUS.STAMMIS_WHEEL.REMAINING_POINTS': '{remainingPoints} poäng kvar',
  'CLUB_MEMBER.BONUS.STAMMIS.BOOST': 'Den här månaden kan du få bonus redan vid 1000 poäng',
  'CLUB_MEMBER.NAVIGATION_START': 'Start',
  'CLUB_MEMBER.NAVIGATION_CLUB_MEMBER': 'Klubb Hjärtat',
  'CLUB_MEMBER.NAVIGATION_BECOME_MEMBER': 'Bli medlem',
  'CLUB_MEMBER.NAVIGATION_CLUB_OFFERS': 'Mina erbjudanden',
  'CLUB_MEMBER.NAVIGATION_SHARED_BONUS': 'Gemensam bonus ICA',
  'CLUB_MEMBER.NAVIGATION_MEMBER_OFFERS': 'Medlemserbjudanden och samarbetspartners',
  'CLUB_MEMBER.NAVIGATION_MEMBERSHIP_CONDITIONS': 'Medlemsvillkor',
  'CLUB_MEMBER.NAVIGATION_SENIOR_AND_STUDENT_DISCOUNT': 'Student- och seniorrabatt',
  'CLUB_MEMBER.NAVIGATION_CLUB_MEMBER_START': 'Mina sidor',
  'CLUB_MEMBER.NAVIGATION_MY_DETAILS': 'Mina uppgifter',
  'CLUB_MEMBER.NAVIGATION_MY_BONUS_CHECKS': 'Mina bonuscheckar',
  'CLUB_MEMBER.NAVIGATION_ORDER_HISTORY': 'Mina ordrar',
  'CLUB_MEMBER.NAVIGATION_LOGOUT': 'Logga ut',
  'CLUB_MEMBER.MY_OFFERS.INTRO.LOGIN_BUTTON': 'Logga in',
  'CLUB_MEMBER.MY_OFFERS.INTRO.ALERT_LOGIN':
    'Logga in för att kunna se dina personliga erbjudanden.',
  'CLUB_MEMBER.MY_OFFERS.INTRO.ALERT_REGISTRER': 'Du har inget medlemskap hos oss.',
  'CLUB_MEMBER.MY_OFFERS.INTRO.ALERT_REGISTER.TEXT':
    'Bli medlem och ta del av våra fina erbjudanden redan idag.',
  'CLUB_MEMBER.MY_OFFERS.CLUB_OFFERS.TITLE': 'Klubberbjudanden',
  'CLUB_MEMBER.MY_OFFERS.WEEKLY_OFFERS.TITLE': 'Klubbklipp',
  'CLUB_MEMBER.MY_OFFERS.WEEKLY_OFFERS.NO_OFFERS_MESSAGE': 'Just nu finns inga klubbklipp.',
  'CLUB_MEMBER.MY_OFFERS.START.NO_OFFERS_MESSAGE': 'Just nu finns inga erbjudanden att presentera.',
  'CLUB_MEMBER.MY_OFFERS.PERSONAL_OFFERS.TITLE': 'Personliga erbjudanden',
  'CLUB_MEMBER.MY_OFFERS.NO_PERSONAL_OFFERS.TEXT':
    'Om du är medlem och handlar mycket hos oss kan du också få personliga erbjudanden på utvalda produkter du handlar lite oftare av.',
  'CLUB_MEMBER.MY_OFFERS.CLUB_OFFERS.INFO_TEXT_DURATION': 'Gäller t o m {campaignEndDate}',
  'CLUB_MEMBER.START.HERO.OFFERS_ARTICLE.TITLE': 'Lite extra kärlek från hjärtat.',
  'CLUB_MEMBER.START.HERO.OFFERS_ARTICLE.BODY':
    'Som klubbmedlem på Apotek Hjärtat får du som vanligt extra förmånliga {CLUB_OFFERS}. {br} {br} För att se ditt totala aktuella saldo för intjänade poäng hos både Apotek Hjärtat och ICA, gå till {MY_BONUS}.',
  'CLUB_MEMBER.START.HERO.OFFERS_ARTICLE.CLUB_OFFERS': 'klubberbjudanden',
  'CLUB_MEMBER.START.HERO.OFFERS_ARTICLE.MY_BONUS': 'Min bonus',
  'CLUB_MEMBER.START.HERO.OFFERS_ARTICLE.GO_TO_CLUB_OFFERS': 'Gå till klubberbjudanden',
  'CLUB_MEMBER.START.PERSONAL_OFFERS.SHOW_ALL_PERSONAL_OFFERS': 'Visa alla erbjudanden',
  'CLUB_MEMBER.START.PERSONAL_OFFERS.NO_PERSONAL_OFFERS':
    'Du har inga personliga erbjudanden just nu!',
  'CLUB_MEMBER.START.PERSONAL_OFFERS.PERSONAL_OFFER_TITLE': 'Bara för dig!',
  'CLUB_MEMBER.START.PERSONAL_OFFERS.YOUR_PRICE': 'Ditt klubbpris',
  'CLUB_MEMBER.START.PERSONAL_OFFERS.WEB_PRICE': 'Webbpris:',
  'CLUB_MEMBER.START.HERO.MY_PAGES.IMAGE_ALT': 'mina sidor',
};

const searchResultPage = {
  'SEARCH_RESULT.PAGE.TITLE': 'Sökresultat',
  'SEARCH_RESULT.PAGE.SEARCH_RESULTS': 'Sökresultat för:',
  'SEARCH_RESULT.PAGE.NO_RESULTS': 'Inga resultat hittades för:',
  'SEARCH_RESULT.PAGE.ERROR.TITLE': 'Ojdå!',
  'SEARCH_RESULT.PAGE.ERROR.FALLBACK_DESCRIPTION': 'Ett oväntat fel inträffade',
  'SEARCH_RESULT.PAGE.NO_RESULTS_NEW': 'Tyvärr hittade vi ingenting som matchar din sökning',
  'SEARCH_RESULT.PAGE.NO_RESULTS_ARTICLES': 'Hittade inga produkter som matchar din sökning',
  'SEARCH_RESULT.PAGE.NO_RESULTS_PHARMACIES': 'Hittade inga apotek som matchar din sökning',
  'SEARCH_RESULT.PAGE.NO_RESULTS_BRANDS': 'Hittade inga varumärken som matchar din sökning',
  'SEARCH_RESULT.PAGE.NO_RESULTS_CATEGORIES': 'Hittade inga kategorier som matchar din sökning',
  'SEARCH_RESULT.PAGE.NO_RESULTS_PAGES': 'Hittade ingen information som matchar din sökning',
};

const miniCart = {
  'MINICART.TITLE': 'Varukorg',
  'MINICART.TITLE_QUANTITY': 'Varukorg, {quantity} varor',
  'MINICART.EMPTY': 'Din varukorg är tom!',
  'MINICART.DISCOUNT': 'Du sparar totalt',
  'MINICART.GO_TO_CHECKOUT': 'Gå till kassan',
  'MINICART.START_SHOPPING': 'Börja handla',
  'MINICART.EMPTY_CART': 'Töm varukorgen',
  'MINICART.ARE_YOU_SURE': 'Vill du tömma din varukorg?',
  'MINICART.YES': 'Töm varukorg',
  'MINICART.NO': 'Avbryt',
  'MINICART.MAX_QUANTITY': 'Max antal uppnått',
  'MINICART.MAX_QUANTITY.RX': 'Max antal utag uppnått',
  'MINICART.PRESCRIPTION_FOR': 'Recept för {name}',
  'MINICART.PRESCRIPTION_ANIMAL': 'Djurrecept',
  'MINICART.PRESCRIPTION_PRICE_TOTAL': 'Pris innan förmån',
  'MINICART.PRESCRIPTION_DISCOUNT': 'Betalas av förmån',
  'MINICART.PRESCRIPTION_PRICE': 'Pris för recept',
  'MINICART.ERROR_MESSAGE': 'Något gick fel, försök igen.',
};

const findPharmacy = {
  'FIND_PHARMACY.NEAR_YOU.HEADING': 'Välkommen',
  'FIND_PHARMACY.NEAR_YOU.PHARMACY_CLOSED': 'Stängt',
  'FIND_PHARMACY.NEAR_YOU.CLOSED_FOR_LUNCH': 'Lunchstängt',
  'FIND_PHARMACY.NEAR_YOU.INFO_PARAGRAPH1':
    'Hej och välkommen till ditt Apotek Hjärtat. Här hittar du öppettider, adress och karta till oss.',
  'FIND_PHARMACY.NEAR_YOU.INFO_PARAGRAPH2':
    'Apotek Hjärtat är Sveriges största fristående apotekskedja. Vi vill hjälpa dig till bättre hälsa och ökat välbefinnande genom ett bredare sortiment, bättre rådgivning och utökade tjänster.',
  'FIND_PHARMACY.NEAR_YOU.INFO_PARAGRAPH3': 'Hjärtligt välkommen!',
  'FIND_PHARMACY.NEAR_YOU.CUSTOMER_SERVICE': 'Kundservice',
  'FIND_PHARMACY.NEAR_YOU.CUSTOMER_SERVICE_PHONE': '0771-405 405',
  'FIND_PHARMACY.NEAR_YOU.ADDRESS': 'Adress',
  'FIND_PHARMACY.NEAR_YOU.SERVICES': 'Tjänster',
  'FIND_PHARMACY.NEAR_YOU.OPENING_HOURS': 'Öppettider',
  'FIND_PHARMACY.NEAR_YOU.MAP_TITLE': 'Hitta hit',
  'FIND_PHARMACY.NEAR_YOU.GENERAL_ERROR_MESSAGE': 'Ett oväntat fel inträffade.',
};

const customerService = {
  'CUSTOMER_SERVICE.PRESCRIBER.CONTACT_US_BUTTON': 'Kontakta oss på kundservice',
  'CUSTOMER_SERVICE.CONTACT_US.TITLE': 'Kundservice - vi hjälper dig gärna!',
  'CUSTOMER_SERVICE.NAVIGATION_CONTACT_US': 'Kontakta oss',
  'CUSTOMER_SERVICE.NAVIGATION_FAQ': 'Vanliga frågor',
  'CUSTOMER_SERVICE.NAVIGATION_OUR_SERVICES': 'Våra tjänster',
  'CUSTOMER_SERVICE.NAVIGATION_DOWNLOAD_APH_APP': 'Ladda ner Hjärtats app',
  'CUSTOMER_SERVICE.NAVIGATION_ECOM_INFO': 'Ny på e-handel? Läs guiden',
  'CUSTOMER_SERVICE.NAVIGATION_SHIPPING_AND_DELIVERY': 'Frakt & leverans',
  'CUSTOMER_SERVICE.NAVIGATION_PURCHASE_CONDITIONS': 'Köpvillkor',
  'CUSTOMER_SERVICE.NAVIGATION_TERMS_OF_USE': 'Användarvillkor för appen',
  'CUSTOMER_SERVICE.NAVIGATION_LIVE_CHAT_TERMS': 'Villkor för livechatt',
  'CUSTOMER_SERVICE.NAVIGATION_AI_CHAT_TERMS': 'Villkor för Apotek Hjärtats Hjälprobot',
  'CUSTOMER_SERVICE.NAVIGATION_PRESCRIBER': 'Är du förskrivare?',
  'CHAT_WIDGET.CONTACT_US.TITLE': 'Chatta med oss!',
  'CHAT_WIDGET.CONTACT_US.INFO': 'Få hjälp av vår hjälprobot eller chatta live med vår kundservice',
  'CHAT_WIDGET.CONTACT_US.COOKE_ALERT_TEXT':
    'För att bli hjälpt av vår hjälprobot så behöver du först godkänna funktionella cookies.',
  'CHAT_WIDGET.CONTACT_US.COOKE_ALERT_BUTTONTEXT': 'Godkänn funktionella cookies',
  'CHAT_WIDGET.CONTACT_US.CALL_TO_ACTION.CHAT': 'Börja chatta',
  'CHAT_WIDGET.CONTACT_US.LINK_TO_CONTACT_US': 'Gå till vår "Kontakta oss" sida för fler val',
  'CHAT_WIDGET.CONTACT_US.MIN_DOKTOR': 'Kontakta Min Doktor',
  'CUSTOMER_SERVICE.CONTACT_US.CARD_PHONE.CALL_CTA.MOBILE': 'Ring',
};

const aboutUs = {
  'ABOUT_US.NAVIGATION_RIGHT_NOW_TITLE': 'Just nu på Hjärtat',
  'ABOUT_US.NAVIGATION_ORGANIZATION_AND_QUICK_FACTS': 'Organisation & snabbfakta',
  'ABOUT_US.NAVIGATION_SUSTAINABILITY': 'Hållbarhet',
  'ABOUT_US.NAVIGATION_JOB_AND_CAREER': 'Jobb & karriär',
  'ABOUT_US.NAVIGATION_CONTACT_IT': 'Kontakt supportkontoret',
  'ABOUT_US.NAVIGATION_PRESS_AND_IMAGE_BANK': 'Press och bildbank',
  'ABOUT_US.NAVIGATION_COMPETITION_CONDITIONS': 'Tävlingsvillkor',
  'ABOUT_US.NAVIGATION_PERSONAL_DATA_PROCESSING': 'Personuppgiftsbehandling',
  'ABOUT_US.NAVIGATION_INFO_ABOUT_COOKIES': 'Information om cookies',
};

const productList = {
  'PRODUCT_LIST.BREADCRUMB': 'Produkter',
  'PRODUCT_LIST.SORT_ORDER.LABEL': 'Sortera',
  'PRODUCT_LIST.PAGINATION.SHOW_MORE': 'Visa fler',
  'PRODUCT_LIST.PAGINATION.SHOW_PREV': 'Visa föregående',
  'PRODUCT_LIST.PAGINATION.COUNT':
    'Visar {count} av {totalCount, plural, one {# produkt} other {# produkter}}',
  'PRODUCT_LIST.PAGE.PRODUCTS':
    '{total, plural, =0 {hittade inga produkter} one {{total} produkt} other {{total} produkter}}',
  'PRODUCT_LIST.FILTER.BUTTON_TEXT': 'Filter',
  'PRODUCT_LIST.FILTER.CHOSEN_FILTER': 'Valda filter',
  'PRODUCT_LIST.FILTER.DRAWER.CHOSEN_FILTER': 'Valda filter',
  'PRODUCT_LIST.FILTER.DRAWER.HEADING': 'Filter',
  'PRODUCT_LIST.FILTER.DRAWER.CLEAR_FILTERS': 'Rensa alla filter',
  'PRODUCT_LIST.FILTER.DRAWER.NO_FILTERS': 'Inga filter valda',
  'PRODUCT_LIST.FILTER.DRAWER.SHOW_RESULTS': 'Visa resultat ({count})',
};

const categoryList = {
  'CATEGORY_LIST.HEADER.CONTRACT': 'Mindre',
  'CATEGORY_LIST.HEADER.EXPAND': 'Läs mer',
};

const categoryNavigation = {
  'CATEGORY_NAVIGATION.VIEW_ALL': 'Se alla',
  'CATEGORY_NAVIGATION.ALL_PRODUCTS': 'Alla produkter',
};

const aphAlert = {
  'APH_ALERT.GENERAL_ERROR_MESSAGE': 'Ett oväntat fel inträffade.',
};

const suggestions = {
  'SUGGESTIONS.HEADER': 'Behöver du något mer?',
  'SUGGESTIONS.CONTINUE_BUTTON.TEXT': 'Vidare till betalning',
  'SUGGESTIONS.PAGE_TITLE': 'Rekommendationer',
};

const campaignPage = {
  'CAMPAIGN_PAGE.BREADCRUMB': 'Kampanjer',
};

const tipsAndAdvicePage = {
  'TIPS_ADVICE_PAGE.BREADCRUMB': 'Rådgivning',
};

const brandPage = {
  'BRAND_PAGE.BREADCRUMB': 'Varumärken',
};

const generalPrescriptionInfoPage = {
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.GET_PRESCRIPTION_ONLINE': 'Hämta ut recept online',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.POA_INFO': 'Så funkar fullmakter',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.EXCHANGE_OF_MEDICINE': 'Utbyte av läkemedel',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.HIGH_COST_PROTECTION': 'Så funkar högkostnadsskyddet',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.LEFTOVER_MEDICINE': 'Lämna in överblivna läkemedel',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.TRAVEL_WITH_MEDICINE': 'Resa med läkemedel',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.PERSONAL_DATA_PROCESSING': 'Personuppgiftsbehandling',

  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.HOW_IT_WORKS': 'Så funkar det',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.E_COMMERCE_GUIDE': 'E-handelsguiden',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.HAS_PRESCRIPTION_ARRIVED': 'Har mitt recept kommit in?',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.GET_YOUR_PRESCRIPTION': 'Hämta ut dina recept',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.GET_PRESCRIPTION_CHILD': 'Hämta ut recept till barn',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.GET_PRESCRIPTION_TEENAGER': 'Hämta ut recept till tonåring',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.GET_PRESCRIPTION_ANIMAL': 'Hämta ut recept till djur',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.E_IDENTIFICATION': 'Så funkar BankID',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.PART_PAYMENT': 'Så funkar delbetalning',
  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.SEARCH_PRESCRIPTION': 'Sök efter läkemedel',

  'GENERAL_PRESCRIPTION_INFO.NAVIGATION.APH_APP': 'Hjärtats app',

  'GENERAL_PRESCRIPTION_INFO.NEED_HELP_PUFF.HEADER': 'Behöver du hjälp?',
  'GENERAL_PRESCRIPTION_INFO.NEED_HELP_PUFF.TEXT':
    'Går ditt läkemedel inte att beställa eller har du ett särskilt önskemål? Kontakta oss så hjälper vi dig. Våra farmaceuter svarar också på frågor om dina läkemedel.',
};

const quantitySelector = {
  'QUANTITY_SELECTOR.INCREASE': 'Öka antal',
  'QUANTITY_SELECTOR.DECREASE': 'Minska antal',
  'QUANTITY_SELECTOR.NEW_QUANTITY.SUCCESS': `Antalet uppdaterat till {quantity}`,
  'QUANTITY_SELECTOR.NEW_QUANTITY.ERROR': `Antalet gick inte att uppdatera`,
}

export const sv = {
  ...common,
  ...error,
  ...brandPage,
  ...checkout,
  ...checkoutConfirm,
  ...cart,
  ...summary,
  ...tooltip,
  ...panels,
  ...cookieSettings,
  ...prescription,
  ...orderDetails,
  ...orderHistory,
  ...orderHistoryStatus,
  ...orderNotes,
  ...orderStatus,
  ...productLabels,
  ...productVariantDetails,
  ...productVariantComparisonPrice,
  ...productVariantRetailPrice,
  ...productVariantSelector,
  ...productVariantReviews,
  ...productVariantQuantityFormatter,
  ...productDetailsError,
  ...productDetailsArticleType,
  ...productDetailsButtons,
  ...consent,
  ...consentInfo,
  ...productVariantPrice,
  ...clubMember,
  ...customerService,
  ...aboutUs,
  ...productVariantInformationTextIcon,
  ...productVariantShowExcludedFromPromotionInformation,
  ...productDetailsStoreStockStatus,
  ...findInStore,
  ...productQuantityUnit,
  ...searchResultPage,
  ...articleMaxPurchase,
  ...ArticlePrice,
  ...miniCart,
  ...productList,
  ...categoryList,
  ...categoryNavigation,
  ...findPharmacy,
  ...aphAlert,
  ...suggestions,
  ...campaignPage,
  ...tipsAndAdvicePage,
  ...generalPrescriptionInfoPage,
  ...quantitySelector,
};
