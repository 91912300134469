import theme from '~/styles/theme';

const sizes = {
  small: {
    height: 32,
    maxWidth: 48,
    fontSize: 16,
    minWidth: 56,
  },
  medium: {
    height: 54,
    width: 80,
    fontSize: 32,
  },
};

const colors = {
  icaPink: {
    background: theme.palette.externalCompanyColors.ICA.PINK,
    color: theme.palette['color/text/default'],
  },
  icaRed: {
    background: theme.palette.externalCompanyColors.ICA.PINK,
    color: theme.palette.externalCompanyColors.ICA.RED,
  },
  aphGreen: {
    background: theme.palette['color/background/visual-dark'],
    color: theme.palette['color/text/brand'],
  },
  orange: {
    background: theme.palette.externalCompanyColors.ICA.ORANGE,
    color: theme.palette['color/text/inverse'],
  },
  blue: {
    background: theme.palette.externalCompanyColors.ICA_INSURANCE.BLUE,
    color: theme.palette['color/text/inverse'],
  },
  darkRed: {
    background: theme.palette.externalCompanyColors.ICA_BANK.RED,
    color: theme.palette['color/text/inverse'],
  },
  gray: {
    background: '#a5a5a5',
    color: theme.palette['color/text/default'],
  },
};

export { sizes, colors };
