import type StatusCode from 'status-code-enum';
import { container } from 'tsyringe';

export type StatusSubscriber = {
  status: StatusCode | ((statusCode: StatusCode) => boolean);
  act: () => void;
};

class StatusEventDispatcher {
  private subscribers: StatusSubscriber[] = [];

  /**
   * This is the method used by the api-client and will trigger all matching subscriptors to act
   * @param status the status from the client instance
   */
  handleNewStatus(status: number | StatusCode): void {
    // IF we want to only act on status change, simply add a holder property to the class which checks if oldSatus !== newStatus
    this.subscribers
      .filter((sub) => {
        if (typeof sub.status === 'function') {
          return sub.status(status);
        }
        return sub.status === status;
      })
      .map((sub) => sub.act());
  }

  /**
   * Subscribe to a status and expect the callback to be fired everytime this status is found
   * @param statusSubscriber the status to match and the method to fire everytime the status is found
   */
  subscribeToStatus(statusSubscriber: StatusSubscriber): void {
    this.subscribers.push(statusSubscriber);
  }

  /**
   * Removes the subscription
   * @param statusSubscriber this needs to match on instance (obj === obj) to be unsubscribed
   */
  unsubscribe(statusSubscriber: StatusSubscriber): void {
    this.subscribers = this.subscribers.filter((s) => s !== statusSubscriber);
  }
}

container.registerSingleton(StatusEventDispatcher);

export { StatusEventDispatcher };
