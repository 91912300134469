import React from 'react';
import type { ButtonProps } from '@mui/material';
import { Fab } from '@mui/material';
import { Icon, type IconProps } from '@aph/ui/components/icon/icon';
import type { TextSize } from '~/styles/text-size';

const fabSizeMapper: Partial<{ [textSize in TextSize]: string | null }> = {
  small: '24px',
  medium: '32px',
  large: '40px',
};

export interface QuantityButtonProps {
  isDisabled: boolean;
  onClick: () => void;
  icon: IconProps['name'];
  size: ButtonProps['size'];
  testId: string;
}

export const QuantityButton = ({
  isDisabled,
  onClick,
  size = 'large',
  icon,
  testId,
  ...props
}: QuantityButtonProps) => {
  const fabSize = fabSizeMapper[size];
  return (
    <Fab
      onClick={onClick}
      data-testid={testId}
      type="button"
      disableRipple
      disabled={isDisabled}
      sx={{
        minWidth: fabSize,
        minHeight: fabSize,
        display: 'flex',
        zIndex: 'inherit',
        '&:hover': {
          '@media (hover: hover)': {
            '& svg path': { fill: (theme) => theme.palette['color/icon/inverse'] },
          },
        },
      }}
      {...props}
    >
      <Icon name={icon} size="small" fill={isDisabled ? 'fill-action-subtle' : 'fill-action'} />
    </Fab>
  );
};
