import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { ChatEventTopic } from './chat-event-topic';

type ChatEventActionsState = {
  registeredActions: Map<ChatEventTopic, ((onCompleted?: () => void) => void)[]>;
};

const initialState: ChatEventActionsState = {
  registeredActions: new Map<ChatEventTopic, ((onCompleted?: () => void) => void)[]>(),
};

interface ChatEventActionsStore {
  registeredActionsState: ChatEventActionsState;
  actions: {
    create: (topic: ChatEventTopic, callback: () => void) => void;
    add: (topic: ChatEventTopic, callback: () => void) => void;
    remove: (topic: ChatEventTopic, callback: () => void) => void;
  };
}

export const useChatEventActionsStore = create<ChatEventActionsStore>()(
  devtools<ChatEventActionsStore>(
    (set, get) => ({
      registeredActionsState: initialState,
      actions: {
        create: (topic, callback) => {
          set({
            registeredActionsState: {
              registeredActions: get().registeredActionsState.registeredActions.set(topic, [
                callback,
              ]),
            },
          });
        },
        add: (topic, callback) => {
          const temp = get().registeredActionsState.registeredActions;
          temp.get(topic)?.push(callback);
          set({
            registeredActionsState: {
              registeredActions: temp,
            },
          });
        },
        remove: (topic, callback) => {
          const temp = get().registeredActionsState.registeredActions;
          const index = temp.get(topic)?.findIndex((fn) => fn === callback);
          if (index) {
            temp.get(topic)?.splice(index, 1);
            set({
              registeredActionsState: {
                registeredActions: temp,
              },
            });
          }
        },
      },
    }),
    {
      name: 'UseChatEventActions',
      anonymousActionType: 'UseChatEventActions',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useChatEventActions = () => useChatEventActionsStore((state) => state.actions);
