import React from 'react';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';

type ContactUsFabProps = {
  onToggle: () => void;
  isContactUsButtonVisible: boolean | undefined;
};

export const ContactUsFab: React.FC<ContactUsFabProps> = ({
  onToggle,
  isContactUsButtonVisible,
}) => {
  const intl = useIntl();
  // Temporary fix for hiding the contact us button on video test page, this should be removed when test is over
  const { asPath } = useRouter();

  if (asPath.includes('/video-test/demo/')) {
    return null;
  }

  return (
    <Box
      display={isContactUsButtonVisible ? 'none' : 'block'}
      position="fixed"
      top="unset"
      left="unset"
      bottom={30}
      right={30}
      zIndex={10}
    >
      <Button
        data-pw="contact-us-button"
        size="large"
        onClick={onToggle}
        className="hidden md:flex"
        aria-label={intl.messages['CHAT_WIDGET.CONTACT_US.TITLE'].toString()}
      >
        <Icon name="SpeechBubble" />
        <FormattedMessage id="CHAT_WIDGET.CONTACT_US.TITLE" />
      </Button>
      <ButtonCircle
        data-pw="contact-us-button"
        size="extraLarge"
        icon="SpeechBubble"
        onClick={onToggle}
        className="md:hidden"
        aria-label={intl.messages['CHAT_WIDGET.CONTACT_US.TITLE'].toString()}
      />
    </Box>
  );
};
