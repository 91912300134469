import { logger } from '~/logging';
import type { IFeatureToggle } from './IFeatureToggle';
import { InvalidFeatureNameArgumentError } from './InvalidFeatureNameArgumentError';

/**
 * @description This is the Feature Cookie information holder object
 * @param cookieName the feature cookie name
 * @param cookieValue the feature cookie value
 */
class CookieFeatureToggle implements IFeatureToggle {
  private prefix: string;

  private suffix: string;

  constructor(cookieName: string, cookieValue: string) {
    if (!cookieName || cookieName === '') {
      throw new InvalidFeatureNameArgumentError(
        `[CookieFeatureToggle.ts] incorrect cookie name is: "${cookieName}"`,
      );
    }

    this.prefix = process.env.NEXT_PUBLIC_FEATURE_COOKIE_PREFIX as string;
    this.suffix = process.env.NEXT_PUBLIC_FEATURE_COOKIE_SUFFIX as string;

    this.name = cookieName;
    this.cookieName = cookieName;
    this.isActive = false;
    this.isEnabled = true;
    this.visualToggle = true;
    this.value = cookieValue;
    this.setFeatureName();
    this.setIsActive();
    logger.debug(`Feature cookie: ${this.name}. Active: ${this.isActive}. Value: ${this.value}`);
  }

  private setFeatureName() {
    let featureName = this.cookieName;
    if (featureName.startsWith(this.prefix)) {
      featureName = featureName.substring(this.prefix.length);
    }
    if (this.suffix.length > 0 && featureName.endsWith(this.suffix)) {
      featureName = featureName.slice(0, -this.suffix.length);
    }
    this.name = featureName;
  }

  private setIsActive() {
    const numberValue = +this.value;
    if (Number.isNaN(numberValue)) {
      this.isActive = this.value !== 'Disabled';
      return;
    }

    this.isActive = numberValue >= 1;
  }

  name: string;

  isActive: boolean;

  isEnabled: boolean;

  cookieName: string;

  value: string;

  visualToggle?: boolean;
}

export { CookieFeatureToggle };
