import { useEffect } from 'react';

export const ChatBotPlugin: React.FC = () => {
  useEffect(() => {
    const twcDiv = document.createElement('div');
    twcDiv.id = 'teneo-web-chat';
    document.body.appendChild(twcDiv);

    const twcCoreCode = document.createElement('script');
    twcCoreCode.src = `${process.env.NEXT_PUBLIC_TENEO_CHAT_BASE_URL}`;
    twcCoreCode.async = true;
    // TODO: we need to get this optanon class to work
    twcCoreCode.type = 'text/plain';
    twcCoreCode.setAttribute('class', 'optanon-category-C0003');
    twcCoreCode.id = 'twcCoreCode';

    document.body.appendChild(twcCoreCode);

    return () => {
      document.getElementById('twcCoreCode')?.remove();
    };
  }, []);
  return null;
};
