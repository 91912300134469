import type { ThemeOptions } from '@mui/material/styles';

const MuiRadio: NonNullable<ThemeOptions['components']>['MuiRadio'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette['color/text/action-dark'],
      padding: 0,
      background: theme.palette['color/background/elevated'],
      height: 28,
      width: 28,
    }),
  },
};

export default MuiRadio;
