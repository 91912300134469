import type { StateCreator, StoreApi } from 'zustand/vanilla';
import type { FeatureToggleState } from './FeatureToggleState';
import { initializeState } from './initializeState';

export const initializeFeatureToggleStoreMiddleware =
  (create: StateCreator<FeatureToggleState>) =>
  (
    set: StoreApi<FeatureToggleState>['setState'],
    get: StoreApi<FeatureToggleState>['getState'],
    api: StoreApi<FeatureToggleState>,
  ) => {
    initializeState({ getState: get, setState: set });
    return create(set, get, api);
  };
