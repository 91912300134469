import React, { useState } from 'react';
import NextImage from 'next/image';
import type { ImageProps } from 'next/image';
import noImage from '@aph/images/no_image.png';
import noImageRx from '@aph/images/rx_image.png';
import type { ImagePreset } from '@aph/utilities/image-presets';
import { addImagePreset } from '@aph/utilities/image-presets';

interface Props extends ImageProps {
  styles?: React.CSSProperties;
  preset?: ImagePreset;
  shouldUseRxFallback?: boolean;
}

export const Image = ({
  src,
  alt,
  shouldUseRxFallback,
  preset,
  height,
  width,
  styles,
  loading,
  unoptimized,
  priority,
  ...rest
}: Props) => {
  const [image, setImage] = useState(src && preset ? addImagePreset(`${src}`, preset) : src);

  const fallbackImage = shouldUseRxFallback ? noImageRx.src : noImage.src;

  return (
    <NextImage
      src={image}
      alt={alt}
      style={styles}
      height={height}
      width={width}
      loading={loading}
      unoptimized={unoptimized}
      priority={priority}
      onError={() => {
        setImage(fallbackImage);
      }}
      {...rest}
    />
  );
};
