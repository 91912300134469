export type RedirectProps = {
  redirectToExternal: string;
};
/**
 *
 * @description Use this method when you want to redirect to external URLs not withing the NextJS Application. To route within the NextJS applicatoin use the useRouter hook and the method push
 */
export const redirectToExternalUrl = ({ redirectToExternal }: RedirectProps): void => {
  window.location.href = redirectToExternal;
};
