import { Cookies } from 'react-cookie';

/**
 * @description Takes a tracking origin parameter and a returnToUrl parameter to create a login redirect URL.
 * @param origin This is only used for tracking purposes.
 * @param returnToUrl If returnToUrl is undefined, then window.location.href is used as base to create the URL. Otherwise process.env.NEXT_PUBLIC_BASE_URL_WEB is used and concatenated with returnToUrl parameter. 
 * @returns a string with the login URL pointing to Curity via the Dispatcher/Gateway process.env.NEXT_PUBLIC_BASE_URL is used as base.
 */

export const getLoginRedirectUrl = (origin: string, returnToUrl?: string) => {

  if (typeof window !== 'undefined') { // If run in the browser.

    const currentLocationHrefSearchParams = new URLSearchParams(window.location.search);

    if (process.env.NEXT_PUBLIC_BASE_URL_WEB === undefined) {
      throw Error('NEXT_PUBLIC_BASE_URL_WEB is undefined')
    }

    const baseUrlWeb = new URL(process.env.NEXT_PUBLIC_BASE_URL_WEB);

    if (returnToUrl) {
      baseUrlWeb.pathname = returnToUrl;
    } else {
      baseUrlWeb.pathname = window.location.pathname;
    }

    currentLocationHrefSearchParams.forEach((value, key) => {
      baseUrlWeb.searchParams.append(key, value);
    });

    baseUrlWeb.searchParams.append('loginOrigin', origin);

    const anonymousIdCookie = new Cookies().get('aph_anonymous_id');

    return `${process.env.NEXT_PUBLIC_BASE_URL}/auth/v1/login?resumeUri=${encodeURIComponent(
      `${baseUrlWeb.toString()}`,
    )}${`&anonymousId=${encodeURIComponent(anonymousIdCookie)}`}`;
  }


  return `${process.env.NEXT_PUBLIC_BASE_URL}/auth/v1/login?resumeUri=${encodeURIComponent(
    `?loginOrigin=${origin}`,
  )}`;
};
