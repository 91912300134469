import type { ThemeOptions } from '@mui/material/styles';
import { ICAText } from '../fonts';
import * as msq from '../media-queries';

export const MuiFormLabel: NonNullable<ThemeOptions['components']>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 16,
      fontFamily: ICAText,
      '&.Mui-disabled': {
        color: theme.palette['color/text/subtle'],
      },
    }),
  },
};

export const MuiFormControlLabel: NonNullable<ThemeOptions['components']>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      marginLeft: '0',
      marginRight: '0',
      width: '100%',
      [msq.md]: {
        width: 'unset',
      },
    },
  },
};

export const MuiFormControl: NonNullable<ThemeOptions['components']>['MuiFormControl'] = {
  styleOverrides: {
    root: {
      width: '100%',
    },
    marginNormal: {
      marginBottom: 16,
      marginTop: 0,
    },
  },
};

export const MuiFormHelperText: NonNullable<ThemeOptions['components']>['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      color: '#ca1702',
      marginLeft: 12,
      marginTop: 0,
    },
    contained: {
      marginLeft: 12,
    },
  },
};
