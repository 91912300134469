import * as yup from 'yup';
import { logger } from '~/logging';
import type { FeatureToggleStates } from './FeatureToggleStates';
import type { IFeatureToggle, IFeatureToggleEnv } from './IFeatureToggle';
import { InvalidFeatureNameArgumentError } from './InvalidFeatureNameArgumentError';
import { InvalidFeatureValueArgumentError } from './InvalidFeatureValueArgumentError';

const isProduction = () => process.env.NODE_ENV === 'production';

export const featureToggleSchema = yup
  .string<FeatureToggleStates>()
  .oneOf(
    ['Enabled', 'Disabled'],
    (params) =>
      `"${params.value}" is not a valid Feature Toggle value, allowed values are "${params.values}"`,
  )
  .defined();

const disabledByDefaultFeatureToggleSchema = featureToggleSchema.default('Disabled');

export type FeatureToggleKey = `aph_features_${string}`;

/**
 * @description This is the Feature Toggle information holder object, it has a feature toggle name and a method to check if it is enabled
 * @param name the feature toggle name, will be used to look up in servies
 * @param envFeature the feature toggle settings
 */
class EnvFeatureToggle implements IFeatureToggle {
  private prefix: string;

  private suffix: string;

  private domain: string;

  name: string;

  isActive: boolean;

  onValue?: string;

  offValue?: string;

  isEnabled: boolean;

  cookieName: string;

  cookieDomain?: string;

  visualToggle?: boolean;

  isReadOnly?: boolean;

  constructor(name: string, envFeature: FeatureToggleStates | IFeatureToggleEnv) {
    if (!name || name === '') {
      throw new InvalidFeatureNameArgumentError(
        `[EnvFeatureToggle.ts] incorrect argument, feature doesn't have a name. Name is ${JSON.stringify(
          name,
        )} For debuging purposees:  This is it's values: ${JSON.stringify(envFeature)}`,
      );
    }

    /**
     * ========================= Don't change the order of these operations ==========
     */

    this.prefix = process.env.NEXT_PUBLIC_FEATURE_COOKIE_PREFIX as string;
    this.suffix = process.env.NEXT_PUBLIC_FEATURE_COOKIE_SUFFIX as string;
    this.domain = process.env.NEXT_PUBLIC_FEATURE_COOKIE_DOMAIN as string;

    /**
     * The condition is inside the if-statement's parentheses ( ). It consists of two parts connected with the && (logical AND) operator, which means both conditions must be true for the entire if-statement to execute.
     * The first part of the condition checks if any of the three variables this.prefix, this.suffix, and this.domain is undefined. It uses the OR || operator to combine the conditions. If any of the variables are undefined, the expression will be true.
     * The second part of the condition checks if any of the three variables this.prefix, this.suffix, and this.domain are of type 'string'. It uses the typeof operator to get the type of each variable and then compares it to 'string'. If any of the variables are of type 'string', the expression will be true.
     * If both conditions evaluate to true, it means that at least one of the required environment variables is missing (undefined) or is not of type 'string'.
     */
    if (
      (this.prefix === undefined || this.suffix === undefined || this.domain === undefined) &&
      (typeof this.prefix === 'string' ||
        typeof this.suffix === 'string' ||
        typeof this.domain === 'string')
    ) {
      throw new Error(
        `[EnvFeatureToggle.ts] Invalid environment variables set. Needs all of [NEXT_PUBLIC_FEATURE_COOKIE_PREFIX, NEXT_PUBLIC_FEATURE_COOKIE_SUFFIX, NEXT_PUBLIC_FEATURE_COOKIE_DOMAIN] to be set and strings.`,
      );
    }

    // default values
    this.name = name;
    this.cookieName = name;
    this.isActive = false;
    this.isEnabled = true;

    /**
     * ========================= Above needs to happen first  =========================
     */

    // Enforce some type checkings for the string version of the feature toggle
    if (typeof envFeature === 'string' && (envFeature === 'Enabled' || envFeature === 'Disabled')) {
      this.setValuesFromEnvString(envFeature);
    } else {
      // Inside this method, there are some error handling incase it isn't a properly argumented feature toggle
      this.setValuesFromEnvJson(envFeature);
    }

    logger.debug(`Feature: ${this.name}. Active: ${this.isActive}. Enabled: ${this.isEnabled}`);
  }

  /**
   *
   * @param envFeature of type FeatureToggleStates (Enabled | Disabled)
   * @description This method sets the values of the feature toggle based on the feature toggle env string passed as argument
   */
  private setValuesFromEnvString(envFeature: string) {
    this.isActive = envFeature === ('Enabled' as FeatureToggleStates);
    this.onValue = 'ClientWeb';
    this.isReadOnly = this.isActive;
  }

  /**
   *
   * @param feature of type IFeatureToggleEnv
   * @desqription This method sets the values of the feature toggle based on the feature toggle env object passed as argument
   */
  private setValuesFromEnvJson(feature: IFeatureToggleEnv) {
    // Some safe guards for the feature toggle parsing method
    if (!feature) {
      logger.warn(
        `Feature toggle: ${this.name}. Inproper feature toggle env object. Should always have a value. Was now set to false impliclitly.`,
      );
      this.isEnabled = false;
      return;
    }

    // feature needs to be an object that we can parse
    if (typeof feature !== 'object') {
      throw new InvalidFeatureValueArgumentError(`
      [EnvFeatureToggle.ts] Error for feature ${
        this.name
      } incorrect argument. Feature doesn't have correct type of value. This is it's values: ${JSON.stringify(
        feature,
      )}`);
    }

    this.setNames(feature);
    // this.name isn't what it used to be any more. It was changed in the above operation.

    // If feature allows for subdomains
    if (feature.allowSubdomains) {
      // add a suffix to the cookie name (changed recently above)
      this.cookieName += this.suffix;
      // and set the cookie domain to the env variable NEXT_PUBLIC_FEATURE_COOKIE_DOMAIN
      this.cookieDomain = this.domain === 'localhost' ? this.domain : `.${this.domain}`;
    }

    // Continue parsing the feature values onto this instance of EnvFeatureToggle.
    // Can't find the documentation describing this logic any where.
    if (feature.onValue !== undefined) {
      this.onValue = feature.onValue;
    }

    if (feature.offValue !== undefined) {
      this.offValue = feature.offValue;
    }

    if (feature.isActive) {
      this.isActive = true;
      this.isReadOnly = true;
    }

    if (feature.isEnabled === false) {
      this.isEnabled = false;
    }

    this.visualToggle = feature.visualToggle;
  }

  /**
   * @param feature of type IFeatureToggleEnv
   * @description Based on some undocumented logic, this sets name & cookieName on the feature toggle.
   */
  private setNames(feature: IFeatureToggleEnv) {
    // if feature allows for name prefix (defaults to true)
    if (feature.useNamePrefix ?? true) {
      // if the feature name starts with the prefix, remove it
      if (this.name.startsWith(this.prefix)) {
        this.name = this.name.substring(this.prefix.length);
      }

      // set the cookie name to the prefix + the feature name
      this.cookieName = this.prefix + this.name;
    }

    // if the feature name ends with the suffix, remove it
    if (this.suffix.length > 0 && this.name.endsWith(this.suffix)) {
      this.name = this.name.slice(0, -this.suffix.length);
    }
  }

  public static parse(key: FeatureToggleKey, value: unknown) {
    let state: FeatureToggleStates;

    try {
      if (isProduction()) {
        state = featureToggleSchema.validateSync(value);
      } else {
        state = disabledByDefaultFeatureToggleSchema.validateSync(value);
      }

      return new EnvFeatureToggle(key, state);
    } catch (error: unknown) {
      if (yup.ValidationError.isError(error)) {
        throw new InvalidFeatureValueArgumentError(`
        [EnvFeatureToggle.ts] Error for feature "${key}" incorrect argument/s. ${error.message}`);
      }

      throw error;
    }
  }
}

export { EnvFeatureToggle };
