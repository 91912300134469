import { container } from 'tsyringe';
import { logger } from '~/logging';

export class AuthSessionTimer {
  // eslint-disable-next-line class-methods-use-this
  handleTimeout: () => void = () => {
    throw Error('executed handleTimeout before it was assigned');
  };

  private timeout: number;

  constructor() {
    this.timeout = process.env.NEXT_PUBLIC_AUTH_SESSION_TIMEOUT as unknown as number;
    this.restart();
  }

  restart(): void {
    this.stop();
    this.timer = setTimeout(() => {
      this.handleTimeout();
      logger.debug('The timeout ended and it executed handleTimeout');
    }, this.timeout);
  }

  stop(): void {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
  }

  private timer: NodeJS.Timeout | undefined;
}

container.registerSingleton(AuthSessionTimer);
