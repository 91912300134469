import type { ThemeOptions } from '@mui/material/styles';
import * as mqs from '../media-queries';

const MuiTooltip: NonNullable<ThemeOptions['components']>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette['color/background/visual'],
      borderRadius: 8,
      color: theme.palette['color/text/brand'],
      padding: '16px',
      fontSize: 14,
      lineHeight: 1.43,
      maxWidth: 300,

      '& .tooltip__content': {
        padding: '14px 16px 14px 16px',
        '& > *': { margin: 0 },
      },
    }),
    tooltipPlacementTop: {
      transformOrigin: 'center bottom',
      margin: '13px 3px 13px 13px',
      [mqs?.sm]: {
        margin: '13px 3px 13px 13px',
      },
    },
    popper: {
      pointerEvents: 'inherit',
    },
    arrow: ({ theme }) => ({
      color: theme.palette['color/icon/inverse-dark'],
    }),
  },
};

export default MuiTooltip;
