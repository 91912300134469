import React from 'react';
import type { ReactElement } from 'react';
import { Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';

export type AphDrawerProps = {
  isPanelActive: boolean;
  onCloseHandler: () => void;
  children: ReactElement;
  variant?: keyof typeof variants;
  mobileVariant?: keyof typeof variants;
  /** The side of the screen the drawer opens from on desktops */
  anchor?: 'bottom' | 'right';
  /** The side of the screen the drawer opens from on mobiles */
  mobileAnchor?: 'bottom' | 'right';
};

const variants = {
  standard: {
    styles: {
      width: 494,
      height: '100%',
    },
  },
  wide: {
    styles: {
      width: 640,
      height: '100%',
    },
  },
};

const mobileVariants = {
  standard: {
    styles: {
      width: '100%',
      minHeight: '230px',
      maxHeight: '85%',
      borderRadius: '24px 24px 0 0',
    },
  },
  wide: {
    styles: {
      width: '90%',
    },
  },
} as const;

export const AphDrawer: React.FC<AphDrawerProps> = ({
  isPanelActive,
  onCloseHandler,
  children,
  anchor = 'right',
  mobileAnchor = 'bottom',
  variant = 'standard',
  mobileVariant = 'standard',
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const styles = isDesktop ? variants[variant].styles : mobileVariants[mobileVariant].styles;
  const setAnchor = isDesktop ? anchor : mobileAnchor;

  return (
    <Drawer
      anchor={setAnchor}
      open={isPanelActive}
      onClose={onCloseHandler}
      variant="temporary"
      transitionDuration={{ enter: 350, exit: 350 }}
      PaperProps={{
        'data-testid': 'aphDrawer',
        style: {
          ...styles,
        },
      }}
    >
      {children}
    </Drawer>
  );
};
