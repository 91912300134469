import { colorTokens } from '@aph/ui/design-tokens/color-tokens';
import * as font from '../fonts';
import * as mqs from '../media-queries';

const headingBase = {
  fontFamily: font.ICAHeading,
  fontStretch: 'normal',
  letterSpacing: 'normal',
  fontWeight: 500,
  color: colorTokens['color/text/brand'],
  margin: 0,
};

export const h1 = {
  ...headingBase,
  fontWeight: 700,
  fontSize: 28,
  [mqs.md]: {
    fontSize: 28,
  },
} as const;

export const h2 = {
  ...headingBase,
  fontSize: 20,

  [mqs?.md]: {
    fontSize: 20,
  },
} as const;

export const h3 = {
  ...headingBase,
  fontSize: 17,
} as const;

export const h4 = {
  ...headingBase,
  fontSize: 16,

  [mqs?.md]: {
    fontSize: 16,
  },
} as const;
