export function waitForElement(selector: string, timeout = 5000): Promise<Element | undefined> {
  return new Promise((resolve) => {
    const element = document.querySelector(selector);
    if (element) {
      resolve(element);
      return;
    }

    const observer = new MutationObserver(() => {
      const observedElement = document.querySelector(selector);
      if (observedElement) {
        observer.disconnect();
        resolve(observedElement);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Fallback to disconnect observer after timeout
    setTimeout(() => {
      observer.disconnect();
      resolve(undefined)
    }, timeout);
  });
}
