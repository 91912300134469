export const formatPrice = (price: number | undefined | 0): string => {
  if (price === 0) {
    return '0:-';
  }
  if (!price) {
    return '';
  }
  // eslint-disable-next-line prefer-const
  let [integer, decimals] = price.toFixed(2).split('.');

  if (decimals === '00') {
    decimals = '-';
  }

  return `${integer}:${decimals}`;
};

export const formatPriceAriaLabel = (price: number | undefined | 0): string | undefined => {
  if (price === 0 || !price) {
    return undefined;
  }

  // eslint-disable-next-line prefer-const
  let [integer, decimals] = price.toFixed(2).split('.');

  if (decimals === '00') {
    decimals = '';
  } else {
    decimals = `och ${decimals} öre`;
  }

  return `${integer} kronor ${decimals}`;
};
