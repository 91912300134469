import React from 'react';
import Skeleton from '@mui/material/Skeleton';

type GdprConsentSkeletonProps = {
  id?: string;
};

export const GdprConsentSkeleton: React.FC<GdprConsentSkeletonProps> = ({ id }) => (
  <Skeleton
    data-testid={id ? `GDPR.CONSENT.SKELETON.${id}` : 'GDPR.CONSENT.SKELETON'}
    variant="rectangular"
    style={{ height: 300, width: '100%', marginBottom: 12, borderRadius: 2 }}
  />
);
