import type { ThemeOptions } from '@mui/material/styles';

const MuiSkeleton: NonNullable<ThemeOptions['components']>['MuiSkeleton'] = {
  styleOverrides: {
    text: {
      transform: 'scale(1, 0.80)',
    },
  },
};
export default MuiSkeleton;
