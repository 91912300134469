import React from 'react';
import { IconButton, SvgIcon, type SxProps } from '@mui/material';
import { useIntl } from 'react-intl';
import CloseIcon from '@aph/icons/close.svg';

export type PanelCloseButtonProps = {
  sx?: SxProps;
  onClickHandler?: () => void;
};

export const PanelCloseButton: React.FC<PanelCloseButtonProps> = ({ sx, onClickHandler }) => {
  const intl = useIntl();
  const buttonTitle = intl.messages['PANELS.CLOSE_BUTTON.TITLE'].toString();

  return (
    <IconButton
      onClick={onClickHandler}
      sx={sx}
      title={buttonTitle}
      data-testid="PANEL.CLOSE_BUTTON"
    >
      <SvgIcon color="primary">
        <CloseIcon />
      </SvgIcon>
    </IconButton>
  );
};
