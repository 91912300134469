import React from 'react';
import { Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { AphDrawer } from '@aph/components/common';
import type { AphDrawerProps } from '@aph/components/common';

type DrawerFromBottomOrModalProps = {
  width?: number;
};

export const DrawerFromBottomOrModal: React.FC<AphDrawerProps & DrawerFromBottomOrModalProps> = ({
  isPanelActive,
  onCloseHandler,
  children,
  width = 736,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {isDesktop && (
        // desktop
        <Modal onClose={onCloseHandler} open={isPanelActive} data-testid="modal">
          <Paper
            elevation={3}
            style={{
              width: '100%',
              maxWidth: width,
              height: 'auto',
              minHeight: '230px',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%,-50%)',
              position: 'absolute',
              margin: 0,
              outline: 0,
              borderRadius: 24,
              overflow: 'auto',
            }}
          >
            {children}
          </Paper>
        </Modal>
      )}
      {!isDesktop && (
        // mobile
        <AphDrawer isPanelActive={isPanelActive} onCloseHandler={onCloseHandler}>
          {children}
        </AphDrawer>
      )}
    </>
  );
};
