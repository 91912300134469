import React from 'react';
import { cva } from 'class-variance-authority';
import { colorTokens } from '../../design-tokens/color-tokens';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';

const variations = cva('flex items-center justify-center', {
  variants: {
    size: {
      extraLarge: 'h-[48px] w-[48px]',
      large: 'h-[24px] w-[24px]',
      medium: 'h-[20px] w-[20px]',
      small: 'h-[16px] w-[16px]',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

const variants = {
  default: {
    accent: colorTokens['color/icon/brand'],
    background: colorTokens['color/icon/subtlest'],
  },
  inverted: {
    accent: colorTokens['color/icon/inverse'],
    background: colorTokens['color/icon/inverse-dark'],
  },
};

interface SpinnerProps
  extends React.ButtonHTMLAttributes<HTMLOrSVGElement>,
    CustomVariantProps<typeof variations> {
  variant?: 'default' | 'inverted';
}

/**
 *
 * @example
 * ```tsx
 * <Spinner size="medium" variant="inverted" />
 * ```
 *
 */
export const Spinner: React.FC<SpinnerProps> = ({ variant = 'default', size, className }) => (
  <svg
    className={cn(variations({ size }), 'animate-spin', className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12ZM3.54001 12C3.54001 16.672 7.328 20.46 12 20.46C16.672 20.46 20.46 16.672 20.46 12C20.46 7.328 16.672 3.53999 12 3.53999C7.328 3.53999 3.54001 7.328 3.54001 12Z"
      fill={variants[variant].background}
    />
    <path
      d="M22.23 11.9998C23.208 11.9998 24.013 11.2028 23.87 10.2358C23.726 9.2668 23.463 8.31681 23.087 7.40781C22.483 5.95181 21.6 4.6288 20.485 3.5148C19.371 2.3998 18.048 1.5168 16.592 0.912798C15.683 0.536798 14.733 0.273808 13.764 0.129808C12.797 -0.0131918 12 0.791808 12 1.76981C12 2.74781 12.801 3.5208 13.757 3.7238C14.262 3.8318 14.758 3.98481 15.238 4.18381C16.264 4.60881 17.197 5.23181 17.982 6.01781C18.768 6.80281 19.391 7.7358 19.816 8.7618C20.015 9.2418 20.168 9.7378 20.276 10.2428C20.479 11.1988 21.252 11.9998 22.23 11.9998Z"
      fill={variants[variant].accent}
    />
  </svg>
);
