import type { ThemeOptions } from '@mui/material/styles';

const MuiCheckbox: NonNullable<ThemeOptions['components']>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 5,
      color: theme.palette['color/text/action-dark'],
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    }),
  },
};

export default MuiCheckbox;
