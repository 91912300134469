import React from 'react';
import type { FC } from 'react';
import type { AlertProps } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { alertMappings } from './aph-alert-severity-variant-map';
import { AphAlert } from './aph-alert.component';

type AphAlertWithButtonProps = Pick<AlertProps, 'severity'> & {
  title?: React.ReactNode;
  button?: React.ReactNode;
  align?: 'center' | 'start';
};

const ALERT_VARIANT = 'standard';

export const AphAlertWithButton: FC<AphAlertWithButtonProps> = ({
  severity,
  title,
  button,
  align = 'center',
}) => {
  const alertSettings = alertMappings.get(severity).get(ALERT_VARIANT);

  return (
    <Stack alignItems={align} bgcolor={alertSettings.background} borderRadius={4} pb={2}>
      <AphAlert severity={severity} title={title} variant={ALERT_VARIANT} />
      <Box width={{ xs: '100%', sm: 'max-content' }} paddingX={5.5}>
        {button}
      </Box>
    </Stack>
  );
};
