import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';

const variations = cva(
  'font-ica-text leading-2xl font-regular text-base tracking-normal underline underline-offset-2 outline-2 outline-offset-2 hover:underline focus-visible:outline',
  {
    variants: {
      variant: {
        default:
          'text-default focus-visible:text-action-dark hover:text-action-dark active:text-action-darkest outline-default',
        inverse:
          'text-inverse hover:text-inverse-dark focus-visible:text-inverse-dark active:text-inverse-darkest outline-inverse',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

interface LinkProps
  extends React.LinkHTMLAttributes<HTMLAnchorElement>,
  CustomVariantProps<typeof variations> {
  children?: React.ReactNode;
  asChild?: boolean;
}

/**
 *
 * @example
 * ```tsx
 * <Link href="https://apotekhjartat.se" tabIndex={0}>
 *  Text link
 * </Link>
 * ```
 *
 */
export const Link: React.FC<LinkProps> = ({ children, asChild, variant, className, ...rest }) => {
  const Comp = asChild ? Slot : 'a';

  return (
    <Comp className={cn(variations({ variant }), className)} {...rest}>
      {children}
    </Comp>
  );
};
