import React from 'react';
import { cn } from '@aph/ui/tailwind/cn';

export const SubmitButtonWithStatesSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => (
  <div className={cn('flex w-full flex-col items-center justify-start', className)}>
    <div className="bg-action-subtle h-6 w-[240px] max-w-full animate-pulse rounded-lg" />
  </div>
);
