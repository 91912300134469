import type { CSSInterpolation } from 'tss-react';
import { GlobalStyles } from 'tss-react';

import { baseStyles } from './base';

const overrides = {
  '.wp-exclude-emoji.needsclick[class*="player"]': {
    zIndex: '500 !important',
  },
};

const base = {
  ...baseStyles,
  ...overrides,
} as CSSInterpolation;

const GlobalStylesComponent = () => <GlobalStyles styles={base} />;

export default GlobalStylesComponent;
