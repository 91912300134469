import React from 'react';
import { cva } from 'class-variance-authority';
import type { EventFor } from '@aph/utilities/event-for';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import { Icon, type IconName } from '../icon/icon';
import { Typography } from '../typography/typography';

const variations = cva(
  [
    'group-disabled:bg-action-subtle group-aria-disabled:bg-action-subtle child:group-aria-disabled:fill-action-subtle child:group-disabled:fill-action-subtle relative flex items-center justify-center rounded-full',
  ],
  {
    variants: {
      variant: {
        primary: `bg-action child:fill-inverse hover:bg-action-dark active:bg-action-darkest`,
        secondary: `bg-action-subtle child:fill-action child-hover:fill-action-dark active:text-action-darkest child-active:fill-action-darkest`,
        white: `bg-action-inverse child:fill-action child-hover:fill-action-dark active:text-action-darkest child-active:fill-action-darkest group-aria-disabled:bg-action-inverse group-disabled:bg-action-inverse group-disabled:opacity-50 group-aria-disabled:opacity-50`,
      },
      size: {
        extraLarge: 'size-[48px]',
        large: 'size-[40px]',
        medium: 'size-[32px]',
        small: 'size-[24px]',
        dynamic: 'size-[--ButtonCircle-size]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'large',
    },
  },
);

interface ButtonPropsWithText extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string; // When text is provided, aria-label is not required
  'aria-label'?: string;
}

interface ButtonPropsWithoutText extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: never; // When text is not provided, aria-label is required
  'aria-label': string;
}

type AccessibleButtonProps = ButtonPropsWithText | ButtonPropsWithoutText;

export type ButtonCircleProps = {
  icon: IconName;
  badge?: React.ReactNode;
} & CustomVariantProps<typeof variations> &
  AccessibleButtonProps;

/**
 *
 * @example
 * ```tsx
 * <ButtonCircle variant="variant-property" icon="Plus" text="Click me">
 *  Hello World!
 * </ButtonCircle>
 * ```
 *
 */
export const ButtonCircle = React.forwardRef<HTMLButtonElement, ButtonCircleProps>(
  ({ icon, text, badge, variant, className, size = 'large', onClick, ...props }, forwardedRef) => {
    const isEmptyAriaLabel = props['aria-label'] === '' ? true : undefined;

    const handleOnClick = (event: EventFor<'button', 'onClick'>) => {
      if (props['aria-disabled'] === true) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        onClick?.(event);
      }
    };

    return (
      <button
        type="button"
        className="outline-action group flex flex-col items-center rounded-sm no-underline outline-2 outline-offset-2 focus-visible:outline aria-disabled:cursor-default"
        aria-hidden={isEmptyAriaLabel}
        onClick={handleOnClick}
        ref={forwardedRef}
        {...props}
      >
        <div className={cn(variations({ variant, size }), className)}>
          <Icon name={icon} size={size} className="child" />
          {badge}
        </div>
        {text && (
          <Typography typography="footnote" className="mt-[2px] text-center">
            {text}
          </Typography>
        )}
      </button>
    );
  },
);
