import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { NoSsr } from '@mui/material';
import { encode } from 'querystring';
import { sendLoginEvent } from '~/components/gtm/events/login-gtm';
import { useAuthState } from '~/contexts/auth/auth.provider';
import { useGtm } from './useGtm';

const LoginTracking: React.FC = () => {
  const { authenticated } = useAuthState();
  const router = useRouter();

  const removeLoginQueryParams = () => {
    const { pathname, query } = router;

    const parsedQuery = encode(query);
    const params = new URLSearchParams(parsedQuery);

    params.delete('loginOrigin');
    params.delete('?loginOrigin');
    params.delete('&loginOrigin');
    params.delete('anonymousId');

    router.replace(
      { pathname, query: decodeURIComponent(params.toString()).replace('?', '') },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (authenticated !== null) {
      const loginOrigin =
        router.query.loginOrigin || router.query['?loginOrigin'] || router.query['&loginOrigin'];
      if (loginOrigin) {
        sendLoginEvent(loginOrigin.toString());
        removeLoginQueryParams();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return null;
};

export const GtmRouteChange: React.FC = React.memo(
  () => {
    const { sendPageView } = useGtm();
    const Router = useRouter();

    useEffect(() => {
      const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
        if (shallow) return;
        sendPageView(url);
      };

      Router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [Router.events, sendPageView]);

    useEffect(() => {
      if (window) {
        sendPageView(window.location.pathname);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <NoSsr>
        <LoginTracking />
      </NoSsr>
    );
  },
  () => true,
);
