import type { ThemeOptions } from '@mui/material/styles';
import { normalBold } from '../elements/text';
import * as mqs from '../media-queries';

export const MuiAlert: NonNullable<ThemeOptions['components']>['MuiAlert'] = {
  styleOverrides: {
    root: {
      color: 'inherit',
      width: '100%',
      borderRadius: 12,
      padding: 12,
      [mqs.sm]: {
        padding: 12,
      },
    },
    filledError: ({ theme }) => ({
      color: theme.palette['color/text/inverse'],
    }),

    icon: {
      height: 24,
      alignItems: 'center',
      boxSizing: 'content-box',
    },
  },
};
export const MuiAlertTitle: NonNullable<ThemeOptions['components']>['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      ...normalBold,
      color: 'inherit',
      marginBottom: '0px',
      marginTop: '-1px',
    },
  },
};
