import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Alert } from '@aph/ui/components/alert/alert';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { cn } from '@aph/ui/tailwind/cn';

export type SubmitButtonWithStatesProps = {
  className?: string;
  buttonText: string;
  submitting: boolean | null;
  success: boolean | null;
  successButtonText: string;
  afterSuccessAction?: () => void;
  afterSuccessActionDelay?: number;
  error: { message: string } | null;
};

const propsAreEqual = (
  prevProps: Readonly<React.PropsWithChildren<SubmitButtonWithStatesProps>>,
  nextProps: Readonly<React.PropsWithChildren<SubmitButtonWithStatesProps>>,
) =>
  prevProps.submitting === nextProps.submitting &&
  prevProps.success === nextProps.success &&
  prevProps.error === nextProps.error;

export const SubmitButtonWithStates: React.FC<SubmitButtonWithStatesProps> = React.memo(
  ({
    className,
    buttonText,
    submitting,
    success,
    successButtonText,
    afterSuccessAction,
    afterSuccessActionDelay = process.env
      .NEXT_PUBLIC_DELAY_AFTER_SUCCESSFUL_FORM_SUBMIT as unknown as number,
    error,
  }) => {
    const isDefault = !submitting && !success;
    const isSubmitting = !!submitting;
    const hasSuccess = !!success;
    const hasError = !!error;
    const buttonShouldBeDisabled = !!submitting || !!success;

    const buttonClasses = {
      button: 'relative min-w-[240px] max-w-full min-h-[40px]',
      buttonError: 'mt-2',
      buttonDisabled: 'bg-action-dark text-inverse-dark shadow-none opacity-100',
    };

    const {
      ref: alertRef,
      inView,
      entry,
    } = useInView({
      threshold: 1,
      triggerOnce: true,
    });

    useEffect(() => {
      if (hasSuccess && afterSuccessAction) {
        setTimeout(() => {
          afterSuccessAction();
        }, afterSuccessActionDelay);
      }
    }, [afterSuccessAction, afterSuccessActionDelay, hasSuccess]);

    useEffect(() => {
      if (hasError && entry?.target && !inView) {
        entry.target.scrollIntoView();
      }
    }, [hasError]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className={cn('flex w-full flex-col items-center justify-start gap-2', className)}>
        <Button
          key="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          id="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          type="submit"
          className={cn(
            buttonClasses.button,
            hasError && buttonClasses.buttonError,
            buttonShouldBeDisabled && buttonClasses.buttonDisabled,
          )}
          data-testid="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          disabled={buttonShouldBeDisabled}
          isLoading={isSubmitting}
        >
          {isDefault && buttonText}
          {hasSuccess && (
            <>
              {successButtonText}
              <Icon width={20} name="Tick" className="absolute right-2" fill="fill-inverse-dark" />
            </>
          )}
        </Button>
        {hasError && (
          <div ref={alertRef} className="w-full max-w-[521px]">
            <Alert
              variant="error"
              isDismissible={false}
              description={error && error.message}
              data-testid="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.ALERT"
            />
          </div>
        )}
      </div>
    );
  },
  propsAreEqual,
);
