import { getFeatureToggles } from './feature-toggle.api';
import type { FeatureToggleState } from './FeatureToggleState';
import { initialState } from './FeatureToggleState';

export function initializeState({
  setState,
  getState,
}: {
  setState: (prop: FeatureToggleState) => void;
  getState: () => FeatureToggleState;
}) {
  const initializedFeatureToggleState = { ...initialState, ...getState() };
  // has not been updated
  if (initializedFeatureToggleState.updated === 0) {
    // this might potentially be async in the future
    const featureToggles = getFeatureToggles();

    setState({
      ...initializedFeatureToggleState,
      featureToggles,
      updated: Date.now(),
      loading: false,
    });
  }
}
