// TODO: Missing test
// TOOD make component of a-tag:component
import type { ReactElement } from 'react';
import React from 'react';
import NextLink from 'next/link';
import type { SxProps } from '@mui/material';
import { Link as MuiLink } from '@mui/material';

type LinkProps = {
  href: string;
  title?: string;
  target?: string;
  sx?: SxProps;
  rel?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: ReactElement | any;
};

export const Link: React.FC<LinkProps> = React.memo(
  ({ href, target, rel, sx, title, children }) => {
    // TODO: Fix this because publicRunttimeCOnfig doesn't exisst anymore
    const internalRoute = false;
    if (internalRoute) {
      return (
        <NextLink href={href} prefetch={false} legacyBehavior>
          <MuiLink
            target={target}
            rel={rel || undefined}
            sx={{
              ...sx,
              color: (theme) => theme.palette['color/text/action-dark'],
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'none',
                cursor: 'pointer',
              },
            }}
            title={title || ''}
            href={href}
          >
            {children || null}
          </MuiLink>
        </NextLink>
      );
    }

    // TODO: Find out why we need to do it like this

    return (
      <MuiLink
        href={href}
        target={target}
        rel={rel}
        sx={{
          ...sx,
          color: (theme) => theme.palette['color/text/action-dark'],
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer',
          },
        }}
        title={title || ''}
      >
        {children || null}
      </MuiLink>
    );
  },
  () => true,
);
