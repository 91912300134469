export enum ChatEventTopic {
  ResetTeneo = 'aph_chat_ResetTeneoChat',
  OpenTeneo = 'aph_chat_openTeneoChat',
  MinimizedGiosg = 'aph_chat_minimizedGiosgChat',
  MaximizedGiosg = 'aph_chat_maximizedGiosgChat',
  LivechatEnded = 'aph_chat_livechatEnded',
  LivechatStarted = 'aph_chat_livechatStarted',
  MinimizedTeneo = 'aph_chat_minimizedTeneoChat',
  OpenGiosg = 'aph_chat_openGiosgChat',
  CloseContactUsModal = 'aph_chat_closeContactUsModal',
  OpenContactUsModal = 'aph_chat_openContactUsModal',
  ContinueWithLiveChat = 'twcStartLivechat',
  FetchTeneoChatHistory = 'aph_chat_fetchTeneoChatHistory',
  TwcInitialized = 'twcInitialized',
  TwcReady = 'twcReady',
  GiosgReady = 'giosg_is_ready',
  Play = 'play_chat_state',
  TeneoMinimize = 'twcMinimized',
  TeneoClosed = 'twcCloseButtonClicked',
  ConsentChanged = 'consent.onetrust',
}
