const values = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1200,
  xl: 1536,
  xxl: 1680,
  smMid: 512, // TODO: @Freddie vilka värden ska det stå här egentligen?
  xsMid: 768, // TODO: @Mattias eller så vet du kanske?
};

const sm = `@media (min-width: ${values.sm}px)`;
const smDown = `@media (max-width: ${values.sm - 1}px)`;
const md = `@media (min-width: ${values.md}px)`;
const mdDown = `@media (max-width: ${values.md - 1}px)`;
const lg = `@media (min-width: ${values.lg}px)`;
const lgDown = `@media (max-width: ${values.lg - 1}px)`;
const xl = `@media (min-width: ${values.xl}px)`;
const xlDown = `@media (max-width: ${values.xl - 1}px)`;
const mqs = {
  sm,
  smDown,
  md,
  mdDown,
  lg,
  lgDown,
  xl,
  xlDown,
};

const breakpoints = {
  values,
  mqs,
};

export default breakpoints;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
    xsMid: true;
    smMid: true;
  }
}
