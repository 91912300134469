type TeneoHistoryType = {
  author: string;
  type: string;
  data: { text?: string };
};

export const transformHistoryToGiosgChat = (history: TeneoHistoryType[]): string => {
  let parsedString = '';

  history.forEach((chatobj) => {
    if (chatobj.type !== 'text' || chatobj.data.text === undefined) return;
    switch (chatobj.author) {
      case 'user':
        parsedString = parsedString.concat('kund:', chatobj.data.text, '\n');
        break;
      default:
        break;
    }
  });

  if (parsedString === '') {
    parsedString = 'kund: Ingen chatthistorik';
  }
  
  return parsedString;
};
