import type { Level, LoggerOptions } from 'pino';

const levelMapping: { [level in Level]: string } = {
  fatal: 'Fatal',
  error: 'Error',
  warn: 'Warning',
  info: 'Information',
  debug: 'Debug',
  trace: 'Trace',
};

// These options are copy pasted to server.js, remember to change in server.js as well if you make a change here.
export const pinoOptions: LoggerOptions = {
  messageKey: 'message',
  timestamp: () => `,"@timestamp":"${new Date().toISOString()}"`,
  formatters: {
    level(label, _number) {
      return { level: levelMapping[label as Level] };
    },
    bindings(_bindings) {
      return {};
    },
  },
  mixin() {
    return { type: 'application-client-web' };
  },
};
