import { container } from 'tsyringe';
import { isServer } from '@aph/utilities/is-server';
import type { ICampaignsClient, ISearchClient } from '~/articles/generated/ArticlesClient';
import { CampaignsClient, SearchClient } from '~/articles/generated/ArticlesClient';
import type { IClient as ICartClientV3 } from '~/services/generated/CartClientV3';
import { Client as CartClientV3 } from '~/services/generated/CartClientV3';
import type { IClient as ICheckoutClientV2 } from '~/services/generated/CheckoutClientV2';
import { Client as CheckoutClientV2 } from '~/services/generated/CheckoutClientV2';
import type { IClient as ICustomerClient } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';
import type { IClient as IDispatcherClient } from '~/services/generated/DispatcherClient';
import { Client as DispatcherClient } from '~/services/generated/DispatcherClient';
import type { IClient as IOrderClient } from '~/services/generated/OrderClient';
import { Client as OrderClient } from '~/services/generated/OrderClient';
import type { IClient as IPharmacyClient } from '~/services/generated/PharmacyClient';
import { Client as PharmacyClient } from '~/services/generated/PharmacyClient';
import type { IClient as IPrescriptionEhmV4Client } from '~/services/generated/PrescriptionEhmV4Client';
import { Client as PrescriptionEhmV4Client } from '~/services/generated/PrescriptionEhmV4Client';
import type { IOffersClient } from '~/services/generated/PresentationClient';
import { OffersClient } from '~/services/generated/PresentationClient';
import type { IPresentationClient as IPresentationClientV0 } from '~/services/generated/ProductClientV2';
import { PresentationClient as PresentationClientV0 } from '~/services/generated/ProductClientV2';
import type {
  ICartsClient,
  ICheckoutClient,
  IOrdersClient,
} from '~/services/generated/PurchaseClient';
import { CartsClient, CheckoutClient, OrdersClient } from '~/services/generated/PurchaseClient';
import { StatusEventDispatcher } from '~/services/middleware/StatusEventDispatcher';

const isDevelopment = process.env.NODE_ENV === 'development';
const getFetch = () => (isServer() ? globalThis : undefined);

function getClientBaseUrl() {
  // the purpose of this is to allow us to (in development) use different api/client urls on the server vs. the client
  // this, in tandem with url rewrites in nextjs config, allow us to avoid CORS issues from localhost
  // so for example, with rewrites, we can have /api/ ... be proxied to https://example.com/api (thus avoiding CORS)
  // but on the server you can't use relative urls so there we must use the full url, https://example.com/api (and server side CORS is not applicable)
  if (isDevelopment && isServer() && process.env.SERVER_BASE_URL) {
    return process.env.SERVER_BASE_URL;
  }

  return process.env.NEXT_PUBLIC_BASE_URL;
}

// Http status code event listener dispatcher singleton
container.registerSingleton(StatusEventDispatcher);

// dispatcher client
container.register<IDispatcherClient>(DispatcherClient, {
  useFactory: () => new DispatcherClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// pharmacy client
container.register<IPharmacyClient>(PharmacyClient, {
  useFactory: () => new PharmacyClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// order clients
container.register<IOrderClient>(OrderClient, {
  useFactory: () => new OrderClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// cart clients
container.register<ICartClientV3>(CartClientV3, {
  useFactory: () => new CartClientV3(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// checkout clients
container.register<ICheckoutClientV2>(CheckoutClientV2, {
  useFactory: () => new CheckoutClientV2(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// prescription client
container.register<IPrescriptionEhmV4Client>(PrescriptionEhmV4Client, {
  useFactory: () => new PrescriptionEhmV4Client(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// presentation client
container.register<IPresentationClientV0>(PresentationClientV0, {
  useFactory: () => new PresentationClientV0(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// campaigns client
container.register<ICampaignsClient>(CampaignsClient, {
  useFactory: () => new CampaignsClient(getClientBaseUrl(), getFetch()),
});

// search client
container.register<ISearchClient>(SearchClient, {
  useFactory: () => new SearchClient(getClientBaseUrl(), getFetch()),
});

// carts client
container.register<ICartsClient>(CartsClient, {
  useFactory: () => new CartsClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// customer client
container.register<ICustomerClient>(CustomerClient, {
  useFactory: () => new CustomerClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// new checkout client
container.register<ICheckoutClient>(CheckoutClient, {
  useFactory: () => new CheckoutClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// new orders client
container.register<IOrdersClient>(OrdersClient, {
  useFactory: () => new OrdersClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

// personal offers client
container.register<IOffersClient>(OffersClient, {
  useFactory: () => new OffersClient(process.env.NEXT_PUBLIC_BASE_URL, getFetch()),
});

const statusEventDispatcherInstance = container.resolve(StatusEventDispatcher);
export { statusEventDispatcherInstance };
