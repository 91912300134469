import { createTheme } from '@mui/material/styles';
import { colorTokens } from '@aph/ui/design-tokens/color-tokens';
import { EXTERNAL_COMPANY_COLORS } from '@aph/ui/external-company-colors';
import type { ColorTokens, ExternalCompanyColors } from '@aph/ui/types';

const defaultTheme = createTheme();

declare module '@mui/material/styles' {
  interface Palette extends ColorTokens {
    externalCompanyColors: ExternalCompanyColors;
  }
}

export default {
  ...colorTokens,
  externalCompanyColors: EXTERNAL_COMPANY_COLORS,
  // mui
  primary: {
    light: colorTokens['color/text/inverse'],
    main: colorTokens['color/text/brand'],
    dark: colorTokens['color/text/action'],
    contrastText: colorTokens['color/text/inverse'],
  },
  secondary: {
    light: colorTokens['color/text/inverse'],
    main: colorTokens['color/text/brand'],
    dark: colorTokens['color/text/brand'],
    contrastText: colorTokens['color/text/brand'],
  },
  error: {
    light: colorTokens['color/text/error'],
    main: colorTokens['color/text/error'],
    dark: colorTokens['color/text/error'],
    contrastText: colorTokens['color/text/inverse'],
  },

  // Imported from component lib
  text: {
    primary: colorTokens['color/text/default'],
    secondary: colorTokens['color/text/subtle'],
  },

  primaryButton: defaultTheme.palette.augmentColor({
    color: {
      main: colorTokens['color/text/action-dark'],
      contrastText: colorTokens['color/text/inverse'],
    },
  }),

  attention: {
    background: { default: colorTokens['color/background/visual'] },
    text: { primary: colorTokens['color/text/brand'] },
  },

  background: {
    default: colorTokens['color/background/default'],
    dark: 'rgba(0,0,0,.55)',
  },

  tonalOffset: 0.1,
  // aph
  border: {
    main: colorTokens['color/border/default'],
  },
  price: {
    primary: colorTokens['color/text/brand'],
  },
};
