import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { GiosgState } from './giosg-state';
import { initialState } from './giosg-state';
import { getToken } from './giosg.api';

interface GiosgStore {
  giosgState: GiosgState;
  actions: {
    getToken: () => void;
    resetToken: () => void;
  };
}

export const useGiosgStore = create<GiosgStore>()(
  devtools<GiosgStore>(
    (set) => ({
      giosgState: initialState,
      actions: {
        getToken: async () => {
          set(
            {
              giosgState: {
                ...initialState,
                loading: true,
                updated: Date.now(),
              },
            },
            false,
            'Fetching token',
          );
          try {
            const payload = await getToken();
            set(
              {
                giosgState: {
                  ...initialState,
                  token: payload.token || initialState.token,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching token success',
            );
          } catch (event) {
            const { userMessage } = event as { userMessage: string };

            set(
              {
                giosgState: {
                  ...initialState,
                  error: true,
                  errorMessage: userMessage,
                },
              },
              false,
              'Fetching token failed',
            );
          }
        },
        resetToken: () => {
          set(() => ({
            giosgState: initialState,
          }));
        },
      },
    }),
    {
      name: 'UseGiosg',
      anonymousActionType: 'UseGiosg',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useGiosgActions = () => useGiosgStore((state) => state.actions);
