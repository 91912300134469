import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import { DrawerFromBottomOrModal } from '../drawers/drawer-from-bottom-or-modal.component';

type ConfirmLoggedInSessionProps = {
  onConfirm: () => void;
  onLogOut: () => void;
  isModalOpen: boolean;
};

export const ConfirmLoggedInSession: React.FC<ConfirmLoggedInSessionProps> = ({
  onConfirm,
  onLogOut,
  isModalOpen,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <DrawerFromBottomOrModal isPanelActive={isModalOpen} onCloseHandler={() => { }} width={928}>
      <Stack paddingX={{ xs: 2, md: 4 }} paddingTop={6} paddingBottom={5} gap={6}>
        <Stack gap={1}>
          <Typography typography="headingExtraLarge" color="text-brand" asChild>
            <h1>
              <FormattedMessage id="COMMON.LOG_IN_SESSION.MODAL.HEADER" />
            </h1>
          </Typography>
          <Typography>
            <FormattedMessage id="COMMON.LOG_IN_SESSION.MODAL.TEXT" />
          </Typography>
        </Stack>
        <Stack gap={1} direction={{ md: 'row-reverse' }}>
          <Button onClick={onConfirm}>
            <FormattedMessage id="COMMON.LOG_IN_SESSION.MODAL.BUTTON.CONFIRM" />
          </Button>
          <Button variant="secondary" onClick={onLogOut}>
            <FormattedMessage id="COMMON.LOG_IN_SESSION.MODAL.BUTTON.LOG_OUT" />
          </Button>
        </Stack>
      </Stack>
    </DrawerFromBottomOrModal>
  );
};
