import type React from 'react';
import { useEffect } from 'react';

import { useAuthState } from '~/contexts/auth/auth.provider';
import type { RedirectProps } from '../../../utilities/redirect-to-external-url';
import { redirectToExternalUrl } from '../../../utilities/redirect-to-external-url';

export const AuthRedirect: React.FC<RedirectProps> = ({ redirectToExternal }) => {
  const { authenticated } = useAuthState();

  useEffect(() => {
    if (authenticated === false && window) {
      redirectToExternalUrl({ redirectToExternal });
    }
  }, [authenticated, redirectToExternal]);

  return null;
};
