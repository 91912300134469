import type { ThemeOptions } from '@mui/material/styles';
import * as mqs from '../media-queries';

const MuiGrid: NonNullable<ThemeOptions['components']>['MuiGrid'] = {
  styleOverrides: {
    root: {
      gap: 8,
      [mqs.xl]: {
        gap: 16,
      },
    },
  },
};

export default MuiGrid;
