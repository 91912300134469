import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { colorTokens } from '@aph/ui/design-tokens/color-tokens';
import { ICAHeading, ICAText } from '../fonts';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    footnote: React.CSSProperties;
    fontWeightHeavy: React.CSSProperties['fontWeight'];
    normal: React.CSSProperties;
    price: React.CSSProperties;
    clubPrice: React.CSSProperties;
    campaignPrice: React.CSSProperties;
    link: React.CSSProperties;
    subheading: React.CSSProperties;
    small: React.CSSProperties;
    header: React.CSSProperties;
    normalBold: React.CSSProperties;
    large: React.CSSProperties;
    largeBold: React.CSSProperties;
    medium: React.CSSProperties;
    mediumBold: React.CSSProperties;
    smallBold: React.CSSProperties;
    xs: React.CSSProperties;
    xsBold: React.CSSProperties;
    bodyHeavy: React.CSSProperties;
    body3: React.CSSProperties;
    bodyv2: React.CSSProperties;
    bodyBig: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    footnote?: React.CSSProperties;
    fontWeightHeavy?: React.CSSProperties['fontWeight'];
    normal?: React.CSSProperties;
    price?: React.CSSProperties;
    clubPrice?: React.CSSProperties;
    campaignPrice?: React.CSSProperties;
    link?: React.CSSProperties;
    subheading: React.CSSProperties;
    header?: React.CSSProperties;
    normalBold?: React.CSSProperties;
    large?: React.CSSProperties;
    largeBold?: React.CSSProperties;
    medium?: React.CSSProperties;
    mediumBold?: React.CSSProperties;
    small?: React.CSSProperties;
    smallBold?: React.CSSProperties;
    xs?: React.CSSProperties;
    xsBold?: React.CSSProperties;
    bodyHeavy?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true; // <Typography typography="headingExtraLarge" color="text-brand">
    h2: true; // <Typography typography="headingLarge" color="text-brand">
    h3: true; // <Typography typography="headingMedium">
    h4: true; // <Typography typography="headingSmall">
    body1: true; // <Typography>
    footnote: true; // <Typography typography="footnote">
    price: true;
    normal: true; // <Typography>
    normalBold: true; // <Typography className="font-bold">
    large: true; // <Typography typography="headingExtraLarge"> if h1, otherwise <Typography typography="headingLarge">
    largeBold: true; // Same as above
    headingBold: true; // Same as above
    medium: true; // <Typography typography="bodyBig">
    mediumBold: true; // <Typography typography="bodyBig" className="font-bold">
    small: true; // <Typography typography="footnote"> - - Victor said that we should be cautions with this one. So perhaps use subHeading?
    smallBold: true; // Not sure if we should make footnote bold, let's check!
    xs: true; // Same as above
    xsBold: true; // Same as above
    normalBonus: true;
    largeBonus: true;
    header: true;
    subtitle1: true; // Will be solved  by Maria
    subtitle2: true; // Will be solved  by Maria
    fontWeightHeavy: true; // <Typography className="font-black">
    subheading: true;
    bodyHeavy: true; // <Typography className="font-black">
    body3: true; // Will be solved  by Maria
    bodyv2: true; // Will be solved  by Maria
    headline: true;
    bodyBig: true; // <Typography variant="bodyBig">

    // this is how we remove variants for typescript
    overline: false;
    caption: false;
    body2: false;
    inherit: false;
    button: false;
    h5: false;
    h6: false;
  }
}

const body1 = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
};

// Design System Typography (should align with what we have in figma)
const typography: TypographyOptions = {
  fontFamily: ICAText,
  body1,
  fontWeightBold: 700,
  fontWeightHeavy: 800,

  normal: {
    color: colorTokens['color/text/brand'],
    fontFamily: ICAText,
  },
  button: {
    fontFamily: ICAText,
    fontWeight: 700,
    textTransform: 'none',
  },
  price: {
    fontFamily: ICAHeading,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 'normal',
    color: colorTokens['color/text/default'],
  },

  clubPrice: {
    fontFamily: ICAHeading,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 'normal',
    color: colorTokens['color/text/brand'],
  },
  small: {
    fontFamily: ICAText,
    lineHeight: 1,
    fontWeight: 400,
    fontSize: 12,
  },
  header: {
    fontFamily: ICAText,
    lineHeight: 1,
    fontSize: 16,
    color: colorTokens['color/text/default'],
  },
  caption: {
    fontSize: 12,
    lineHeight: 'normal',
    fontStyle: 'italic',
    color: colorTokens['color/text/default'],
  },

  link: {
    fontSize: 14,
    color: colorTokens['color/text/action-dark'],
    fontFamily: ICAText,
    // eslint-disable-next-line
    // @ts-ignore
    ':hover': {
      textDecoration: 'underline',
    },
  },

  subheading: {
    fontFamily: ICAText,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },

  footnote: {
    fontFamily: ICAText,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '19px',
  },

  h1: {
    fontFamily: ICAHeading,
    fontWeight: 700,
    fontSize: 28,
    color: colorTokens['color/text/brand'],
  },

  h2: {
    fontFamily: ICAHeading,
    fontWeight: 700,
    fontSize: 20,
    color: colorTokens['color/text/brand'],
  },

  h3: {
    fontFamily: ICAText,
    fontWeight: 800,
    fontSize: 18,
    fontStyle: 'normal',
    lineHeight: '25px',
  },

  h4: {
    fontFamily: ICAText,
    fontWeight: 800,
    fontSize: '1rem',
    fontStyle: 'normal',
    lineHeight: '25px',

    // unsure if we should set color like this (shouldn't this be the default color on body)
    color: colorTokens['color/text/brand'],
  },
  // removes variants from the theme
  h6: undefined,
  bodyHeavy: {
    fontFamily: ICAText,
    fontSize: 16,
    fontWeight: 800,
    fontStyle: 'normal',
    lineHeight: '20px',
    color: colorTokens['color/text/default'],
  },
  body3: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '25.41px',
  },
  bodyv2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '20px',
  },
  headline: {
    color: colorTokens['color/text/default'],
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '21px',
  },
  bodyBig: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '24.15px',
    letterSpacing: '-0.2px',
  },
};

export default typography;
