import { CreateHistoryTokenRequestV4 } from '~/services/generated/CustomerClient';
import { createHistoryToken } from './api/create-history-token.api';
import { transformHistoryToGiosgChat } from './utils/chat-robot-history-parser';

export const getHasAcceptedOneTrustByCategoryId = (groupId: string) => {
  if (window.OptanonActiveGroups) {
    return window.OptanonActiveGroups.indexOf(groupId) !== -1;
  }
  return false;
};

export const showCookies = () => {
  if (window && window.Optanon) {
    setTimeout(() => {
      window.Optanon.ToggleInfoDisplay();
    }, 500);
  }
};

export const openTeneo = () => {
  window.TeneoWebChat.call('maximize');
};

export const resetTeneo = (onCompleted?: () => void) => {
  window.TeneoWebChat.call('reset');

  if (onCompleted !== undefined) {
    onCompleted();
  }
};

export const openGiosg = (getToken: () => void) => {
  if (typeof window.GiosgClient !== 'undefined') {
    window.GiosgClient.openChatDialog();
    getToken();
  }
};

export const fetchTeneoChatHistory = async (onCompleted?: () => void) => {
  const history = window.TeneoWebChat.get('chat_history');
  const transformedHistory = transformHistoryToGiosgChat(history);
  createAndSubmitGiosgHistoryToken(transformedHistory).then(() => {
    if (onCompleted !== undefined) {
      onCompleted();
    }
  });
};

const createAndSubmitGiosgHistoryToken = async (history: string) => {
  const body = new CreateHistoryTokenRequestV4();
  try {
    body.history = history;
    const result = await createHistoryToken(body);

    if (result && result.token && typeof window._giosg !== 'undefined') {
      const algorithm = 'HS256';
      window._giosg('visitor', 'submit', result.token, algorithm);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error occured:', e);
  }
};
