import type { ThemeOptions } from '@mui/material/styles';

const MuiCollapse: NonNullable<ThemeOptions['components']>['MuiCollapse'] = {
  styleOverrides: {
    // MuiCollapse-entered Styles applied to the container element-
    // when the transition has entered.
    entered: {},
    // MuiCollapse-hidden Styles applied to the container element-
    // when the transition has exited and collapsedHeight != 0px.
    hidden: {},
    // MuiCollapse-wrapper Styles applied to the outer wrapper element.
    wrapper: {},
    // MuiCollapse-wrapperInner Styles applied to the inner wrapper element.
    wrapperInner: {},
  },
};

export default MuiCollapse;
