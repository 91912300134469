import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/system';
import { useGiosgActions } from '~/model/giosg/useGiosgStore';
import theme from '~/styles/theme';
import { ChatEventTopic } from './chat-event-topic';
import { VisibleModal } from './chat-store/chat-state';
import { useChatActions } from './chat-store/use-chat-store';
import { ContactUsFab } from './contact-us-fab/contact-us-fab.component';
import { ContactUsWidget } from './contact-us-widget/contact-us-widget.component';
import {
  fetchTeneoChatHistory,
  getHasAcceptedOneTrustByCategoryId,
  openGiosg,
  openTeneo,
  resetTeneo,
  showCookies,
} from './event-handlers';
import { useChatMediator } from './use-chat-mediator';
import { constants } from './utils/constants';

export const ChatContainer: React.FC = () => {
  // ChatMediator
  const { subscribe, unsubscribe, notify, initializeListeners } = useChatMediator();
  const { getToken } = useGiosgActions();

  // ChatStore
  const {
    updateCurrentChatStateModal,
    setIsGiosgReady,
    setIsTeneoReady,
    setIsMaximized,
    getIsMaximized,
    setIsLivechatEnded,
    getIsLivechatEnded,
    getCurrentModal,
    getIsGiosgAndTeneoReady,
    resetState,
    setIsFunctionalCookiesAccepted,
    getIsFunctionalCookiesAccepted,
  } = useChatActions();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();

  useEffect(() => {
    setIsGiosgReady(false);
    setIsTeneoReady(false);
    initializeListeners();
    subscribe(ChatEventTopic.TwcInitialized, onTwcInitialized);
    subscribe(ChatEventTopic.TwcReady, onTwcReady);
    subscribe(ChatEventTopic.GiosgReady, onGiosgReady);
    subscribe(ChatEventTopic.Play, onPlay);
    subscribe(ChatEventTopic.OpenContactUsModal, onOpenContactUsModal);
    subscribe(ChatEventTopic.CloseContactUsModal, onCloseContactUsModal);
    subscribe(ChatEventTopic.OpenTeneo, onOpenTeneo);
    subscribe(ChatEventTopic.MinimizedTeneo, onMinimizedTeneo);
    subscribe(ChatEventTopic.ContinueWithLiveChat, onContinueWithLiveChat);
    subscribe(ChatEventTopic.FetchTeneoChatHistory, onFetchTeneoChatHistory);
    subscribe(ChatEventTopic.ResetTeneo, onResetTeneo);
    subscribe(ChatEventTopic.OpenGiosg, onOpenGiosg);
    subscribe(ChatEventTopic.MaximizedGiosg, onMaximizedGiosg);
    subscribe(ChatEventTopic.MinimizedGiosg, onMinimizedGiosg);
    subscribe(ChatEventTopic.TeneoClosed, onTeneoClosed);
    subscribe(ChatEventTopic.LivechatEnded, onLivechatEnded);
    subscribe(ChatEventTopic.LivechatStarted, onLivechatStarted);
    subscribe(ChatEventTopic.ConsentChanged, onConsentChanged);

    return () => {
      setIsGiosgReady(false);
      setIsTeneoReady(false);
      initializeListeners();
      unsubscribe(ChatEventTopic.TwcInitialized, onTwcInitialized);
      unsubscribe(ChatEventTopic.TwcReady, onTwcReady);
      unsubscribe(ChatEventTopic.GiosgReady, onGiosgReady);
      unsubscribe(ChatEventTopic.Play, onPlay);
      unsubscribe(ChatEventTopic.OpenContactUsModal, onOpenContactUsModal);
      unsubscribe(ChatEventTopic.CloseContactUsModal, onCloseContactUsModal);
      unsubscribe(ChatEventTopic.OpenTeneo, onOpenTeneo);
      unsubscribe(ChatEventTopic.MinimizedTeneo, onMinimizedTeneo);
      unsubscribe(ChatEventTopic.ContinueWithLiveChat, onContinueWithLiveChat);
      unsubscribe(ChatEventTopic.FetchTeneoChatHistory, onFetchTeneoChatHistory);
      unsubscribe(ChatEventTopic.ResetTeneo, onResetTeneo);
      unsubscribe(ChatEventTopic.OpenGiosg, onOpenGiosg);
      unsubscribe(ChatEventTopic.MaximizedGiosg, onMaximizedGiosg);
      unsubscribe(ChatEventTopic.MinimizedGiosg, onMinimizedGiosg);
      unsubscribe(ChatEventTopic.TeneoClosed, onTeneoClosed);
      unsubscribe(ChatEventTopic.LivechatEnded, onLivechatEnded);
      unsubscribe(ChatEventTopic.LivechatStarted, onLivechatStarted);
      unsubscribe(ChatEventTopic.ConsentChanged, onConsentChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTwcInitialized = () => {
    notify(ChatEventTopic.TwcReady);
  };

  const onTwcReady = () => {
    setIsTeneoReady(true);
    notify(ChatEventTopic.Play);
  };

  const onGiosgReady = () => {
    setIsGiosgReady(true);
    notify(ChatEventTopic.Play);
  };

  const onPlay = () => {
    if (getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID)) {
      if (getIsGiosgAndTeneoReady() && getIsMaximized()) {
        switch (getCurrentModal()) {
          case VisibleModal.ContactUsModal:
            notify(ChatEventTopic.OpenContactUsModal);
            break;
          case VisibleModal.GiosgChat:
            notify(ChatEventTopic.OpenGiosg);
            break;
          case VisibleModal.TeneoChat:
            notify(ChatEventTopic.OpenTeneo);
            break;
          default:
            break;
        }
      }
    } else if (
      !getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID) &&
      getCurrentModal() === VisibleModal.ContactUsModal &&
      getIsMaximized()
    ) {
      notify(ChatEventTopic.OpenContactUsModal);
    }
  };

  const toggleWidget = () => {
    setIsMaximized(!getIsMaximized());
    notify(ChatEventTopic.Play);
  };

  const onOpenContactUsModal = () => {
    updateCurrentChatStateModal(VisibleModal.ContactUsModal);
  };

  const onCloseContactUsModal = () => {
    setIsMaximized(false);
  };

  const onOpenTeneo = () => {
    updateCurrentChatStateModal(VisibleModal.TeneoChat);
    openTeneo();
  };

  const onResetTeneo = (onCompleted?: () => void) => {
    resetTeneo(onCompleted);
  };

  const onFetchTeneoChatHistory = (onCompleted?: () => void) => {
    fetchTeneoChatHistory(onCompleted);
  };

  const onConsentChanged = () => {
    const currentIsFunctionalCookiesAccepted = getIsFunctionalCookiesAccepted();
    const isFunctionalCookiesAccepted = getHasAcceptedOneTrustByCategoryId(
      constants.FUNCTIONAL_COOKIE_ID,
    );

    if (currentIsFunctionalCookiesAccepted !== isFunctionalCookiesAccepted) {
      setIsFunctionalCookiesAccepted(isFunctionalCookiesAccepted);
    }

    if (
      !isFunctionalCookiesAccepted &&
      isFunctionalCookiesAccepted !== currentIsFunctionalCookiesAccepted
    ) {
      resetState();
      router.reload();
    }
  };

  const onMinimizedGiosg = () => {
    if (getIsLivechatEnded()) {
      updateCurrentChatStateModal(VisibleModal.ContactUsModal);
    }
    setIsMaximized(false);
  };

  const onMaximizedGiosg = () => {
    setIsMaximized(true);
  };

  const onMinimizedTeneo = () => {
    setIsMaximized(false);
  };

  const onTeneoClosed = () => {
    updateCurrentChatStateModal(VisibleModal.ContactUsModal);
    setIsMaximized(false);
  };

  const onOpenGiosg = () => {
    updateCurrentChatStateModal(VisibleModal.GiosgChat);
    openGiosg(getToken);
  };

  const onContinueWithLiveChat = async () => {
    window.dataLayer.push({
      event: 'chat_live',
      contact_subject: '',
    });
    notify(ChatEventTopic.FetchTeneoChatHistory, async () => {
      notify(ChatEventTopic.ResetTeneo);
      notify(ChatEventTopic.OpenGiosg);
    });
  };

  const startChat = () => {
    notify(ChatEventTopic.CloseContactUsModal);
    notify(ChatEventTopic.OpenTeneo);
  };

  const onLivechatEnded = () => {
    setIsLivechatEnded(true);
  };

  const onLivechatStarted = () => {
    setIsLivechatEnded(false);
  };

  return (
    <>
      <ContactUsWidget
        onToggle={toggleWidget}
        onStartChat={startChat}
        isMobile={isMobile}
        isOpen={getIsMaximized() && getCurrentModal() === VisibleModal.ContactUsModal}
        hasAcceptedCookies={getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID)}
        onCookiesClick={showCookies}
      />
      <ContactUsFab onToggle={toggleWidget} isContactUsButtonVisible={getIsMaximized()} />
    </>
  );
};
