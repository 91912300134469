import type { ThemeOptions } from '@mui/material/styles';

const MuiFab: NonNullable<ThemeOptions['components']>['MuiFab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette['color/text/subtle'],
      backgroundColor: theme.palette['color/background/elevated'] || 'inherit',
      minHeight: 24,
      minWidth: 24,
      height: 24,
      width: 24,
      boxShadow: 'none',
      fontSize: '14px',
      '&:active': {
        boxShadow: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette['color/background/elevated'],
        '@media (hover: hover)': {
          backgroundColor: theme.palette['color/background/action'],
        },
      },
    }),

    /* Styles applied to the root element if `color="primary"`. */
    primary: ({ theme }) => ({
      color: theme.palette['color/text/subtle'],
      backgroundColor: theme.palette['color/background/default'],
      '&:hover': {
        backgroundColor: theme.palette['color/background/action'],
      },
      '&:disabled': {
        backgroundColor: theme.palette['color/background/default'],
      },
    }),
  },
};

export default MuiFab;
