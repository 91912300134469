/* eslint-disable import/prefer-default-export */
import type { ConsentV3, ConsentOptionV3 } from '../../../../services/generated/CustomerClient';
import { MemberConsentV3 } from '../../../../services/generated/CustomerClient';

const getConsentOptionCode = (
  consent: MemberConsentV3,
  selectedOptions: { [s: string]: string },
) => {
  const result = Object.keys(selectedOptions).find((key) => consent.consentCode === key);
  if (result) {
    return selectedOptions[result];
  }
  return null;
};

// Updating variable with consent options when submitting
export const mapSelectedOptionsToMemberConsents = (
  memberConsent: MemberConsentV3[],
  selectedOptions: { [s: string]: string },
  submitOnlyMissing = false,
): MemberConsentV3[] => {
  const memberConsents = submitOnlyMissing
    ? memberConsent.filter((c) => c.isMissing === true)
    : memberConsent;

  // MemberConsentV3[] is the correct class to be send back to back-end when submitting the consents
  const consentChoice: MemberConsentV3[] = memberConsents.map((consent) =>
    MemberConsentV3.fromJS({
      ...consent,
      // Adding the selected consent option
      consentOptionCode: getConsentOptionCode(consent, selectedOptions),
      // setting the consent to not be missing
      isMissing: false,
    }),
  );
  return consentChoice;
};

export type MappedMemberConsent = {
  isMissing: boolean | undefined;
  code?: string | undefined;
  sortOrder?: number | undefined;
  description?: string | undefined;
  options?: ConsentOptionV3[] | undefined;
  consentOptionCode?: string | undefined;
  consentSortOrder?: number | undefined;
};

export const mapMemberConsentsToConsent = (
  consents: ConsentV3[] | undefined,
  memberConsents: MemberConsentV3[] | undefined,
  filterIsMissing = false,
): MappedMemberConsent[] => {
  if (consents && memberConsents && memberConsents.length > 0) {
    const mappedMemberConsentsToConsent = consents.map((consent) => ({
      ...consent,
      // adding propertys
      consentOptionCode: memberConsents.find(
        (memberConsent) => memberConsent.consentCode === consent.code,
      )?.consentOptionCode,

      consentSortOrder: memberConsents.find(
        (memberConsent) => memberConsent.consentCode === consent.code,
      )?.consentSortOrder,

      isMissing: memberConsents.find((memberConsent) => memberConsent.consentCode === consent.code)
        ?.isMissing,
    }));

    return filterIsMissing
      ? mappedMemberConsentsToConsent.filter((c) => c.isMissing === true)
      : mappedMemberConsentsToConsent;
  }
  return [];
};

export type ConsentFormValues = {
  Bonus: string;
  PersonalOffer: string;
};
