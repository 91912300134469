import * as headings from '~/styles/elements/headings';
import theme from '~/styles/theme';

export const classes = {
  root: {
    width: '100%',
    height: 'auto',
    margin: theme.spacing(0, 0, 1, 0),
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.mqs.md]: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(5, 5, 3, 5),
    },
  },
  consentHeading: {
    ...headings.h2,
    margin: theme.spacing(0, 0, 1, 0),
    lineHeight: '24px',
    letterSpacing: '0.3px',
    fontSize: 22,
    // overides fontWeight and fontSize from heading h2 style
    fontWeight: 400,

    [theme.breakpoints.mqs.lg]: {
      fontSize: 28,
    },
  },
  consentSelect: {
    background: theme.palette['color/background/visual'] || 'inherit',

    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.mqs.md]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  consentSelectError: {
    background: theme.palette['color/background/visual'] || 'inherit',
    border: `1px solid ${theme.palette['color/border/error']} !important`,
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.mqs.md]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  rootLabel: {
    alignItems: 'start',
    padding: theme.spacing(0, 0, 2, 0),
    '&:last-child': {
      padding: theme.spacing(0),
    },
  },
  label: {
    height: 'auto',
    color: theme.palette['color/text/default'],
    lineHeight: '20px',
    letterSpacing: '0.3px',
    fontSize: 15,
    span: {
      alignSelf: 'flex-start',
      marginTop: '-2px',
    },
  },
  radioButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
};
