import pino, { type LoggerOptions } from 'pino';
import { pinoOptions } from './options';

const pinoConfig: LoggerOptions = {
  ...pinoOptions,
  browser: {
    asObject: true,
    transmit: {
      level: 'info',
      send: (/* level, logEvent */) => {
        // const msg = logEvent.messages[0];
        // const headers = {
        //   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        //   type: 'application/json',
        // };
        // const blob = new Blob([JSON.stringify({ msg, level })], headers);
        // navigator.sendBeacon(`/spa/api/log/`, blob);
      },
    },
  },
};

export const logger = pino(pinoConfig);
