import { colorTokens } from '@aph/ui/design-tokens/color-tokens';
import * as font from '../fonts';

export const large = {
  fontFamily: font.ICAText,
  fontSize: 28,
  fontWeight: 400,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const largeBold = {
  ...large,
  fontWeight: 700,
} as const;

export const headingBold = {
  ...large,
  fontFamily: font.ICAHeading,
} as const;

export const medium = {
  fontFamily: font.ICAText,
  fontSize: 18,
  fontWeight: 400,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const mediumBold = {
  ...medium,
  fontFamily: font.ICAText,
  fontWeight: 700,
} as const;

export const normal = {
  fontFamily: font.ICAText,
  fontSize: 16,
  fontWeight: 400,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const normalBold = {
  ...normal,
  fontWeight: 700,
} as const;

export const small = {
  fontFamily: font.ICAText,
  fontSize: 14,
  fontWeight: 400,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.25,
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const smallBold = {
  ...small,
  fontFamily: font.ICAText,
  fontWeight: '700',
} as const;

export const xs = {
  fontFamily: font.ICAText,
  fontSize: 12,
  fontWeight: 400,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.67,
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const xsBold = {
  fontFamily: font.ICAText,
  fontSize: 12,
  fontWeight: 700,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: colorTokens['color/text/default'],
} as const;

export const normalBonus = {
  fontFamily: font.ICAHeading,
  fontSize: 16,
  fontWeight: 700,
  color: colorTokens['color/text/default'],
  lineHeight: 'normal',
} as const;

export const largeBonus = {
  ...normalBonus,
  fontSize: 24,
} as const;
