import type { IFeatureToggle } from './IFeatureToggle';
import { getFeatureTogglesFromCookies } from './getFeatureTogglesFromCookies';
import { getFeatureTogglesFromEnv } from './getFeatureTogglesFromEnv';

export const getCookie = (feature: IFeatureToggle, cookies: IFeatureToggle[]) => {
  const exactMatch = cookies.find(
    (c) => c.name === feature.name && c.cookieName === feature.cookieName,
  );
  if (exactMatch) return exactMatch;

  const cookieMatch = cookies.find((c) => c.cookieName === feature.cookieName);
  if (cookieMatch) return cookieMatch;

  const nameMatch = cookies.find((c) => c.name === feature.name);
  return nameMatch;
};

export function getFeatureToggles(): IFeatureToggle[] {
  const featureToggleList: IFeatureToggle[] = [];
  let cookieFeatures = getFeatureTogglesFromCookies();
  const envFeatures = getFeatureTogglesFromEnv();

  // Adds feature toggles from environment variables
  envFeatures.forEach((env) => {
    const feature: IFeatureToggle = {
      name: env.name,
      onValue: env.onValue,
      offValue: env.offValue,
      isEnabled: env.isEnabled,
      isActive: env.isActive,
      cookieName: env.cookieName,
      cookieDomain: env.cookieDomain,
      visualToggle: env.visualToggle,
      isReadOnly: env.isReadOnly,
    };

    // Get cookie from cookie list
    const cookie = getCookie(feature, cookieFeatures);

    // If cookie is found, copy values from cookie
    if (cookie) {
      // only copy values if cookie name is the same. fix to not mix cookies from production in test environments
      if (cookie.cookieName === feature.cookieName) {
        if (!feature.isActive)
          feature.isActive =
            cookie.isActive || (cookie.value === feature.onValue && feature.onValue !== undefined);

        feature.value = cookie.value;
      }
      cookieFeatures = cookieFeatures.filter((c) => c.name !== cookie.name);
    }

    // If feature is not enabled, feature should not be active
    if (!feature.isEnabled) feature.isActive = false;

    featureToggleList.push(feature);
  });

  // Adds feature toggles from cookies
  cookieFeatures.forEach((cookie) => {
    const feature: IFeatureToggle = {
      name: cookie.name,
      isEnabled: cookie.isEnabled,
      isActive: cookie.isActive,
      cookieName: cookie.cookieName,
      visualToggle: cookie.visualToggle,
    };
    featureToggleList.push(feature);
  });

  return featureToggleList;
}
