import { appendLeadingSlash, appendTrailingSlash } from '../slug';

/**
 * Generates the logout redirect URL with the provided return path.
 * @param returnPath - The return path after logout.
 * @returns The logout redirect URL.
 */

export const getLogOutRedirectUrl = (returnPath: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_WEB || '';
  const formattedReturnPath = appendTrailingSlash(appendLeadingSlash(returnPath));
  const returnUrl = `${baseUrl}${formattedReturnPath}`;

  return `${process.env.NEXT_PUBLIC_BASE_URL}/auth/v1/logout?resumeUri=${encodeURIComponent(
    `${returnUrl}`,
  )}`;
};
