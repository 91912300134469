export interface GiosgState {
  token: string;
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
  updated: number;
}

export const initialState: GiosgState = {
  token: '',
  loading: false,
  error: false,
  errorMessage: null,
  updated: 0,
};
